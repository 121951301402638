import { Drawer, List, Box } from "@material-ui/core";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SidebarListItem } from "~/components/layouts/Sidebar/SidebarListItem";
import { UserProfile } from "~/components/layouts/Sidebar/UserProfile";

import { Button } from "~/components/uiParts";
import logo from "~/images/logo.svg";
import { mixin } from "~/util";

type Props = {
  isMember?: boolean;
};

export const SettingSidebar: FC<Props> = ({ isMember }) => {
  const navigate = useNavigate();
  const handleClickBackButton = () => {
    navigate("/");
  };
  const currentPathname = window.location.pathname;

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Box
        mb={5}
        pt={4}
        pl={4}
        pr={10}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Link to="/">
          <img src={logo} alt="Onn" />
        </Link>
      </Box>
      <List>
        <Box pt={1} pb={3.5} pl={3.5}>
          <StyledButton
            borderRadius="circle"
            color="default"
            variant="contained"
            onClick={() => handleClickBackButton()}
          >
            {`< Back`}
          </StyledButton>
        </Box>
        {!isMember && (
          <SidebarListItem
            label="管理者設定"
            to="/settings/admin"
            shouldShowName
            currentPathname={currentPathname}
          />
        )}
        <SidebarListItem
          label="アカウント"
          to="/settings/account"
          shouldShowName
          currentPathname={currentPathname}
        />
      </List>
      <Box flexGrow={1} />
      <Box mb={5} ml={5}>
        <UserProfile />
      </Box>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  & > .MuiDrawer-paper {
    width: 270px;
    ${mixin.breakDown.sm`display: none`};
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    height: 36px;
    padding: 8px 16px;
    font-size: 14px;
    color: white;
  }
`;
