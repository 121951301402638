import { ContentText, Comment } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import chunk from "lodash/chunk";
import { v4 } from "uuid";

import { ICommentRepository } from "../../service/repository/iCommentRepository";

import { firestore } from "~/config/firebase";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type CommentForDB = Omit<ConstructorParameters<typeof Comment>[0], "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "comments";

const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10;

export class CommentRepository implements ICommentRepository {
  async findById(commentId: string): Promise<Comment> {
    const doc = await getDoc(this.doc(commentId));

    if (!doc) {
      throw new Error("no comment found");
    }

    return this.dbToObject(doc.data() as CommentForDB);
  }

  async whereByTransactionIds(transactionIds: string[], tenantId: string): Promise<Comment[]> {
    const comments: Comment[] = [];
    await Promise.all(
      // where in句はで最大10件までしかクエリできないため、予めtransactionIdsを10件ごとに分割し、取得した結果を結合するようにしている
      chunk(transactionIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map(async (chunkedTransactionIds) => {
        const { docs } = await getDocs(
          query(
            this.collection(),
            where("transactionId", "in", chunkedTransactionIds),
            where("tenantId", "==", tenantId)
          )
        );

        comments.push(...docs.map((doc) => this.dbToObject(doc.data() as CommentForDB)));
      })
    );

    // 作成日順に昇順ソートして返す
    return comments.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  }
  async add({
    content,
    transactionId,
    employeeId,
    tenantId,
  }: {
    content: ContentText[];
    transactionId: string;
    employeeId: string;
    tenantId: string;
  }): Promise<{ commentId: string }> {
    const id = v4();
    const now = convertDateToTimestamp(new Date());
    const comment: CommentForDB = {
      id,
      content,
      transactionId,
      employeeId,
      createdAt: now,
      updatedAt: now,
      tenantId,
    };

    const ref = this.doc(id);
    await setDoc(ref, comment);

    return { commentId: id };
  }

  async update({
    commentId,
    content,
  }: {
    commentId: string;
    content: ContentText[];
  }): Promise<void> {
    const updates: Pick<CommentForDB, "content" | "updatedAt"> = {
      content,
      updatedAt: convertDateToTimestamp(new Date()),
    };

    await updateDoc(this.doc(commentId), updates);
  }

  async delete(commentId: string): Promise<void> {
    await deleteDoc(this.doc(commentId));
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(commentId: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, commentId);
  }

  private dbToObject({ createdAt, updatedAt, ...rest }: CommentForDB) {
    return new Comment({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }
}
