import React, { FC } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts";
import { mixin } from "~/util";

export const Footer: FC<{
  onClickCancel: () => void;
  onClickSave: () => void;
  canSubmit: boolean;
}> = ({ onClickCancel, onClickSave, canSubmit }) => {
  return (
    <StyledDiv>
      <Button
        variant="outlined"
        borderRadius="circle"
        color="default"
        onClick={onClickCancel}
        disabled={!canSubmit}
      >
        キャンセル
      </Button>
      <Button
        variant="contained"
        borderRadius="circle"
        color="primary"
        onClick={onClickSave}
        disabled={!canSubmit}
        type="submit"
      >
        変更を保存
      </Button>
    </StyledDiv>
  );
};

const FOOTER_HEIGHT = 96;

const StyledDiv = styled.div`
  position: fixed;
  z-index: 2;
  row-gap: 16px;
  column-gap: 16px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  padding: 40px 24px;
  background-color: white;
  border-top: solid 1px ${(props) => props.theme.palette.grey[100]};

  ${mixin.sp`
    position: relative;
    border-top: 0;
    flex-direction: column-reverse;
    padding: 0;
    .MuiButtonBase-root,
    .MuiButtonBase-root > div {
      ${mixin.sp`
        width: 100%;
      `}
    }
  `};
`;
