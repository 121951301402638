import { Timestamp } from "firebase/firestore";

/**
 * Date 型を Timestamp 型に変換します
 * @param {Date} date 変換する対象の日付データ
 * @returns {Timestamp}
 * @see https://firebase.google.com/docs/reference/js/firestore_.timestamp#timestampfromdate
 */
export const convertDateToTimestamp = (date: Date): Timestamp => {
  return Timestamp.fromDate(date);
};
