import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { MemoPreview } from "~/components/domains/transactions";
import { Button, Divider, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  memo: {
    id: string;
    title: string;
    text: string;
    createdUser?: {
      name: string;
      profileIconImageUrl?: string;
      deleted?: boolean;
    };
    createdAt: string;
    updatedAt: string;
  };
  onCancel(): void;
  onAccept(memoId: string): void;
};

export const DeleteMemoConfirmModal: FC<Props> = ({ open, memo, onCancel, onAccept }) => {
  const handleAccept = useCallback(() => onAccept(memo.id), [memo.id, onAccept]);

  return (
    <Modal
      open={open}
      title="メモ削除"
      content={
        <>
          <Box mt={2} mb={3} textAlign="center">
            <Typography variant="body1">
              メモを削除しますか？この操作は取り消すことができません。
            </Typography>
          </Box>
          <StyledBox>
            <Typography variant="body1">{memo.title}</Typography>
            <Divider margin={20} orientation="horizontal" />
            <MemoPreview {...memo} />
          </StyledBox>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            onClick={handleAccept}
          >
            削除
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
  padding: 24px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
