import { Chip as MuiChip } from "@material-ui/core";
import React, { FC, ComponentProps, useRef } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts";

type AdditionalProps = {
  /** x ボタンのサイズ(default: "vsm") */
  closeBtnSize?: "vsm" | "sm" | "ssm" | "md" | "lg" | "xl";
  /** ホバー時にpaddingを追加するか(default: true) */
  addPaddingsWhenHover?: boolean;
};
export const ClickableChip: FC<ComponentProps<typeof MuiChip> & AdditionalProps> = ({
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleMouseEnter = () => {
    if (!ref.current) return;
    ref.current.style.display = "flex";
  };
  const handleMouseLeave = () => {
    if (!ref.current) return;
    ref.current.style.display = "none";
  };

  return (
    <StyledChip
      {...props}
      label={props.label}
      deleteIcon={
        <StyledIconWrapper ref={ref}>
          <Icon icon="close" color="grey" size={props.closeBtnSize ?? "vsm"} />
        </StyledIconWrapper>
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDelete={props.onDelete}
      isClickable={props.onDelete !== undefined}
      addPaddingsWhenHover={props.addPaddingsWhenHover ?? true} // デフォルトでホバー時にpaddingを追加する
    />
  );
};

const StyledIconWrapper = styled("div")`
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

// TODO: font-familyの"noto sans cjk jp"指定は暫定対応。Onn全体として指定する必要のあるかもしれないので、別タスクとして精査する。
const StyledChip = styled(MuiChip)<{ isClickable: boolean; addPaddingsWhenHover: boolean }>`
  &.MuiChip-root {
    font-size: 10px;
    height: 28px;
    padding: 0px;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: #fff;
    border: ${(props) => `1px solid ${props.theme.palette.grey[300]}`};
    > .MuiChip-label {
      padding: 8px 17px;
      font-family: "noto sans cjk jp", sans-serif !important;
    }
  }

  &.MuiChip-root:hover {
    > .MuiChip-label {
      ${(props) => (props.isClickable && props.addPaddingsWhenHover ? "padding: 0px 6px 8px;" : "")}
    }
    background-color: ${(props) => (props.isClickable ? props.theme.palette.grey[100] : "")};
  }
`;
