import { Employee } from "@onn/common";

import React from "react";
import styled from "styled-components";

import { ClickableChip, UserIconWithLabel } from "~/components/uiParts";

export const ClosableEmployeeIconAndName = ({
  employee,
  onClickDeleteButton,
}: {
  employee: Employee;
  onClickDeleteButton: (target: Employee) => void;
}) => {
  return (
    <StyledClickableChip
      label={
        <UserIconWithLabel
          iconPath={employee.profileIconImageUrl || ""}
          name={employee.getName()}
          iconBackgroundColor={employee.uid ? "primary" : "grey"}
          size="small"
        />
      }
      onDelete={() => onClickDeleteButton(employee)}
      closeBtnSize="sm"
      addPaddingsWhenHover={false}
    />
  );
};

const StyledClickableChip = styled(ClickableChip)`
  &.MuiChip-root {
    border-radius: 4px;
    padding: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    > .MuiChip-label {
      &:hover {
        ${(props) => props.theme.palette.grey[50]};
      }
      padding: 0;
    }
  }
`;
