import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useCreateUnreadCountByEmployeeIds = () => {
  const createUnreadCountByEmployeeIds = useCallback(
    async ({ employeeIds, contactRoomId }: { employeeIds: string[]; contactRoomId: string }) => {
      await apiClient.post("/post_contact_room_unread_count", {
        employeeIds,
        contactRoomId,
      });
    },
    []
  );

  return { createUnreadCountByEmployeeIds };
};
