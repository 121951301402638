import { useCallback } from "react";
import useSWR, { KeyedMutator, useSWRConfig } from "swr";

import { useCurrentUser } from "~/hooks/employee";

import { apiClient } from "~/libs";

export const generateUnreadMessageCountV2Key = (tenantId: string, employeeId: string) => {
  return { resourcePath: "/get_contact_rooms_unread_count", tenantId, employeeId } as const;
};

/**
 * ログインユーザーに紐づく各コンタクトルームの未読メッセージ数を返すhooks
 */
export const useContactRoomUnreadCountV2 = () => {
  const { currentUser } = useCurrentUser();
  return useSWR(
    generateUnreadMessageCountV2Key(currentUser.tenantId, currentUser.id),
    async ({ resourcePath }) => {
      const response = await apiClient.get(resourcePath);

      return response.data;
    }
  );
};

export const useGenerateMutateContactRoomUnreadCountV2 = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();

  const generateMutateContactRoomUnreadCountV2 = useCallback<
    () => KeyedMutator<
      | {
          contactRoomId: string;
          unreadCount: number;
        }[]
      | undefined
    >
  >(() => {
    return async (data, opts) => {
      const key = generateUnreadMessageCountV2Key(currentUser.tenantId, currentUser.id);
      if (!data) {
        return mutate(key);
      }
      return mutate(key, data, opts);
    };
  }, [currentUser.id, currentUser.tenantId, mutate]);

  return { generateMutateContactRoomUnreadCountV2 };
};
