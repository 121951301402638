import { RefObject } from "react";

import { useScrollToTargetElement as sharedUseScrollToTargetElement } from "../shared";

/**
 * 指定された要素が画面表示領域内に入るよう、ウインドウを自動スクロールするhooks
 *
 * @param enabled ウインドウの自動スクロールを有効にするか否か
 * @param targetRef 対象要素のRefObject
 * @param onScrolled スクロール時に実行する関数
 */
export const useScrollToTargetElement = ({
  enabled,
  targetRef,
  onScrolled,
}: {
  enabled: boolean;
  targetRef?: RefObject<HTMLElement>;
  onScrolled?: () => void;
}): void => {
  const PADDING_HEIGHT = 20;
  const EXPANDED_EMPLOYEE_SUMMARY_HEIGHT = 122;

  sharedUseScrollToTargetElement({
    enabled: enabled,
    targetRef: targetRef,
    scrollYOffset: PADDING_HEIGHT + EXPANDED_EMPLOYEE_SUMMARY_HEIGHT,
    onScrolled: onScrolled,
  });
};
