import { Box } from "@material-ui/core";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { Button, PasswordField, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onAccept: (password: string) => Promise<void>;
  hasChangedEmail: boolean;
  hasChangedName: boolean;
  hasChangedPassword: boolean;
  hasChangedIcon: boolean;
};

export const ConfirmChangeAccountInfoModal: FC<Props> = ({
  open,
  onCancel,
  onAccept,
  hasChangedEmail,
  hasChangedName,
  hasChangedPassword,
  hasChangedIcon,
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const handleClickChange = async () => {
    if (!isProcessing) {
      setIsProcessing(true);
      await onAccept(password)
        .then(() => setPassword(""))
        .finally(() => setIsProcessing(false));
    }
  };

  const checkPasswordInvalid = password.length === 0 || isProcessing === true;

  const Content: JSX.Element = (
    <Box>
      <Box textAlign="center">
        <Typography variant="body1" color="textSecondary">
          現在設定中のパスワードを入力して、変更を保存してください。
        </Typography>
        <Box pt={1}>
          <Typography variant="caption" color="textSecondary">
            {`${hasChangedIcon ? "「アイコン」" : ""}${hasChangedName ? "「氏名」" : ""}${
              hasChangedEmail ? "「メールアドレス」" : ""
            }${hasChangedPassword ? "「新しいパスワード」" : ""}が更新されます。`}
            <br />
            変更を保存すると、この操作は取り消すことはできません。
          </Typography>
        </Box>
      </Box>
      <Box pt={2}>
        <StyledBox>
          <PasswordField autoFocus value={password} fullWidth onChange={(v) => setPassword(v)} />
        </StyledBox>
      </Box>
    </Box>
  );

  const Footer: JSX.Element = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleClickChange}
        disabled={checkPasswordInvalid}
      >
        変更を保存する
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="アカウント情報更新"
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: baseline;
  > * + * {
    margin-left: 12px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
