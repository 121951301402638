import { addDays, isToday, isPast } from "date-fns";

import { Employee } from "../../Employee";
import { DifferenceDate } from "../../shared";

export const assigneeRoles = [
  "NEW_HIRE",
  "MENTOR",
  "SUPPORT_MEMBER",
  "ADMIN",
  "BY_NAME",
  "NOT_SET",
] as const;
type AssigneeRole = (typeof assigneeRoles)[number];
export const displayAssigneeRoleMap: { [key in AssigneeRole]: string } = {
  ADMIN: "管理者",
  MENTOR: "バディ",
  SUPPORT_MEMBER: "サポートメンバー",
  NEW_HIRE: "入社者",
  BY_NAME: "特定のメンバーを指定",
  NOT_SET: "担当者をあとで設定",
};

const ONE_DAY = 1;
const MAX_TITLE_NUM = 70;

export abstract class OnboardingTask {
  onboardingExperienceId?: string;
  title: string;
  body: string;
  deliveryDate?: DifferenceDate;
  dueDate: DifferenceDate;
  isRequired: boolean;
  index: number;
  status: "NOT_STARTED" | "INPROGRESS" | "COMPLETED";
  employeeId: string;
  tenantId: string;
  assigneeRole: AssigneeRole;
  assigneeIds: string[];
  createdAt: Date;
  updatedAt: Date;

  static MAX_TITLE_NUM = MAX_TITLE_NUM;

  constructor(
    init: Omit<OnboardingTask, "isExpiredByEmployee" | "shouldDelivery" | "shouldRemind">
  ) {
    this.onboardingExperienceId = init.onboardingExperienceId;
    this.title = init.title;
    this.body = init.body;
    this.deliveryDate = init.deliveryDate;
    this.dueDate = init.dueDate;
    this.isRequired = init.isRequired;
    this.index = init.index;
    this.status = init.status;
    this.employeeId = init.employeeId;
    this.tenantId = init.tenantId;
    this.assigneeRole = init.assigneeRole;
    this.assigneeIds = init.assigneeIds;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  /**
   * 入社者を元にタスクが期限切れかどうかを返すメソッド
   * @param employee 入社者
   * @returns boolean
   */
  isExpiredByEmployee(employee: Employee): boolean {
    const date = this.dueDate.calculateDateByEmployee(employee);

    if (!date) return false;
    // 締め切り当日は未着手と表示するので1日足している
    return isPast(addDays(date, ONE_DAY));
  }

  /**
   * 入社者を元にタスクを配信するかどうかを返すメソッド
   * @param employee 入社者
   * @returns boolean
   */
  shouldDelivery(employee: Employee): boolean {
    if (this.status === "COMPLETED") return false;
    if (this.assigneeRole === "NOT_SET") return false;
    if (!this.deliveryDate) return false;

    const date = this.deliveryDate.calculateDateByEmployee(employee);

    if (!date) return false;

    return isToday(date);
  }

  /**
   * 入社者を元にタスクをリマインドするかどうかを返すメソッド
   * @param employee 入社者
   * @returns boolean
   */
  abstract shouldRemind(employee: Employee, currentDate?: Date): boolean;

  static getDisplayAssigneeRole(value: (typeof assigneeRoles)[number]): string {
    return displayAssigneeRoleMap[value];
  }
}
