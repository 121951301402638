import { useCallback } from "react";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

/**
 * 画像をアップロードする
 * @param dataUrl
 * @param path
 */
export const useUploadImageFile = (): {
  uploadImageFile: ({ dataUrl, path }: { dataUrl: string; path: string }) => Promise<void>;
} => {
  const uploadImageFile = useCallback(
    async ({ dataUrl, path }: { dataUrl: string; path: string }) => {
      // dataUrl → File 変換
      const blob = await (await fetch(dataUrl)).blob();
      const mimeType = dataUrl.match(/:([a-z/-]+);/)?.[1];
      const imageFile = new File([blob], "", {
        type: mimeType,
      });

      await fileAPIAdapter.upload({
        path,
        file: imageFile,
      });
    },
    []
  );

  return { uploadImageFile };
};
