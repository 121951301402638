import React, { FC } from "react";

export const Notice: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_225_179)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8884 2.21713C19.2091 2.4417 19.4 2.80855 19.4 3.20001V19.2C19.4 19.5915 19.2091 19.9583 18.8884 20.1829C18.5678 20.4075 18.1578 20.4615 17.7899 20.3278L6.98859 16.4H4.6C2.61178 16.4 1 14.7882 1 12.8V9.60001C1 7.61178 2.61178 6.00001 4.6 6.00001H6.98859L17.7899 2.07226C18.1578 1.93848 18.5678 1.99255 18.8884 2.21713ZM17 4.91325L7.61009 8.32776C7.47865 8.37556 7.33986 8.40001 7.2 8.40001H4.6C3.93726 8.40001 3.4 8.93727 3.4 9.60001V12.8C3.4 13.4627 3.93726 14 4.6 14H7.2C7.33986 14 7.47865 14.0245 7.61009 14.0723L17 17.4868V4.91325Z"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4128 6.29432C17.675 6.06641 18.0231 5.96332 18.3671 6.0117C19.7208 6.20207 20.9793 6.69317 21.9142 7.57886C22.874 8.48815 23.4 9.72784 23.4 11.2C23.4 12.6722 22.874 13.9119 21.9142 14.8211C20.9793 15.7068 19.7208 16.1979 18.3671 16.3883C18.0231 16.4367 17.675 16.3336 17.4128 16.1057C17.1506 15.8778 17 15.5474 17 15.2V7.20001C17 6.8526 17.1506 6.52223 17.4128 6.29432ZM19.4 8.76132V13.6387C19.7499 13.4831 20.0375 13.293 20.2636 13.0789C20.7112 12.6548 21 12.0612 21 11.2C21 10.3388 20.7112 9.7452 20.2636 9.32115C20.0375 9.10699 19.7499 8.91695 19.4 8.76132Z"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2 7.00001C7.86274 7.00001 8.4 7.53727 8.4 8.20001V14.2C8.4 14.8627 7.86274 15.4 7.2 15.4C6.53726 15.4 6 14.8627 6 14.2V8.20001C6 7.53727 6.53726 7.00001 7.2 7.00001Z"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.66334 14.1267C4.25612 13.8303 4.97693 14.0706 5.27331 14.6634L8.27331 20.6634C8.5697 21.2561 8.32943 21.9769 7.73666 22.2733C7.14388 22.5697 6.42307 22.3294 6.12669 21.7367L3.12669 15.7367C2.8303 15.1439 3.07057 14.4231 3.66334 14.1267Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
