import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { useCallback, useEffect, useState } from "react";

export const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const addListener = useCallback(() => {
    Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
      setKeyboardHeight(info.keyboardHeight);
    }).catch(() => {
      /* ignore */
    });

    Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardHeight(0);
    }).catch(() => {
      /* ignore */
    });
  }, []);

  const removeListner = useCallback(() => {
    Keyboard.removeAllListeners().catch(() => {
      /* ignore */
    });
  }, []);

  useEffect(() => {
    addListener();

    return () => removeListner();
  }, [addListener, removeListner]);

  return { keyboardHeight };
};
