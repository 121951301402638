import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Icon, Paper, Typography } from "~/components/uiParts";

type Props = {
  onClickAddMemoButton(): void;
};

export const MemoEmptyPaper: FC<Props> = (props) => {
  return (
    <Paper square paddingLarge>
      <Box textAlign="center">
        <Typography variant="body1">
          {`入社者に関する1on1や入社後インタビューの情報をメモとして残して、\nバディ・サポートメンバー・管理者間で共有しましょう。`}
        </Typography>
      </Box>
      <Box mt={3} textAlign="center">
        <StyledButton
          variant="contained"
          borderRadius="circle"
          color="primary"
          onClick={props.onClickAddMemoButton}
          startIcon={<Icon icon="add" color="white" size="md" />}
        >
          メモを追加
        </StyledButton>
      </Box>
    </Paper>
  );
};

const StyledButton = styled(Button)`
  &.MuiButton-root {
    padding: 14px 24px;
  }
`;
