import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type Props = {
  text: string;
};

export const DividerWithText: FC<Props> = ({ text }) => {
  return (
    <StyledWrapper>
      <StyledDivider />

      <StyledTypography variant="body1" bold display="inline" color="textSecondary">
        {text}
      </StyledTypography>

      <StyledDivider />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  width: 100%;
`;

const StyledTypography = styled((props) => <Typography {...props} />)`
  padding: 0 8px;
  color: ${(props) => props.theme.palette.grey[200]};
`;
