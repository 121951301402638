import { Employee } from "../Employee";

export type ContentText = { text: string } & (
  | { type: "plain" }
  | { type: "mention"; employeeId: string }
);

export class Comment {
  id: string;
  employeeId: string;
  transactionId: string;
  content: ContentText[];
  createdAt: Date;
  updatedAt: Date;
  tenantId: string;

  constructor({
    id,
    employeeId,
    transactionId,
    content,
    createdAt,
    updatedAt,
    tenantId,
  }: {
    id: string;
    employeeId: string;
    transactionId: string;
    content: ContentText[];
    createdAt: Date;
    updatedAt: Date;
    tenantId: string;
  }) {
    this.id = id;
    this.employeeId = employeeId;
    this.transactionId = transactionId;
    this.content = content;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.tenantId = tenantId;
  }

  editable(requestedUser: Employee): boolean {
    return requestedUser.id === this.employeeId;
  }

  deletable(requestedUser: Employee): boolean {
    return requestedUser.id === this.employeeId || requestedUser.isAdmin();
  }
}
