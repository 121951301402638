import { merge } from "lodash";
import React, {
  FC,
  useCallback,
  FocusEvent,
  FormEvent,
  useMemo,
  ComponentProps,
  useRef,
  useEffect,
  MouseEvent,
} from "react";
import { MentionsInput, Mention } from "react-mentions";
import styled from "styled-components";

import type { OnChangeHandlerFunc } from "react-mentions";

import { Button, IconButton } from "~/components/uiParts";
import theme from "~/config/theme";

const MENTION_USERS_SYMBOL = "@";

type MentionProps = ComponentProps<typeof Mention>;

type Props = {
  value: string;
  disabled?: boolean;
  autoFocus?: boolean;
  textAreaMinHeight?: number;
  textAreaMaxHeight?: number;
  suggestedMentionUsers?: { id: string; name: string }[];
  submitButtonLabel?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onCancel?: () => void;
};

export const CommentForm: FC<Props> = ({
  value,
  disabled,
  autoFocus,
  textAreaMinHeight,
  textAreaMaxHeight,
  suggestedMentionUsers,
  submitButtonLabel = "送信",
  onBlur,
  onChange,
  onSubmit,
  onCancel,
}) => {
  const submissionDisabled = disabled || !value.trim();

  const mentionsInputRef = useRef<HTMLTextAreaElement>(null);

  const handleChange: OnChangeHandlerFunc = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      e.target.selectionStart = value.length;
      e.target.selectionEnd = value.length;
    },
    [value]
  );

  const handleTriggerMention = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!mentionsInputRef.current) {
        return;
      }
      // コメントのテキストフィールドに文字列を挿入するために、予めフォーカスする
      mentionsInputRef.current.focus();

      // 文字列が挿入されてない場合 or 文字列の末尾が空白文字である場合には、スペースを空けずにメンション文字列を挿入する
      const newValue =
        !value || (value[value.length - 1] as (typeof value)[number]).match(/\s/)
          ? `${value}${MENTION_USERS_SYMBOL}`
          : `${value} ${MENTION_USERS_SYMBOL}`;

      onChange(newValue);
    },
    [value, onChange]
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (submissionDisabled) {
        return;
      }

      onSubmit();
    },
    [submissionDisabled, onSubmit]
  );

  const suggestedUsersForMentionsInput = useMemo<MentionProps["data"]>(() => {
    return (
      suggestedMentionUsers
        ?.filter(({ id }) => !value.match(id)) // 既にメンション済みのユーザーは候補に表示させないため
        .map(({ id, name }) => ({ id, display: name })) ?? []
    );
  }, [suggestedMentionUsers, value]);

  const mentionsInputStyle = merge(mentionsInputDefaultStyle, {
    "&multiLine": {
      // ハイライト対象の文字要素とテキストエリアの文字を重ね合わせることでハイライトを行う仕組みだったので、両者の高さを合わせておく必要がある
      highlighter: { maxHeight: textAreaMaxHeight, minHeight: textAreaMinHeight },
      input: { maxHeight: textAreaMaxHeight, minHeight: textAreaMinHeight },
    },
  });
  // autoFocusが指定されると、スクロール + カーソルをテキストエリアの末尾に合わせる & フォーカスする
  useEffect(() => {
    if (!autoFocus || !mentionsInputRef.current) {
      return;
    }
    const mentionsInputElement = mentionsInputRef.current;

    // focusによるスクロールは行わない
    mentionsInputElement.focus({ preventScroll: true });
  }, [autoFocus]);

  return (
    <StyledForm>
      <MentionsInput
        style={mentionsInputStyle}
        value={value}
        autoFocus={false}
        onChange={handleChange}
        placeholder="サポートやフォローをしたほうが良いことを管理者・バディ・サポートメンバー間で共有しましょう"
        allowSpaceInQuery
        onFocus={handleFocus}
        onBlur={onBlur}
        inputRef={mentionsInputRef}
        suggestionsPortalHost={document.body}
        className="mentions-style"
      >
        <Mention
          trigger={MENTION_USERS_SYMBOL}
          data={suggestedUsersForMentionsInput}
          // ユーザー候補クリック時に、デフォルトだとnameしか挿入されないため、@{name}という形式に変換して挿入する
          displayTransform={(_, name) => `${MENTION_USERS_SYMBOL}${name}`}
          // メンションしたユーザーの情報をvalueの中で @[name](id) という形式で記録している
          markup={`${MENTION_USERS_SYMBOL}[__display__](__id__)`}
          appendSpaceOnAdd
        />
      </MentionsInput>
      <StyledFooter>
        <IconButton
          icon="at"
          size="sm"
          color="grey"
          borderRadius="regular"
          onClick={handleTriggerMention}
        />
        <StyledCommentFormControlButtonContainer>
          {onCancel ? (
            <Button onClick={onCancel} borderRadius="regular" variant="outlined" color="default">
              キャンセル
            </Button>
          ) : null}
          <Button
            onClick={handleSubmit}
            borderRadius="regular"
            variant="contained"
            color="primary"
            disabled={submissionDisabled}
          >
            {submitButtonLabel}
          </Button>
        </StyledCommentFormControlButtonContainer>
      </StyledFooter>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;

  /* MentionsInput コンポーネントによって自動生成されたクラス */
  .mentions-style__input {
    ::placeholder {
      color: ${(props) => props.theme.palette.grey[300]};
    }
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.action.active};
  }

  &:focus-within {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    margin: -1px;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

// MentionsInputコンポーネントが、styleタグでしかカスタムスタイルが適用できなかったため、オブジェクト定義したstyleを使っている
const mentionsInputDefaultStyle = {
  control: {
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 63,
    },
    highlighter: {
      padding: theme.spacing(2),
      border: "1px solid transparent",
      boxSizing: "border-box",
      overflow: "hidden",
    },
    input: {
      padding: theme.spacing(2),
      border: "1px solid transparent",
      outline: "none",
      overflow: "auto",
    },
  },

  suggestions: {
    list: {
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[10],
      fontSize: 14,
      overflow: "auto",
      width: 200,
      maxHeight: 240,
    },
    item: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      "&focused": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
};

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const StyledCommentFormControlButtonContainer = styled.div`
  display: flex;

  > button + button {
    margin-left: 8px;
  }
`;
