import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { MemoForm } from "../../transactions";

import { ActivityListItemLayout } from "./ActivityListItemLayout";

import { Icon } from "~/components/uiParts";

export type ActivityListItemMemoFormProps = {
  onSubmitMemo: ({
    title,
    text,
    attachedFiles,
  }: {
    title: string;
    text: string;
    attachedFiles: (File | Pick<File, "name">)[];
  }) => Promise<void>;
  onDropFilesError: (message: string) => void;
};
export const ActivityListItemMemoForm = (props: ActivityListItemMemoFormProps): JSX.Element => {
  const { onSubmitMemo, onDropFilesError } = props;

  return (
    <ActivityListItemLayout circleIcon={<StyledIcon size="sm" icon="add" color="primary" />}>
      <Grid container alignItems="flex-start">
        <Grid item sm={7}>
          <MemoForm
            attachedFiles={[]}
            onSubmit={onSubmitMemo}
            onDropFilesError={onDropFilesError}
          />
        </Grid>
      </Grid>
    </ActivityListItemLayout>
  );
};

const StyledIcon = styled(Icon)`
  font-weight: bold;
`;
