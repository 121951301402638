import React, { FC } from "react";
import styled from "styled-components";

import { Paper, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  fullName: string;
  hasMentor?: boolean;
  joinedAt: string;
  isActive: boolean;
};

export const JoinedDateNotifyPaper: FC<Props> = (props) => {
  const { fullName, hasMentor, joinedAt, isActive } = props;

  return (
    <StyledPaper $isActive={isActive}>
      <Typography variant="body2">
        {`${fullName}さんの入社予定日が`}
        <Typography variant="body2" bold display="inline">
          {` ${joinedAt} `}
        </Typography>
        に設定されました。
      </Typography>
      {hasMentor ? (
        <Typography variant="body2">{`${fullName}さんが最高の入社日を迎えられるように、入社日を待ちましょう！`}</Typography>
      ) : (
        <Typography variant="body2">{`${fullName}さんの入社後不安をサポートするバディを設定しましょう！`}</Typography>
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;
