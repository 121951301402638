import React, { useEffect, ReactChild, FC } from "react";
import { useLocation } from "react-router-dom";

import { useSwitchViewport } from "~/hooks/shared";

type Props = {
  children: ReactChild;
};

export const SwitchViewportProvider: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { switchViewport } = useSwitchViewport();

  useEffect(() => {
    switchViewport(location.pathname);
  }, [location.pathname, switchViewport]);

  return <>{children}</>;
};
