import { Paper as MuiPaper } from "@material-ui/core";
import React, { FC, ComponentProps } from "react";
import styled from "styled-components";

import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";
import { mixin } from "~/util";

type CustomProps = {
  isShowBgImage?: boolean;
  paddingLarge?: boolean;
  border?: "primary";
};

type Props = ComponentProps<typeof MuiPaper> & CustomProps;

export const Paper: FC<Props> = ({ isShowBgImage, paddingLarge, border, children, ...rest }) => {
  return (
    <StyledMuiPaper
      $isShowBgImage={isShowBgImage}
      $paddingLarge={paddingLarge}
      border={border}
      {...rest}
    >
      {children}
    </StyledMuiPaper>
  );
};

const StyledMuiPaper = styled(MuiPaper)<{
  $isShowBgImage?: boolean;
  $paddingLarge?: boolean;
  border?: "primary";
}>`
  padding: ${(props) => (props.$paddingLarge ? "80px" : "40px")};

  ${mixin.breakDown.sm`
    padding: 40px 24px;
  `}

  ${(props) =>
    props.$isShowBgImage &&
    `
    background-repeat: no-repeat;
    background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
    background-position: right top, left bottom;
    background-size: 50%;
  `}

  ${(props) => props.border && `border: solid 1px ${props.theme.palette[props.border].main};`}
`;
