import { useCallback } from "react";

import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { captureException } from "~/util";

/**
 * クリップボードへテキストをコピーするためのhooks
 * @returns handleCopy クリップボードへのコピーを行うための関数
 */

export const useClipboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = useCallback(
    (copyText: string, targetName: string) => {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          enqueueSnackbar(`${targetName}をコピーしました`, { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(`${targetName}のコピーに失敗しました`, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useClipboard:handleCopy",
            },
          });
        });
    },
    [enqueueSnackbar]
  );

  return { handleCopy };
};
