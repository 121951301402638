import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { Paper } from "~/components/uiParts/Paper";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  text: string;
  buttons?: ReactNode;
};

export const NotFoundPaper: FC<Props> = ({ text, buttons }) => {
  return (
    <StyledPaper square>
      <Typography variant="body1" align="center">
        {text}
      </Typography>

      {buttons && <StyledButtonWrapper>{buttons}</StyledButtonWrapper>}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 80px 0;
`;

const StyledButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;

  .MuiButton-root:nth-child(even) {
    margin-left: 24px;
  }
`;
