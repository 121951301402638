import { FormControlLabel as MuiFormControlLabel } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

type CustomProps = {
  control: JSX.Element;
  disableEffect?: boolean;
};

type Props = ComponentProps<typeof MuiFormControlLabel> & CustomProps;

export const FormControlLabel: FC<Props> = ({ control, disableEffect, ...rest }) => {
  return <StyledMuiFormControlLabel {...rest} $disableEffect={disableEffect} control={control} />;
};

const StyledMuiFormControlLabel = styled(MuiFormControlLabel)<{ $disableEffect?: boolean }>`
  ${(props) =>
    props.$disableEffect &&
    `
    &.MuiFormControlLabel-root {
      cursor: default !important;
    }
  `}
`;
