import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from "styled-components";

import theme from "../config/theme";

const breakDown = Object.entries(theme.breakpoints.values).reduce(
  (acc, [sizeName, number]) => {
    acc[sizeName as Breakpoint] = (
      first: CSSObject | TemplateStringsArray,
      ...interpolations: SimpleInterpolation[]
    ): FlattenSimpleInterpolation => css`
      /* Mui に合わせて、指定した数値未満 を条件とする */
      @media (max-width: ${number - 1}px) {
        ${css(first, ...interpolations)}
      }
    `;
    return acc;
  },
  {} as {
    [key in Breakpoint]: (
      first: CSSObject | TemplateStringsArray,
      ...interpolations: SimpleInterpolation[]
    ) => FlattenSimpleInterpolation;
  }
);

const breakUp = Object.entries(theme.breakpoints.values).reduce(
  (acc, [sizeName, number]) => {
    acc[sizeName as Breakpoint] = (
      first: CSSObject | TemplateStringsArray,
      ...interpolations: SimpleInterpolation[]
    ): FlattenSimpleInterpolation => css`
      @media (min-width: ${number}px) {
        ${css(first, ...interpolations)}
      }
    `;
    return acc;
  },
  {} as {
    [key in Breakpoint]: (
      first: CSSObject | TemplateStringsArray,
      ...interpolations: SimpleInterpolation[]
    ) => FlattenSimpleInterpolation;
  }
);

export const mixin = {
  /**
   * PC用の style を指定するための mixin
   * @example
   *  ${mixin.pc`
   *    background: red;
   *  `}
   */
  pc: breakUp.md,
  /**
   * モバイル端末用の style を指定するための mixin
   * @example
   *  ${mixin.sp`
   *    background: red;
   *  `}
   */
  sp: breakDown.md,
  /**
   * ポータルのPC用の style を指定するための mixin
   * @example
   *  ${mixin.pc`
   *    background: red;
   *  `}
   */
  portalPc: breakUp.sm,
  /**
   * ポータルのSP用の style を指定するための mixin
   * @example
   *  ${mixin.pc`
   *    background: red;
   *  `}
   */
  portalSp: breakDown.sm,
  /**
   * 特定の breakpoint 未満の画面で style を指定するための mixin
   * @example xs 以下の画面で適応させる場合
   *  ${mixin.breakDown.xs`
   *    background: red;
   *  `}
   */
  breakDown,
  /**
   * 特定の breakpoints 以上の画面で style を指定するための mixin
   * @example xs 以下の画面で適応させる場合
   *  ${mixin.breakUp.xs`
   *    background: red;
   *  `}
   */
  breakUp,
  /**
   * 特定の border に Animation を指定するための mixin
   * @example $isActiveがtrueの時にanimationが発火
   * ${(props) => props.$isActive && mixin.outlineAnimation()};
   */
  outlineAnimation: (): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
    return css`
      @keyframes borderKeyframes {
        0% {
          outline: ${(props) => `2px solid ${props.theme.palette.primary.main}`};
        }
        95% {
          outline: ${(props) => `2px solid ${props.theme.palette.primary.main}`};
        }
        100% {
          outline: 2px solid transparent;
        }
      }

      animation-name: borderKeyframes;
      animation-duration: 4s;
      animation-timing-function: linear;
    `;
  },
  /**
   * ボタンの幅が固定の時に使うmixin
   * SPのときにcolumnがreverseし、PCのときは一定の相対的横幅で横並び
   */
  fixedWidthButtonContainer: () => {
    return css`
      display: flex;
      justify-content: center;
      grid-gap: 24px;
      ${mixin.breakDown.sm`
        &.MuiBox-root {
          flex-direction: column-reverse;
        }
      `}
      .MuiButtonBase-root {
        width: 33%;
        ${mixin.breakDown.sm`
          width: 100%;
        `};
      }
    `;
  },
  /**
   * カラーコードをRGBカラーモデルに
   * @param code カラーコード
   */
  generateRgbaFromColorCode: (code: string) => {
    const red = parseInt(code.substring(1, 3), 16);
    const green = parseInt(code.substring(3, 5), 16);
    const blue = parseInt(code.substring(5, 7), 16);
    return [red, green, blue].join(",");
  },
};
