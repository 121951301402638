import React, { FC } from "react";

export const CheckOutlineCircle: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(24099 9919)">
        <g transform="translate(-24097 -9917)" fill="none" strokeWidth="2.2">
          <rect width="20" height="20" rx="10" stroke="none" />
          <rect x="1.1" y="1.1" width="17.8" height="17.8" rx="8.9" fill="none" />
        </g>
        <path
          d="M4.042,8.235a1.288,1.288,0,0,1-.13-.153L1,5.171A1.3,1.3,0,0,1,2.833,3.338L4.978,5.482l4.48-4.5A1.279,1.279,0,1,1,11.264,2.8L6.041,8.041a1.306,1.306,0,0,1-.153.184,1.292,1.292,0,0,1-.675.357,1.274,1.274,0,0,1-1.171-.347Z"
          transform="translate(-24093.129 -9911.61)"
          stroke="none"
        />
        <rect transform="translate(-24099 -9919)" fill="none" />
      </g>
    </svg>
  );
};
