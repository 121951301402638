import { Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

/**
 * @deprecated
 * このフックは廃止予定です。代わりにusePaginatedEmployeesを使用するか、必要な関数を作って全件取得をクライアントでしないようにしてください。
 * 削除されたものも含む全Employeeを取得する
 * @param tenantId 対象の tenantId
 */
export const useAllEmployeesWithDeleted = (tenantId: string): SWRResponse<Employee[], Error> => {
  const key = tenantId ? ["employee-with-deleted", tenantId] : null;

  return useSWR(key, () => EmployeeUseCase.findAllWithDeleted().then((res) => res));
};
