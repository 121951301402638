import { Tenant } from "@onn/common";

import { TenantRepository } from "../../infrastructure/api/tenantRepository";

export interface ITenantRepository {
  get(): Promise<Tenant | null>;
  deleteSlackAccessToken(id: string): Promise<void>;
  updateTenantName(tenantId: string, tenantName: string): Promise<void>;
}

export const factory = {
  tenantRepository: (): ITenantRepository => {
    return new TenantRepository();
  },
};
