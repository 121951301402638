import { Box, Menu } from "@material-ui/core";
import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useMemo, useCallback, FC, memo } from "react";
import styled from "styled-components";

import { EmployeeFormControlLabel } from "./EmployeeFormControlLabel";

import { Button, Divider, Icon, IconButton, Typography } from "~/components/uiParts";
import { useEmployee } from "~/hooks/employee";

const RenderEmployeeFormControlLabel: FC<{
  employeeId: string;
  checked: boolean;
  onChange: () => void;
}> = ({ employeeId, checked, onChange }) => {
  const { data: employee } = useEmployee(employeeId, { shouldAutoRevalidate: false });

  if (!employee) return null;

  return <EmployeeFormControlLabel employee={employee} checked={checked} onChange={onChange} />;
};

type Props = {
  currentUser?: Employee;
  selectableEmployeeIds: string[];
  selectedEmployeeIds: string[];
  onChange: (selectedEmployeeIds: string[]) => void;
  employeeType?: "employee" | "mentor";
  defaultLabel?: string;
};

export const EmployeeFilter: FC<Props> = memo(
  ({
    currentUser,
    selectableEmployeeIds,
    selectedEmployeeIds,
    onChange,
    employeeType = "mentor",
    defaultLabel = "全て",
  }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const employeeIdsExceptCurrentUser = currentUser
      ? selectableEmployeeIds.filter((v) => v !== currentUser.id)
      : selectableEmployeeIds;
    const employeeTypeText = employeeType === "mentor" ? "担当者" : "メンバー";

    const labelText = useMemo(() => {
      if (
        isEmpty(selectedEmployeeIds) ||
        selectedEmployeeIds.length === selectableEmployeeIds.length
      ) {
        return defaultLabel;
      }
      return `${selectedEmployeeIds.length}名の${employeeTypeText}`;
    }, [defaultLabel, employeeTypeText, selectableEmployeeIds.length, selectedEmployeeIds]);

    const handleChange = useCallback(
      (employeeId: string) => {
        if (selectedEmployeeIds.includes(employeeId)) {
          return onChange(selectedEmployeeIds.filter((v) => v !== employeeId));
        } else {
          return onChange([...selectedEmployeeIds, employeeId]);
        }
      },
      [onChange, selectedEmployeeIds]
    );

    return (
      <>
        <Box position="relative">
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            borderRadius="regular"
            variant="outlined"
            color="default"
            fullWidth
          >
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" noWrap>
                {labelText}
              </Typography>
              <StyledIcon
                icon="filter"
                size="sm"
                color="grey"
                $isEmpty={isEmpty(selectedEmployeeIds)}
              />
            </Box>
          </Button>
          {/* Buttonのhoverも効いてしまうので内包しないようにする */}
          {!isEmpty(selectedEmployeeIds) && (
            <StyledIconButton icon="close" size="sm" color="grey" onClick={() => onChange([])} />
          )}
        </Box>
        <Menu
          key="statusFilter"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: -8, horizontal: "right" }}
        >
          <Box py="16px" width="240px" display="flex" flexDirection="column">
            {isEmpty(selectableEmployeeIds) ? (
              <Box px="24px">
                <Typography variant="caption" color="textSecondary">
                  {`該当する${employeeTypeText}は存在しません`}
                </Typography>
              </Box>
            ) : (
              <>
                {currentUser && (
                  <>
                    <Box px="24px">
                      <EmployeeFormControlLabel
                        key={currentUser.id}
                        employee={currentUser}
                        checked={selectedEmployeeIds.includes(currentUser.id)}
                        onChange={() => handleChange(currentUser.id)}
                      />
                    </Box>
                    <Divider orientation="horizontal" margin={16} />
                  </>
                )}
                <Box
                  px="24px"
                  maxHeight={300}
                  overflow="auto"
                  display="flex"
                  flexDirection="column"
                  gridGap="8px"
                >
                  {employeeIdsExceptCurrentUser.map((employeeId) => {
                    return (
                      <RenderEmployeeFormControlLabel
                        key={employeeId}
                        employeeId={employeeId}
                        checked={selectedEmployeeIds.includes(employeeId)}
                        onChange={() => handleChange(employeeId)}
                      />
                    );
                  })}
                </Box>
                {!isEmpty(selectedEmployeeIds) && (
                  <Box mt="8px" px="24px">
                    <StyledTypography variant="body2" color="primary" onClick={() => onChange([])}>
                      全てクリア
                    </StyledTypography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Menu>
      </>
    );
  }
);

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
