import { Box } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type Props = Omit<ComponentProps<typeof Typography>, "bold"> & {
  bold?: boolean;
  isShowBg?: boolean;
  padding?: number;
};

export const TextContext: FC<Props> = ({ isShowBg, padding, bold, ...rest }) => {
  return (
    <StyledBox padding={padding ?? 1} $isShowBg={isShowBg}>
      <Linkify
        componentDecorator={(decoratedAdminef, decoratedText) => (
          <a target="blank" href={decoratedAdminef} key={decoratedText}>
            {decoratedText}
          </a>
        )}
      >
        <Typography variant="body2" bold={bold} {...rest} />
      </Linkify>
    </StyledBox>
  );
};

const StyledBox = styled(Box)<{ $isShowBg?: boolean }>`
  background-color: ${(props) => (props.$isShowBg ? props.theme.palette.grey[50] : "")};
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-all;
`;
