import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  targetCount: number;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export const ConfirmAddNewGraduateOnnEventModal: FC<Props> = ({
  open,
  targetCount,
  onSubmit,
  onCancel,
}) => {
  const [sending, setSending] = useState(false);
  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit().finally(() => {
      onCancel();
      setSending(false);
    });
  }, [onCancel, onSubmit]);

  return (
    <StyledModal
      open={open}
      title="イベント配信確認"
      onCancel={onCancel}
      content={
        <Box textAlign="center" whiteSpace="pre-line">
          <Box textAlign="center" whiteSpace="pre-line" mb="16px">
            <Typography>
              新たに追加した {targetCount}名 の配信対象の方にのみ追加で配信が行われます。
            </Typography>
            <Typography>イベントの配信を確定しますか？</Typography>
          </Box>
          <Box>
            <Typography variant="caption">
              すでに過去に配信が行われている対象者には配信されません。
            </Typography>
          </Box>
          <Typography variant="caption">なお、この操作は取り消すことができません。</Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            disabled={sending}
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            isLoading={sending}
            onClick={handleSubmit}
          >
            配信
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
