import { Employee, GeneralTaskSettings } from "@onn/common";
import { isEmpty } from "lodash";

import { TenantSettingsRepository } from "~/infrastructure/api/tenantSettingsRepository";

const tenantSettingsRepository = new TenantSettingsRepository();

export class UpdateGeneralTaskSettingsUseCase {
  async execute(currentUser: Employee, newObject: GeneralTaskSettings) {
    if (!currentUser.isAdmin()) {
      throw new Error("設定を更新する権限がありません。");
    }

    if (newObject.assigneeRole === "ADMIN" && !isEmpty(newObject.assigneeIds)) {
      throw new Error("管理者をアサインする場合はidは指定できません。");
    }

    if (newObject.assigneeRole === "BY_NAME" && isEmpty(newObject.assigneeIds)) {
      throw new Error("特定メンバーをアサインする場合は1名以上指定が必要です。");
    }

    await tenantSettingsRepository.update(currentUser.tenantId, { generalTask: newObject });
  }
}
