import { BaseEmoji, Picker } from "emoji-mart";
import React, { FC } from "react";
import "emoji-mart/css/emoji-mart.css";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  onSelect: (emoji: string) => void;
  onClose: () => void;
};

export const EmojiPicker: FC<Props> = ({ isOpen, onSelect, onClose }) => {
  const handleSelectEmoji = (emoji: BaseEmoji) => {
    onSelect(emoji.native);
    onClose();
  };

  return (
    <>
      {isOpen && <StyledDiv onClick={onClose} />}
      <StyledPickerWrapper id="emoji-picker-wrapper">
        {isOpen && (
          <Picker
            onSelect={handleSelectEmoji}
            showPreview={false}
            showSkinTones={false}
            set="apple"
          />
        )}
      </StyledPickerWrapper>
    </>
  );
};

const StyledPickerWrapper = styled.div`
  .emoji-mart {
    position: absolute;
    z-index: ${(props) => props.theme.zIndex.snackbar};
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.snackbar - 1};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
