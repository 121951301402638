import { LatestContactMessage } from "@onn/common";
import {
  CollectionReference,
  DocumentData,
  collection,
  onSnapshot,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { chunk } from "lodash";

import { firestore } from "~/config/firebase";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const COLLECTION_NAME = "latestContactMessages";
const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10;

type LatestContactMessageForDB = Omit<
  ExcludeMethods<LatestContactMessage>,
  "updatedAt" | "createdAt"
> & {
  updatedAt: Timestamp;
  createdAt: Timestamp;
};

export class LatestContactMessageRepository {
  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  dbToObject({ updatedAt, createdAt, ...rest }: LatestContactMessageForDB): LatestContactMessage {
    return new LatestContactMessage({
      ...rest,
      updatedAt: updatedAt && convertTimestampToDate(updatedAt),
      createdAt: convertTimestampToDate(createdAt),
    });
  }

  listenLatestContactMessages(
    tenantId: string,
    contactRoomIds: string[],
    onChangedLatestMessage: (newMessage: LatestContactMessage) => void
  ): (() => void)[] {
    return chunk(contactRoomIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map((chunk) => {
      const q = query(
        this.collection(),
        where("contactRoomId", "in", chunk),
        where("tenantId", "==", tenantId)
      );
      return onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          onChangedLatestMessage(this.dbToObject(change.doc.data() as LatestContactMessageForDB));
        });
      });
    });
  }
}
