import { Box } from "@material-ui/core";
import React, { useState, useCallback, FC } from "react";
import styled from "styled-components";

import { Button, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
};

export const ConfirmPostMessageModal: FC<Props> = ({ open, onCancel, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    onSubmit()
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onCancel, onSubmit]);

  const Content = (
    <Box textAlign="center" display="flex" flexDirection="column" gridRowGap="32px">
      <Typography variant="body1" color="textPrimary">
        {`現在入社者からはコンタクト機能が利用できない状態です。\nこのメッセージを送信すると入社者とのやり取りが開始され、\n入社者からもメッセージの送受信が可能になります。\nメッセージを送信しますか？`}
      </Typography>
      <Typography variant="caption" color="textPrimary">
        この操作は取り消すことができません。
      </Typography>
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button
        fullWidth
        borderRadius="circle"
        variant="outlined"
        color="default"
        onClick={() => onCancel()}
      >
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={() => handleSubmit()}
        isLoading={isLoading}
      >
        送信
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="メッセージ送信確認"
      content={Content}
      footer={footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
