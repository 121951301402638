import { z } from "zod";

export const contactMessageDraftSchema = z.object({
  id: z.string().uuid(),
  contactRoomId: z.string().uuid(),
  createdUserId: z.string().uuid(),
  tenantId: z.string(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactMessageDraftSchema extends z.infer<typeof contactMessageDraftSchema> {}
