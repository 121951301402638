import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onSubmit: () => void;
};

// このモーダルはログインしていない状態でも開く必要があるため、ModalProviderに登録しない
export const ShouldRefreshPageModal: FC<Props> = ({ open, onSubmit }) => {
  return (
    <Modal
      open={open}
      title="新しいバージョンへの更新"
      content={
        <Box textAlign="center">
          <Typography align="center" variant="body1">
            Onnの新しいバージョンがリリースされました。
          </Typography>
          <Typography align="center" variant="body1">
            「更新」ボタンを押して、ページを更新してください。
          </Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            更新
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
