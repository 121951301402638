import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePickerView } from "@material-ui/pickers";
import { createStyles, makeStyles } from "@material-ui/styles";
import jaLocale from "date-fns/locale/ja";
import React, { ReactNode, ComponentProps, useCallback, FC } from "react";
import styled from "styled-components";

type Props = ComponentProps<typeof KeyboardDatePicker>;

const DEFAULT_DATE_FORMAT = "yyyy/MM/dd";
const DEFAULT_VIEWS: DatePickerView[] = ["year", "month", "date"];

export const DatePicker: FC<Props> = ({
  format = DEFAULT_DATE_FORMAT,
  views = DEFAULT_VIEWS,
  onError,
  ...rest
}) => {
  const { datePicker } = useStyles(views)();

  const handleError = useCallback(
    (error: ReactNode, value: Props["value"]) => {
      // error がないときにも onError が発火していたため、ここで弾いてる
      if (!onError || !error) {
        return;
      }
      onError(error, value);
    },
    [onError]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
      <StyledKeyboardDatePicker
        autoOk
        disableToolbar
        invalidDateMessage="日付フォーマットが不正です"
        margin="normal"
        maxDateMessage="日付が最大値を超えています"
        minDateMessage="日付が古すぎます"
        format={format}
        onError={handleError}
        DialogProps={{ className: datePicker }}
        views={views}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 16px;
  }

  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

// DatePickerのDialogはstyled-componentに対応していない(Mui v5で対応)
const useStyles = (views: DatePickerView[]) =>
  makeStyles(() =>
    createStyles({
      datePicker: {
        "& .MuiPickersCalendarHeader-transitionContainer": {
          // 年を含まない場合はモーダルヘッダーに年の情報を入れないためのstyle
          width: views.includes("year") ? "100%" : "40px",
        },
        "& .MuiPickersModal-dialogRoot": {
          margin: "16px",
          padding: "16px",
        },
        "& .MuiDialogActions-root": {
          display: "none",
        },
      },
    })
  );
