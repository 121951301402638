import { useRef, useEffect, RefObject } from "react";

/**
 * ドロップ領域へのドラッグ移動の最中に、親要素のdragenter/dragleaveイベントがトリガーされることを抑制するためのhooks
 *
 * @param listenerRef ドラッグ & ドロップの起点となる要素のRefObject
 * @param onDragEnter dragenterイベントのコールバック
 * @param onDragOver dragoverイベントのコールバック
 * @param onDragLeave dragleaveイベントのコールバック
 */
export const usePreventDragEventFromDropArea = ({
  listenerRef,
  onDragEnter,
  onDragOver,
  onDragLeave,
}: {
  listenerRef: RefObject<HTMLElement>;
  onDragEnter?: (e: DragEvent) => void;
  onDragOver?: (e: DragEvent) => void;
  onDragLeave?: (e: DragEvent) => void;
}): void => {
  /**
   * 現在ドロップ領域へ移動中であるかどうか
   */
  const draggingToDropAreaRef = useRef(false);

  useEffect(() => {
    if (!listenerRef.current) {
      return;
    }

    const listener = listenerRef.current;

    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      draggingToDropAreaRef.current = true;
      onDragEnter?.(e);
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      draggingToDropAreaRef.current = false;
      onDragOver?.(e);
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      // ドロップ領域へのドラッグ移動の最中に発生したdragleaveイベントの場合は、親要素で発生したdragleaveイベントではないとみなす
      if (draggingToDropAreaRef.current) {
        draggingToDropAreaRef.current = false;
        return;
      }
      onDragLeave?.(e);
    };

    listener.addEventListener("dragenter", handleDragEnter);
    listener.addEventListener("dragover", handleDragOver);
    listener.addEventListener("dragleave", handleDragLeave);

    return () => {
      listener.removeEventListener("dragenter", handleDragEnter);
      listener.removeEventListener("dragover", handleDragOver);
      listener.removeEventListener("dragleave", handleDragLeave);
    };
  }, [listenerRef, onDragEnter, onDragLeave, onDragOver]);
};
