import { useCallback } from "react";

/**
 * 特定のプロパティに部分一致するObjectをフィルターする関数を提供するhooks
 */
export const useFilterObjectByPartialMatch = () => {
  /**
   * 特定のプロパティに部分一致するObjectをフィルターする関数
   * @param objects フィルターする対象のObjectの配列
   * @param searchText 検索する文字列
   * @param getProperties 検索対象のプロパティを取得する関数
   */
  const filterObjectByPartialMatch = useCallback(
    <T>({
      objects,
      searchText,
      getProperties,
    }: {
      objects: T[];
      searchText: string;
      getProperties: Array<(object: T) => string>;
    }): T[] => {
      return objects.filter((object) => {
        const normalize = (string: string) => string.toLowerCase().replace(/\s+/g, "");
        const normalizedQuery = normalize(searchText);

        return getProperties.some((getProperty) => {
          const normalizedName = normalize(getProperty(object));

          return normalizedName.includes(normalizedQuery);
        });
      });
    },
    []
  );

  return { filterObjectByPartialMatch };
};
