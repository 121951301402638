import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  profileIconImageUrl?: string;
  username: string;
  onSubmit: () => Promise<void>;
};

export const DismissNewGraduateFollowerConfirmModal: FC<Props> = ({
  open,
  onCancel,
  profileIconImageUrl,
  username,
  onSubmit,
}) => {
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    setDeleting(true);
    await onSubmit()
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setDeleting(false);
      });
  }, [onCancel, onSubmit]);

  const contents = (
    <Box pt={2} textAlign="center">
      <UserIcon
        profileIconImageUrl={profileIconImageUrl}
        username={username}
        size="medium"
        borderColor="blue"
      />
      <Box height="8px" />
      <Typography variant="body2" bold noWrap>
        {username}
      </Typography>
      <Box mt={3}>
        <Typography variant="body1">このアカウントをフォロワーから外しますか？</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="caption">
          解除すると対象メンバーの情報を閲覧できなくなります。
        </Typography>
      </Box>
    </Box>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={deleting}
      >
        {deleting ? "解除中" : "解除"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="フォロワー解除"
      content={contents}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
