import { ContactMessageDraft } from "@onn/common";

import { IContactMessageDraftRepository } from "~/service/repository/iContactMessageDraftRepository";

export class SaveContactMessageDraftUseCase {
  constructor(private readonly contactMessageRepository: IContactMessageDraftRepository) {}

  async execute(contactMessageDraft: ContactMessageDraft): Promise<void> {
    await this.contactMessageRepository.save(contactMessageDraft);
  }
}
