import { ContactMessageDraft } from "@onn/common";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IContactMessageDraftRepository } from "~/service/repository/iContactMessageDraftRepository";
import { toFirestoreCompatible } from "~/util/toFirestoreCompatible";

const COLLECTION_NAME = "contactMessageDrafts";

export class ContactMessageDraftRepository implements IContactMessageDraftRepository {
  async fetchByCreatedUserId({
    createdUserId,
    tenantId,
  }: Pick<ContactMessageDraft, "createdUserId" | "tenantId">): Promise<ContactMessageDraft[]> {
    const snapshot = await getDocs(
      query(
        this.collection(),
        where("createdUserId", "==", createdUserId),
        where("tenantId", "==", tenantId)
      )
    );
    return snapshot.docs.map((d) => d.data());
  }

  async save(contactMessageDraft: ContactMessageDraft): Promise<void> {
    const docRef = this.doc(contactMessageDraft.id);

    await setDoc(docRef, contactMessageDraft, { merge: true });
  }

  private doc(id: string): DocumentReference<ContactMessageDraft> {
    return doc(firestore, COLLECTION_NAME, id).withConverter({
      toFirestore: (contactMessageDraft) => toFirestoreCompatible(contactMessageDraft),
      fromFirestore: (snapshot, options) => {
        const { id, createdUserId, contactRoomId, text, tenantId } = snapshot.data(options);
        return new ContactMessageDraft({
          id,
          createdUserId,
          contactRoomId,
          text,
          tenantId,
        });
      },
    });
  }

  private collection(): CollectionReference<ContactMessageDraft> {
    return collection(firestore, COLLECTION_NAME).withConverter({
      toFirestore: ({ contactRoomId, createdUserId, id, text }) => {
        return {
          id,
          createdUserId,
          contactRoomId,
          text,
        };
      },
      fromFirestore: (snapshot, options) => {
        const { id, createdUserId, contactRoomId, text, tenantId } = snapshot.data(options);
        return new ContactMessageDraft({
          id,
          createdUserId,
          contactRoomId,
          text,
          tenantId,
        });
      },
    });
  }
}
