import React, { FC } from "react";

export const Feedback: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-1199 -27)">
        <g transform="translate(1202 31)">
          <path
            d="M219.572,328.183h10.984a4.361,4.361,0,0,1,4.508,4.186v7.158a4.361,4.361,0,0,1-4.508,4.186h-5.14l-5.695,3.78a1,1,0,0,1-1.53-1.048l.615-2.792a4.3,4.3,0,0,1-3.742-4.126v-7.158A4.361,4.361,0,0,1,219.572,328.183Zm10.984,13.53a2.366,2.366,0,0,0,2.508-2.186v-7.158a2.366,2.366,0,0,0-2.508-2.186H219.572a2.366,2.366,0,0,0-2.508,2.186v7.158a2.366,2.366,0,0,0,2.508,2.186h.465a1,1,0,0,1,.977,1.215l-.337,1.53,3.884-2.578a1,1,0,0,1,.553-.167Z"
            transform="translate(-216.064 -329.184)"
            stroke="none"
          />
          <g transform="translate(-215.211 -120.953)">
            <path
              d="M225.212,322.564a.548.548,0,0,1-.577.513h-.845a.548.548,0,0,1-.578-.513v-.783a.548.548,0,0,1,.578-.513h.845a.548.548,0,0,1,.577.513Z"
              transform="translate(0 -191.989)"
              stroke="none"
            />
            <path
              d="M225.212,127.862a.548.548,0,0,1-.577.512h-.845a.548.548,0,0,1-.578-.512v-3.413a.548.548,0,0,1,.578-.513h.845a.548.548,0,0,1,.577.513Z"
              transform="translate(0 0)"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
