import { SWRResponse } from "swr";
import useImmutableSWR from "swr/immutable";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

/**
 * pathをもとにファイルにアクセスできるurlを取得するswr
 * @param path
 */
export const useFileUrl = (path?: string): SWRResponse<string | undefined, Error> => {
  const key = path ? path : null;

  return useImmutableSWR(key, (path) =>
    fileAPIAdapter
      .fetchUrl({
        path,
      })
      .then((res) => res)
  );
};
