import useSWR, { SWRResponse } from "swr";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { captureException } from "~/util";

/**
 * ログインしているブラウザのIpAddressを取得するswr
 */
export const useIpAddress = (): SWRResponse<string, Error> => {
  // localhostでは取得できないため確認しない
  const key = process.env.NODE_ENV !== "localhost" ? "ipAddress" : null;
  return useSWR(
    key,
    async () => functionOperator.httpsCall<unknown, string>("getIpAddress").then((res) => res.data), // NOTE: localhostではIPアドレスを取得できないため必ずnullを返す
    {
      onError: (e: Error) => {
        // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
        captureException({ error: e, tags: { type: "getIpAddress" } });
      },
    }
  );
};
