import { useEffect } from "react";

/**
 * body要素のスクロールをロックするhooks
 *
 * ウインドウのスクロールを防ぐ目的で利用されます
 *
 * @param enabled body要素のスクロールのロックを有効にするかどうか
 */
export const useLockBodyScroll = (enabled: boolean): void => {
  useEffect(() => {
    document.body.style.overflow = enabled ? "hidden" : ""; // enabled がfalseに切り替わった場合には、もとに戻す

    return () => {
      document.body.style.overflow = ""; // アンマウントされる際には、もとに戻す
    };
  }, [enabled]);
};
