import useSWR from "swr";

import { RevisionRepository } from "~/infrastructure/api/revisionRepository";

const revisionRepository = new RevisionRepository();
export const useRevision = () => {
  return useSWR("revision", async () => {
    return revisionRepository.findById("revision");
  });
};
