import { useReward as useRewardOrigin } from "react-rewards";

const defaultConfig = {
  emoji: ["👏", "👍", "😊", "💫", "💮"],
  lifetime: 200,
  angle: 90,
  decay: 0.91,
  spread: 100,
  startVelocity: 20,
  elementCount: 59,
  elementSize: 20,
  zIndex: 10,
};

/**
 * Emojiの設定値を固定するためにuseRewardをwrapするhooks
 * @param id rewardの発生元を指定するid
 * @see https://github.com/thedevelobear/react-rewards
 * @returns reward
 */
export const useReward = (
  id: string,
  config?: Partial<typeof defaultConfig>
): {
  reward: () => void;
} => {
  const { reward } = useRewardOrigin(id, "emoji", { ...defaultConfig, ...config });

  return { reward };
};
