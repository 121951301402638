import { Maintenance } from "@onn/common";

import { factory } from "~/service/repository/iMaintenanceRepository";

const maintenanceRepository = factory.maintenanceRepository();

export const MaintenanceUseCase = {
  async currentStatus(): Promise<Maintenance> {
    return maintenanceRepository.get();
  },
};
