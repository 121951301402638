import { Employee } from "@onn/common";
import { useCallback } from "react";

/**
 * 入社者に対する役割によって枠線の色を返す関数を提供するhooks
 */
export const useGetBorderColorById = () => {
  /**
   * 入社者に対する役割によって枠線の色を返す関数
   * @param {Employee} newHire 入社者
   * @param {string} targetEmployeeId 対象のEmployeeId
   */
  const getBorderColorById = useCallback((newHire: Employee, targetEmployeeId: string) => {
    if (newHire.mentorUserId && newHire.mentorUserId === targetEmployeeId) {
      return "primary";
    } else if (
      newHire.supportMemberEmployeeIds &&
      newHire.supportMemberEmployeeIds.includes(targetEmployeeId)
    ) {
      return "blue";
    } else {
      return undefined;
    }
  }, []);

  return { getBorderColorById };
};
