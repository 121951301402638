import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Paper, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = { newHireEmployeeName: string; isActive?: boolean };

export const JoiningPaper = (props: Props): JSX.Element => {
  const { newHireEmployeeName, isActive = false } = props;

  return (
    <StyledPaper isShowBgImage $isActive={isActive}>
      <Box textAlign="center">
        <Typography variant="body1" bold>
          {`本日から${newHireEmployeeName}さんが入社されます`}
        </Typography>

        <Box height="16px" />

        <Box lineHeight={2}>
          <Typography variant="body1">
            {`新しく入社された${newHireEmployeeName}さんに最高の入社体験をしてもらうために、\n人事やバディ・サポーターのチーム全員で暖かく迎え入れましょう！`}
          </Typography>
        </Box>

        <Box height="16px" />

        <Typography variant="caption" color="textSecondary">
          {`※Slackの#welcomeチャンネルで新メンバーの紹介が流れるので、\n暖かいコメントやリアクションをつけて歓迎しましょう！`}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;
