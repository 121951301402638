import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  tenantId: string;
  eventId: string;
  onSubmit: (tenantId: string, eventId: string) => Promise<void>;
  onCancel: () => void;
};

export const EventBulkRemindModal: FC<Props> = ({
  open,
  tenantId,
  eventId,
  onSubmit,
  onCancel,
}) => {
  const [sending, setSending] = useState(false);
  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit(tenantId, eventId).finally(() => {
      onCancel();
      setSending(false);
    });
  }, [tenantId, eventId, onCancel, onSubmit]);

  return (
    <StyledModal
      open={open}
      title="一括リマインド通知"
      onCancel={onCancel}
      content={
        <Box textAlign="center">
          <Typography>回答ステータスが「未回答」になっている配信対象の方へ</Typography>
          <Typography>一括でリマインド通知を送信しますか？</Typography>
          <Typography variant="caption">この操作は取り消すことができません。</Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={sending}
            onClick={handleSubmit}
          >
            送信
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
