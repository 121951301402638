import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { UserIcon, Typography } from "~/components/uiParts";

type Props = {
  currentUser: Employee;
  onClick?: () => void;
};

export const AccountSettingButton: FC<Props> = ({ currentUser, onClick }) => {
  const navigate = useNavigate();

  const path = currentUser.isNewcomer() ? "/portal/settings/account" : "/settings/account";
  const handleClickSetting = useCallback(() => {
    navigate(path);
    onClick && onClick();
  }, [navigate, onClick, path]);

  return (
    <StyledBox width="100%" onClick={handleClickSetting}>
      <UserIcon
        username={currentUser.getName()}
        profileIconImageUrl={currentUser.profileIconImageUrl}
        size="small"
      />
      <Box pl="16px">
        <Typography variant="body2" bold={true} color="textSecondary" noWrap>
          アカウント設定
        </Typography>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;
