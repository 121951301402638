import { Skeleton } from "@material-ui/lab";
import React, { FC, ComponentProps, useCallback } from "react";
import styled from "styled-components";

import { FileDropArea } from "~/components/uiParts/FileDropArea";
import { FilePicker } from "~/components/uiParts/FilePicker";
import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";
import { fetchImageSize } from "~/util";

type FileDropAreaProps = ComponentProps<typeof FileDropArea>;

type Props = {
  alt: string;
  defaultImage?: string; // URLかベクター
  imagePath: string;
  allowImageSize?: {
    max: { height: number; width: number };
    min: { height: number; width: number };
  };
  loading?: boolean;
  onChange: (image: File) => void;
  onError?: () => void;
  size: "small" | "large";
};

const SIZE = {
  small: 48,
  large: 160,
};

export const ImageUploadArea: FC<Props> = ({
  alt,
  defaultImage,
  imagePath,
  allowImageSize,
  loading,
  onChange,
  onError,
  size,
}) => {
  const handleUpload = useCallback(
    async (image: File) => {
      const { width, height } = await fetchImageSize(image);

      if (
        allowImageSize &&
        (height > allowImageSize.max.height ||
          width > allowImageSize.max.width ||
          height < allowImageSize.min.height ||
          width < allowImageSize.min.width)
      ) {
        if (onError) onError();
        return;
      }

      onChange(image);
    },
    [allowImageSize, onChange, onError]
  );

  const handleDropOrInputImage: FileDropAreaProps["onDropFiles"] = useCallback(
    (payload) => {
      if (payload.status === "error") {
        if (onError) onError();
        return;
      }

      const file = payload.files[0] as (typeof payload.files)[number];
      handleUpload(file);
    },
    [onError, handleUpload]
  );

  return (
    <StyledFileDropAreaWrapper size={SIZE[size]}>
      <FileDropArea onDropFiles={handleDropOrInputImage} accepts={["image"]}>
        <FilePicker accepts={["image"]} inputAccept="image/*" onChange={handleDropOrInputImage}>
          <StyledInputWrapper size={SIZE[size]}>
            {loading ? (
              <StyledSkeleton />
            ) : (
              <>
                {size === "large" && (
                  <StyledTypography noWrap variant="caption" color="inherit">
                    <StyledIcon icon="photo" color="white" />
                    写真を変更
                  </StyledTypography>
                )}
                {(imagePath || defaultImage) && (
                  <StyledImage
                    height="100%"
                    width="100%"
                    src={imagePath || defaultImage}
                    alt={alt}
                  />
                )}
              </>
            )}
          </StyledInputWrapper>
        </FilePicker>
      </FileDropArea>
    </StyledFileDropAreaWrapper>
  );
};

const StyledIcon = styled(Icon)`
  width: 48px;
  height: 48px;
`;

const StyledFileDropAreaWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  & > div {
    border: none !important;
  }
`;

const StyledInputWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 4px;
  position: relative;

  :hover {
    ::before {
      background-color: rgba(0, 0, 0, 0.4);
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  ::before {
    pointer-events: none;
    cursor: pointer;
    border-radius: 4px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: background-color 0.3s ease;
  }
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;
  color: white !important;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;

  ${StyledInputWrapper}:hover & {
    display: flex;
  }
`;

const StyledImage = styled.img`
  object-fit: contain;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 100%;
  width: 100%;
  transform: none;
`;
