import { z } from "zod";

export const contactRoomUnreadCountSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  contactRoomId: z.string(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactRoomUnreadCountSchema
  extends z.infer<typeof contactRoomUnreadCountSchema> {}
