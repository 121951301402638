import { Box, CircularProgress } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type Props = {
  percent: number;
  color: "primary" | "secondary";
};

export const ProgressCircle: FC<Props> = ({ percent, color }) => {
  return (
    <Box position="relative" display="inline-flex">
      <StyledCircleProgress variant="determinate" value={percent} size={64} color={color} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2" color="textPrimary">
          {percent}%
        </Typography>
      </Box>
    </Box>
  );
};

const StyledCircleProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    // 緑のバーを上に表示させるためのz-index
    z-index: ${(props) => props.theme.zIndex.mobileStepper - 1};
  }
  &.MuiCircularProgress-root + .MuiBox-root {
    border-radius: 50%;
    border: 5px solid ${(props) => props.theme.palette.grey[200]};
  }
`;
