import { LinearProgress as MuiLinearProgress } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

type Props = ComponentProps<typeof MuiLinearProgress>;

export const LinearProgress: FC<Props> = (props) => {
  return <StyledMuiLinearProgress {...props} />;
};

const StyledMuiLinearProgress = styled(MuiLinearProgress)`
  &.MuiLinearProgress-determinate {
    height: 10px;
    border-radius: 20px;
  }

  & > .MuiLinearProgress-bar {
    height: 10px;
    border-radius: 20px;
  }
`;
