import { isSameDay } from "date-fns";

import { holidaysJp } from "../../constants";

/**
 * 祝日かどうかを判定する関数
 * @param date
 */
export const isHolidays = (date: Date) => {
  return holidaysJp.some((holiday) => isSameDay(date, holiday));
};
