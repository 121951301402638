import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../hooks";

import { UserIconGroup, Typography } from "~/components/uiParts";

type Props = {
  newcomerEmployee: Employee;
  users: Employee[];
};

export const NotSetJoinAt: FC<Props> = ({ newcomerEmployee, users }) => {
  const { getBorderColorById } = useGetBorderColorById();
  return (
    <>
      <Box textAlign="center">
        <Typography variant="h2" bold color="primary">
          🎉Welcome!🎉
        </Typography>

        <Box height="16px" />

        <Box lineHeight={2} textAlign="center">
          <Typography variant="body2">
            {`${newcomerEmployee.lastName}さんの入社を\nメンバー全員で楽しみにお待ちしています！`}
          </Typography>
        </Box>
      </Box>

      <StyledUserIconGroup
        usersInfo={users.map((u) => ({
          username: u.getName(),
          profileIconImageUrl: u.profileIconImageUrl,
          borderColor: getBorderColorById(newcomerEmployee, u.id),
        }))}
        max={users.length}
      />
    </>
  );
};

const StyledUserIconGroup = styled(UserIconGroup)`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;
