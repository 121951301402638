import { Box, Drawer, IconButton as MaterialUiIconButton } from "@material-ui/core";
import React, { FC, ReactNode, useState } from "react";
import styled from "styled-components";

import { Badge, Icon, IconButton } from "~/components/uiParts";
import logo from "~/images/logo-sp.svg";
type Props = {
  isBadge: boolean;
  logoInfo?: {
    isDisplay: true;
    handleClick: () => void;
  };
  renderDrawerContent: (closeDrawer: () => void) => ReactNode;
};
export const GlobalNaviMenu: FC<Props> = ({ isBadge, logoInfo, renderDrawerContent }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const closeDrawer = () => setAnchorEl(false);
  const openDrawer = () => setAnchorEl(true);

  return (
    <>
      <MaterialUiIconButton onClick={openDrawer} edge="end" color="inherit" aria-label="menu">
        <Badge color="secondary" variant={isBadge ? "dot" : "standard"}>
          <Icon icon="menuHor" color="grey" size="md" />
        </Badge>
      </MaterialUiIconButton>
      <StyledDrawer
        anchor="right"
        open={anchorEl}
        onClose={closeDrawer}
        BackdropProps={{ invisible: true }}
      >
        <StyledWrapperBox>
          <StyledHeaderBox>
            {logoInfo?.isDisplay && (
              <StyledImage
                src={logo}
                alt="ロゴ"
                onClick={() => {
                  closeDrawer();
                  logoInfo.handleClick();
                }}
              />
            )}
            <div></div> {/* ロゴ非表示の場合にも閉じるボタンを右寄せるにするための要素 */}
            <IconButton icon="close" size="sm" color="grey" onClick={closeDrawer} />
          </StyledHeaderBox>
          <StyledContentBox>{renderDrawerContent(closeDrawer)}</StyledContentBox>
        </StyledWrapperBox>
      </StyledDrawer>
    </>
  );
};

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 375px;
    opacity: 0.95;
  }
`;

const StyledHeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

const StyledImage = styled.img`
  max-height: 24px;
  ${(props) => props.onClick && `cursor: pointer;`}
`;

const StyledWrapperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 16px 0;
`;

const StyledContentBox = styled(Box)`
  overflow-y: auto;
  flex-grow: 2;
  padding: 40px 24px 0px;
`;
