import { ListItem, ListItemIcon, ListItemText, Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { Icon, Tooltip, Typography } from "~/components/uiParts";

import { loadHeadway } from "~/util/loadHeadway";

type Props = {
  shouldShowName: boolean;
};
export const NotificationSidebarListItem: FC<Props> = React.memo(({ shouldShowName }) => {
  const TooltipComponent = React.memo(({ children }: { children: ReactNode }) => {
    return shouldShowName ? (
      <>{children}</>
    ) : (
      <Tooltip title="リリースノート" placement="right" arrow>
        <> {children}</>
      </Tooltip>
    );
  });
  const StyledListItemComponent = React.memo(() => {
    return (
      <StyledBox p={0}>
        <StyledListItem button $shouldShowName={shouldShowName}>
          <ListItemIcon>
            <Icon icon="notice" size="md" color="grey" />
          </ListItemIcon>
          {shouldShowName && (
            <ListItemText
              primary={
                <Typography variant="body2" bold color="textSecondary">
                  リリースノート
                </Typography>
              }
            />
          )}
          <div ref={() => loadHeadway()} className="headway" />
        </StyledListItem>
      </StyledBox>
    );
  });

  return (
    <Box>
      <TooltipComponent>
        <StyledListItemComponent />
      </TooltipComponent>
    </Box>
  );
});

const StyledListItem = styled(ListItem)<{ $shouldShowName: boolean }>`
  &.MuiButtonBase-root.MuiListItem-root {
    margin-left: 32px;
    width: ${(props) => (props.$shouldShowName ? 190 : 40)}px;
    padding: 8px;
    background-color: ${(props) => props.theme.palette.common.white};
    border-radius: 8px;
  }

  &.MuiButtonBase-root.Mui-selected {
    /* 左端のボーダーの幅4px分減らしている */
    margin-left: calc(32px - 4px);
    box-sizing: border-box;
    :hover {
      background-color: ${(props) => props.theme.palette.grey[50]};
    }
  }

  &.MuiButtonBase-root:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }

  .MuiListItemText-root {
    margin: 0px;
  }
`;

const StyledBox = styled(Box)`
  #HW_badge_cont {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }

  #HW_badge {
    top: 0px;
    left: 28px;
    height: 8px;
    width: 8px;
    /* 数字を非表示にするために設定している */
    font-size: 0px;
    &.HW_visible {
      background-color: #fc7878;
    }
    /* 既読状態の場合 */
    &.HW_softHidden {
      display: none;
    }
    /* 跳ねるアニメーションを無効にしている */
    &.HW_animated {
      animation: none;
    }
  }
`;
