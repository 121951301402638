import React, { FC } from "react";

export const Security: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <style>{`.sec_a{fill:none;}.sec_b{fill:#fff;strokeWidth:2px;}.sec_d{stroke:none;}`}</style>
      </defs>
      <g transform="translate(-292 -112)">
        <g className="sec_b" transform="translate(298 114)">
          <rect className="d" width="12" height="17" rx="6" />
          <rect className="sec_a" x="1" y="1" width="10" height="15" rx="5" />
        </g>
        <g className="sec_b" transform="translate(295 120)">
          <rect className="d" width="18" height="14" rx="2" />
          <rect className="sec_a" x="1" y="1" width="16" height="12" rx="1" />
        </g>
        <path
          className="sec_c"
          d="M-4344,1666v-1.268a2,2,0,0,1-1-1.732,2,2,0,0,1,2-2,2,2,0,0,1,2,2,2,2,0,0,1-1,1.732V1666a1,1,0,0,1-1,1A1,1,0,0,1-4344,1666Z"
          transform="translate(4647 -1537)"
        />
      </g>
    </svg>
  );
};
