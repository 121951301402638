import React, { FC } from "react";

export const Setting: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-42 -295)">
        <g transform="translate(42 295)">
          <path
            d="M80.916,71.147H80.2a1.1,1.1,0,0,1-1.07-1.1,1.025,1.025,0,0,1,.352-.773l.46-.45a1.15,1.15,0,0,0,0-1.636L78.9,66.156a1.192,1.192,0,0,0-1.642,0l-.441.44a1.06,1.06,0,0,1-.8.361,1.1,1.1,0,0,1-1.1-1.064v-.717A1.172,1.172,0,0,0,73.765,64H72.338a1.165,1.165,0,0,0-1.145,1.176v.712a1.1,1.1,0,0,1-1.1,1.064,1.05,1.05,0,0,1-.779-.347l-.455-.45a1.157,1.157,0,0,0-.821-.328,1.185,1.185,0,0,0-.821.328l-1.056,1.031a1.153,1.153,0,0,0,0,1.631l.441.441a1.06,1.06,0,0,1,.366.792,1.093,1.093,0,0,1-1.07,1.1h-.713A1.162,1.162,0,0,0,64,72.285V73.71a1.165,1.165,0,0,0,1.182,1.139H65.9a1.1,1.1,0,0,1,1.07,1.1,1.06,1.06,0,0,1-.366.792l-.441.436a1.153,1.153,0,0,0,0,1.631l1.046,1.04a1.157,1.157,0,0,0,.821.328,1.186,1.186,0,0,0,.821-.328l.455-.45a1.042,1.042,0,0,1,.779-.347,1.1,1.1,0,0,1,1.1,1.064v.712A1.169,1.169,0,0,0,72.334,82H73.76a1.165,1.165,0,0,0,1.145-1.176v-.712a1.09,1.09,0,0,1,1.9-.7l.441.441a1.192,1.192,0,0,0,1.642,0l1.046-1.04a1.159,1.159,0,0,0,0-1.635l-.46-.45a1.032,1.032,0,0,1-.352-.773,1.093,1.093,0,0,1,1.07-1.1h.713A1.09,1.09,0,0,0,82,73.715V72.285A1.077,1.077,0,0,0,80.916,71.147ZM76.8,73h0a3.754,3.754,0,0,1-7.508,0h0A3.754,3.754,0,0,1,76.8,73Z"
            transform="translate(-61 -61)"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};
