import { Chip as MuiChip } from "@material-ui/core";
import React, { FC, ComponentProps } from "react";
import styled from "styled-components";

import { mixin } from "~/util";

export const AddChip: FC<ComponentProps<typeof MuiChip>> = ({ ...props }) => {
  return <StyledChip {...props} />;
};

const StyledChip = styled(MuiChip)`
  line-height: 50%;
  &.MuiChip-root {
    font-size: 10px;
    height: 28px;
    width: 28px;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: #fff;
    border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
    > .MuiChip-label {
      padding: 0px;
    }
  }

  /* onClickが有効の場合 */
  &.MuiChip-clickable:hover,
  &.MuiChip-clickable:focus {
    background-color: ${(props) =>
      `rgba(${mixin.generateRgbaFromColorCode(props.theme.palette.primary.main)},0.2)`};
  }
`;
