import { Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

/**
 * idをもとに複数employeeを返すswr
 * @param employeeIds employeeのid の配列
 */
export const useEmployees = (employeeIds?: string[]): SWRResponse<Employee[], Error> => {
  const keys = employeeIds ? [employeeIds.map((v) => `employee-${v}`), employeeIds] : null;

  return useSWR(keys, ([_key, employeeIds]: [string, string[]]) =>
    EmployeeUseCase.findByIds(employeeIds).then((res) => res)
  );
};
