import { Timestamp } from "firebase/firestore";

/**
 * Timestamp 型を Date 型に変換します
 * @param {Timestamp} timestamp 変換する対象の Timestamp 型のデータ
 * @returns {Date}
 */
export const convertTimestampToDate = (timestamp: Timestamp): Date => {
  return timestamp.toDate();
};
