import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  eventTitle: string;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export const EventDeleteConfirmModal: FC<Props> = ({ open, eventTitle, onSubmit, onCancel }) => {
  const [sending, setSending] = useState(false);
  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit().finally(() => {
      onCancel();
      setSending(false);
    });
  }, [onCancel, onSubmit]);

  return (
    <StyledModal
      open={open}
      title="イベント削除"
      onCancel={onCancel}
      content={
        <Box textAlign="center">
          <Typography>「{eventTitle}」を削除しますか？</Typography>
          <Typography variant="caption">この操作は取り消すことができません。</Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            disabled={sending}
            onClick={handleSubmit}
          >
            削除
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
