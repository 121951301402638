import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";
import { mixin } from "~/util";

type Props = {
  text: React.ReactNode;
  color: "success" | "error" | "info";
};

export const Snackbar: FC<Props> = ({ color, text }) => {
  return (
    <StyledBox
      py="12px"
      px="16px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gridGap="8px"
      className={`snackbar-${color}`}
    >
      {color === "success" && <Icon icon="check" size="sm" color="primary" />}
      {color === "error" && <Icon icon="error" size="sm" color="secondary" />}
      <Typography variant="caption" bold>
        {text}
      </Typography>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: white;
  border-radius: 8px;
  ${mixin.breakUp.sm`
    width: 50vw;
  `}
  &.snackbar-success {
    color: ${(props) => props.theme.palette.primary.main};
    border: ${(props) => `2px solid ${props.theme.palette.primary.main}`};
  }
  &.snackbar-error {
    color: ${(props) => props.theme.palette.secondary.main};
    border: ${(props) => `2px solid ${props.theme.palette.error.main}`};
  }
  &.snackbar-info {
    color: ${(props) => props.theme.palette.blue.main};
    border: ${(props) => `2px solid ${props.theme.palette.blue.main}`};
  }
`;
