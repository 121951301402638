/**
 * Fileオブジェクトをbase64エンコードするPromiseを返します
 * @param file エンコードしたいFileオブジェクト
 */
export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve((event.target?.result || "") as string);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
