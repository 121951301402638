import { Box } from "@material-ui/core";
import React, { FC, useState, useCallback, useMemo, ComponentProps } from "react";
import styled from "styled-components";

import { DeleteMemoConfirmModal } from "./DeleteMemoConfirmModal";
import { MemoEmptyPaper } from "./MemoEmptyPaper";
import { MemoItem } from "./MemoItem";

import { MemoForm } from "~/components/domains/transactions";
import { Loading } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";

type MemoFormProps = ComponentProps<typeof MemoForm>;

type MemoItemProps = ComponentProps<typeof MemoItem>;

type TransactionCommentsMap = {
  [transactionId: string]: MemoItemProps["commentListProps"]["comments"];
};

type AttachedFilesMap = {
  [memoId: string]: MemoItemProps["attachedFiles"];
};

type Props = {
  loading: boolean;
  attachedFilesLoading: boolean;
  currentUserId: string;
  memos: MemoItemProps["memo"][];
  attachedFilesMap: AttachedFilesMap;
  onCreateMemo: MemoFormProps["onSubmit"];
  onEditMemo: MemoItemProps["onEditMemo"];
  onDeleteMemo: (memoId: string) => void;
  transactionCommentsMap: TransactionCommentsMap;
  commentFocused: MemoItemProps["commentFocused"];
  commentListProps: Omit<
    MemoItemProps["commentListProps"],
    "comments" | "commentDisabled" | "on" | "onAcceptDeleteComment"
  >;
  onDownloadAttachedFile: MemoItemProps["onDownloadAttachedFile"];
  onDeleteAttachedFile: MemoItemProps["onDeleteAttachedFile"];
  targetMemoId: string | null;
};

export const MemoTab: FC<Props> = ({
  loading,
  attachedFilesLoading,
  currentUserId,
  memos,
  attachedFilesMap,
  onCreateMemo,
  onEditMemo,
  onDeleteMemo,
  transactionCommentsMap,
  commentFocused,
  commentListProps,
  onDownloadAttachedFile,
  onDeleteAttachedFile,
  targetMemoId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteTargetMemoId, setDeleteTargetMemoId] = useState("");
  const [createMemoFormVisible, setCreateMemoFormVisible] = useState(false);
  /**
   * 再読み込み等でMemoTabが再マウントされた際に、ウインドウが自動スクロールされないようにするため
   */
  const [alreadyScrolledToMemo, setAlreadyScrolledToMemo] = useState(false);

  const deleteTargetMemo = useMemo(
    () => memos.find((memo) => memo.id === deleteTargetMemoId),
    [memos, deleteTargetMemoId]
  );

  const handleSubmitMemo = useCallback<MemoFormProps["onSubmit"]>(
    async ({ title, text, attachedFiles }) => {
      setCreateMemoFormVisible(false);
      onCreateMemo({ title, text, attachedFiles });
    },
    [onCreateMemo]
  );

  const handleDeleteMemo = useCallback(
    (memoId: string) => {
      setDeleteTargetMemoId("");
      onDeleteMemo(memoId);
    },
    [onDeleteMemo]
  );

  const handleDropAttachedFilesError = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: "error" }),
    [enqueueSnackbar]
  );

  if (loading) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <>
      <Box pt={5} pb={5}>
        {createMemoFormVisible && (
          <MemoForm
            attachedFiles={[]}
            onSubmit={handleSubmitMemo}
            onCancel={() => setCreateMemoFormVisible(false)}
            onDropFilesError={handleDropAttachedFilesError}
          />
        )}
        {!createMemoFormVisible && memos.length > 0 && (
          <StyledAddMemoButton onClick={() => setCreateMemoFormVisible(true)}>
            + メモを追加
          </StyledAddMemoButton>
        )}
        {!createMemoFormVisible && memos.length === 0 && (
          <MemoEmptyPaper onClickAddMemoButton={() => setCreateMemoFormVisible(true)} />
        )}
      </Box>
      {memos.length > 0 &&
        memos.map((memo) => (
          <Box key={memo.id} pb={3}>
            <MemoItem
              memo={memo}
              attachedFiles={attachedFilesMap[memo.id] ?? []}
              attachedFilesLoading={attachedFilesLoading}
              alreadyScrolledToMemo={alreadyScrolledToMemo}
              onScrolledToMemo={() => setAlreadyScrolledToMemo(true)}
              onEditMemo={onEditMemo}
              currentUserId={currentUserId}
              onClickDeleteMemoMenu={(memoId) => setDeleteTargetMemoId(memoId)}
              commentFocused={commentFocused}
              commentListProps={{
                comments: transactionCommentsMap[memo.id] ?? [],
                commentDisabled: loading,
                ...commentListProps,
              }}
              onDownloadAttachedFile={onDownloadAttachedFile}
              onDeleteAttachedFile={onDeleteAttachedFile}
              isActive={memo.id === targetMemoId}
            />
          </Box>
        ))}
      {deleteTargetMemo ? (
        <DeleteMemoConfirmModal
          memo={deleteTargetMemo}
          open={!!deleteTargetMemoId}
          onCancel={() => setDeleteTargetMemoId("")}
          onAccept={handleDeleteMemo}
        />
      ) : null}
    </>
  );
};

const StyledAddMemoButton = styled.button`
  width: 100%;
  padding: 24px 0px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 16px;
  border: 1px dashed ${(props) => props.theme.palette.grey[200]};
  outline: none;
  touch-action: manipulation;
  user-select: none;
  background-color: white;
  border-radius: 4px;
  transition: background-color 0.2s;

  :hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;
