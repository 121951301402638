import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { constants } from "~/config/theme";

type Props = {
  children: ReactNode;
  minWidth?: number;
};

export const AdminContentWrapper: FC<Props> = ({ children, minWidth }) => {
  return (
    <StyledBox $minWidth={minWidth || constants.ADMIN_CONTENT_MIN_WIDTH}>{children}</StyledBox>
  );
};

const StyledBox = styled(Box)<{ $minWidth: number }>`
  ${(props) => `min-width: ${props.$minWidth}px`}
`;
