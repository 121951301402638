import { Box, Grid } from "@material-ui/core";
import React, { useMemo, FC } from "react";
import styled from "styled-components";

import { IconButton } from "~/components/uiParts/IconButton";
import { Tooltip } from "~/components/uiParts/Tooltip";
import { Typography } from "~/components/uiParts/Typography";
import { getNameAndExtensionFromFilePath } from "~/util";

type Props = {
  fileName: string;
  previewable: boolean;
  zoomPercent: number;
  zoomInDisabled: boolean;
  zoomOutDisabled: boolean;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onDownload: () => void;
  onDeleteFile?: () => void;
  onClose: () => void;
};

type FileType = {
  name: string;
  extension: string;
};

export const Header: FC<Props> = ({
  fileName,
  previewable,
  zoomPercent,
  zoomInDisabled,
  zoomOutDisabled,
  onZoomIn,
  onZoomOut,
  onDownload,
  onDeleteFile,
  onClose,
}) => {
  const fileType = useMemo<FileType>(() => {
    const { name, extension } = getNameAndExtensionFromFilePath(fileName);

    return {
      name,
      extension: `.${extension}`,
    };
  }, [fileName]);

  return (
    <StyledContainer>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography variant="body2" bold noWrap>
              {fileType.name}
            </Typography>
            <Typography variant="body2" bold>
              {fileType.extension}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          {previewable ? (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <IconButton
                icon="remove"
                size="sm"
                color="white"
                disabled={zoomOutDisabled}
                onClick={onZoomOut}
              />
              <StyledZoomPercentLabel variant="body2" noWrap>
                {`${zoomPercent}%`}
              </StyledZoomPercentLabel>
              <IconButton
                icon="add"
                size="sm"
                color="white"
                disabled={zoomInDisabled}
                onClick={onZoomIn}
              />
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Box>
              <Tooltip title="ダウンロード" placement="bottom" arrow>
                <IconButton icon="download" size="md" color="white" onClick={onDownload} />
              </Tooltip>
              {onDeleteFile && (
                <Tooltip title="削除" placement="bottom" arrow>
                  <IconButton icon="trash" size="md" color="white" onClick={onDeleteFile} />
                </Tooltip>
              )}
            </Box>
            <Box ml={2}>
              <Tooltip title="閉じる" placement="bottom" arrow>
                <IconButton icon="close" size="sm" color="white" onClick={onClose} />
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #1f1f1f;
  padding: 0px 24px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const StyledZoomPercentLabel = styled(Typography)`
  min-width: 4em; // 最低でも桁数 + 単位の4文字部分確保することで、数値の桁が増減した際にガクつかないようにする
  text-align: center;
`;
