import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  deleteTagName: string;
  onCancel: () => void;
  onAccept: () => Promise<void>;
};
export const ConfirmDeleteTagModal: FC<Props> = ({ open, deleteTagName, onCancel, onAccept }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = useCallback(() => {
    setIsLoading(true);
    onAccept()
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [onAccept, onCancel]);

  return (
    <Modal
      open={open}
      title="タグ削除"
      content={
        <>
          <Box mb={2} textAlign="center" display="flex" flexDirection="column" gridGap="18px">
            <Typography variant="body1">
              {`${deleteTagName}を削除した場合、このタグが設定されている候補者のタグも同時に削除されますがよろしいですか？`}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません
            </Typography>
          </Box>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            onClick={handleAccept}
            isLoading={isLoading}
          >
            削除
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
