import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Paper } from "~/components/uiParts/Paper";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  title: string;
  children: React.ReactNode;
  showCloseAnnotation?: boolean;
};

export const Notice: FC<Props> = ({ title, children, showCloseAnnotation = true }) => {
  return (
    <>
      <StyledPaper>
        <Typography variant="h2" bold color="primary" align="center">
          {title}
        </Typography>
        <Box height="24px" />
        {children}
      </StyledPaper>
      {showCloseAnnotation && (
        <Box mt="16px" textAlign="center">
          <Typography variant="caption" color="textSecondary" align="center">
            ブラウザを閉じて終了してください。
          </Typography>
        </Box>
      )}
    </>
  );
};

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 40px;
  }
`;
