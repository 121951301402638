import React, { ReactNode, FC } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

type Props = {
  children: ReactNode;
  onDragStart?: (sourceIndex: number) => void;
  onDragEnd: (result: DropResult) => void;
};

export const DnDProvider: FC<Props> = ({ children, onDragStart, onDragEnd }) => {
  return (
    <DragDropContext
      onDragStart={onDragStart ? ({ source }) => onDragStart(source.index) : undefined}
      onDragEnd={onDragEnd}
    >
      {children}
    </DragDropContext>
  );
};
