import { useCallback } from "react";

import User from "~/images/user.svg";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

export const useGetIconUrl = () => {
  const getIconUrl = useCallback((profileIconImageUrl: string) => {
    let urlStr: string;
    // GCS でない URL
    if (!profileIconImageUrl) {
      return User;
    }
    // GCS の URL
    if (/(http(s?)):\/\//i.test(profileIconImageUrl)) {
      // https:// から始まるpropsを受け取った場合は、それをそのままurlとする
      urlStr = profileIconImageUrl;
    } else {
      // バケットの相対パスを受け取った場合は、絶対パスをurlに入れる
      urlStr = fileAPIAdapter.getFileUrl(profileIconImageUrl);
    }

    return urlStr;
  }, []);

  return { getIconUrl };
};
