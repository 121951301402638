import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const googleAnalyticsId = "G-9T4MFH9TED";

function initializeGA(measurementId: string) {
  // load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs
  const loadGtagScript = document.createElement("script");
  loadGtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + measurementId;
  loadGtagScript.async = true;
  document.body.appendChild(loadGtagScript);

  const defineGtagScript = document.createElement("script");
  // プレーンテキスト、グローバルな window.gtag 関数を定義するため
  defineGtagScript.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'GA_MEASUREMENT_ID');`;
  document.body.appendChild(defineGtagScript);
}

type GTagConfigType = { page_path: string } | { event_category?: string; event_label?: string };

declare global {
  interface Window {
    gtag?: (key: string, trackingId: string, config: GTagConfigType) => void;
  }
}

export const GoogleAnalytics = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // 本番環境でのみ実行する
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    initializeGA(googleAnalyticsId);

    if (!window.gtag) return;
    window.gtag("config", googleAnalyticsId, { page_path: location.pathname });
  }, [pathname]);

  return null;
};
