import React, { FC } from "react";

export const Check: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.142 10.607">
      <g transform="translate(-290.8 -163.828)" stroke="none">
        <rect width="8" height="3" rx="1.5" transform="translate(292.922 166.656) rotate(45)" />
        <rect width="12" height="3" rx="1.5" transform="translate(294.336 172.313) rotate(-45)" />
      </g>
    </svg>
  );
};
