type QueryObject = Record<string, string | string[] | undefined | null>;
/**
 * クエリストリングにvalueをsetしたurlを返す
 * @param {QueryObject} obj { target: value } valueがnullやundefinedのとき、クエリストリングは削除されます
 * @param {string} queryString '?employee=newHire1' のようなクエリ文字列、?の有無はどちらでも良い
 */
export const joinQuery = (obj: QueryObject, queryString?: string) => {
  const searchParams = new URLSearchParams(queryString);

  Object.entries(obj).map(([key, value]) => {
    searchParams.delete(key);

    if (typeof value === "string") {
      searchParams.set(key, value ?? "");
    }
    if (Array.isArray(value)) {
      value.map((v) => {
        searchParams.append(key, v ?? "");
      });
    }
  });

  return searchParams.toString();
};
