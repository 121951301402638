import React, { FC } from "react";

export const Onboarding: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-42 -183)">
        <g transform="translate(42 183)">
          <path
            d="M1416.81,570.572a4.837,4.837,0,0,0-6.885,0l-.689.7-.689-.7a4.837,4.837,0,0,0-6.885,0,4.948,4.948,0,0,0,0,6.951l4.131,4.171h0a4.837,4.837,0,0,0,6.885,0l4.132-4.171A4.947,4.947,0,0,0,1416.81,570.572Zm-12.1,5.855-.979-.989a1.982,1.982,0,0,1,0-2.781,1.936,1.936,0,0,1,2.754,0l.688.7-1.377,1.39A4.893,4.893,0,0,0,1404.708,576.427Zm10.037-.989-4.132,4.171a1.936,1.936,0,0,1-2.754,0,1.982,1.982,0,0,1,0-2.781l4.131-4.171a1.937,1.937,0,0,1,2.754,0,1.979,1.979,0,0,1,0,2.781Z"
            transform="translate(-1397.236 -564.133)"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};
