/**
 * 画像ファイルのサイズをチェックする
 * https://gotohayato.com/content/519/
 *
 * @param file 画像ファイルオブジェクト
 * @returns 画像の幅、高さのpx数を返すPromiseインスタンス
 */
export const fetchImageSize = async (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const size = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };

      URL.revokeObjectURL(img.src);
      resolve(size);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = URL.createObjectURL(file);
  });
};
