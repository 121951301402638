import { Box } from "@material-ui/core";
import React, { FC, useCallback, useRef, ReactNode } from "react";
import styled from "styled-components";

import { useDebouncedCallback } from "~/hooks/shared";

let pos = { top: 0, left: 0, x: 0, y: 0 };

type Props = {
  children: ReactNode;
  onScroll?: ({ scrollTop, scrollLeft }: { scrollTop: number; scrollLeft: number }) => void;
};

/**
 * DragでScrollできる領域を提供する
 * NOTE: https://htmldom.dev/drag-to-scroll/
 */
export const DragToScrollArea: FC<Props> = ({ children, onScroll }) => {
  const scrollAreaRef = useRef<HTMLFormElement>(null);
  const handleScroll = useDebouncedCallback((callback) => callback(), 200);

  const mouseMoveHandler = useCallback((e: MouseEventInit) => {
    if (!scrollAreaRef?.current || !e.clientX || !e.clientY) return;

    // Grabで操作した距離
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // スクロールさせる
    scrollAreaRef.current.scrollTop = pos.top - dy;
    scrollAreaRef.current.scrollLeft = pos.left - dx;
  }, []);

  const mouseUpHandler = useCallback(() => {
    if (!scrollAreaRef?.current) return;
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);

    scrollAreaRef.current.style.cursor = "grab";
  }, [mouseMoveHandler]);

  const mouseDownHandler = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (!scrollAreaRef?.current) return;
      pos = {
        // The current scroll
        left: scrollAreaRef.current.scrollLeft,
        top: scrollAreaRef.current.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      scrollAreaRef.current.style.cursor = "grabbing";

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    },
    [mouseMoveHandler, mouseUpHandler]
  );

  return (
    // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
    // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
    <StyledBox
      {...{ ref: scrollAreaRef }}
      onMouseDown={mouseDownHandler}
      height="100%"
      width="100%"
      onScroll={
        onScroll
          ? () =>
              handleScroll(() =>
                onScroll({
                  scrollTop: scrollAreaRef?.current ? scrollAreaRef.current.scrollTop : 0,
                  scrollLeft: scrollAreaRef?.current ? scrollAreaRef.current.scrollLeft : 0,
                })
              )
          : undefined
      }
    >
      {children}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  overflow: scroll;
  cursor: grab;
`;
