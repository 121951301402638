import { ContactMessage, ContactMessageWithCreator, Employee } from "@onn/common";
import { useCallback, useState } from "react";
import { mutate } from "swr";
import { v4 } from "uuid";

import { useSnackbar } from "../shared";

import { useTenant } from "../tenant";

import {
  generateContactMessagesKey,
  useContactMessages,
} from "./useContactMessages/useContactMessages";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

type NewStampContactMessageArgs = {
  tenantId: string;
  employee: Employee;
  contactRoomId: string;
  stickerId: string;
};

function newStampContactMessage({
  tenantId,
  employee,
  contactRoomId,
  stickerId,
}: NewStampContactMessageArgs) {
  return ContactMessage.createNewMessage({
    contactRoomId,
    creator: { employee },
    tenantId,
    platformTypeCreatedBy: "onn",
    stickerId,
  });
}

type NewContactMessageArgs = {
  tenantId: string;
  employee: Employee;
  contactRoomId: string;
  text: string;
  newMessageFiles?: File[];
};

function newContactMessage({
  tenantId,
  employee,
  contactRoomId,
  text,
  newMessageFiles,
}: NewContactMessageArgs) {
  const filePaths = newMessageFiles?.map((newMessageFile) => {
    const uid = v4();
    return `tenants/${tenantId}/contact_messages/${uid}/${newMessageFile.name}`;
  });
  return ContactMessage.createNewMessage({
    text,
    contactRoomId,
    creator: { employee },
    tenantId,
    platformTypeCreatedBy: "onn",
    filePaths: filePaths?.length ? filePaths : undefined,
  });
}

/**
 * コンタクトルームのメッセージの楽観的更新を行うhooks
 */
export const useOptimisticCreateMessage = ({
  tenantId,
  contactRoomId,
}: {
  tenantId: string;
  contactRoomId: string;
}) => {
  const { tenant } = useTenant();
  const [isSending, setIsSending] = useState(false);
  const [isFileSending, setIsFileSending] = useState(false);
  const [messageId, setMessageId] = useState<string | undefined>();

  const { enqueueSnackbar } = useSnackbar();
  const { data: contactRoomAndMessages } = useContactMessages({
    tenantId,
    contactRoomId,
  });

  // APIを呼び出す
  const postContactMessageAndFile = useCallback(
    async ({ newMessageFiles, message }: { newMessageFiles?: File[]; message: ContactMessage }) => {
      // 添付ファイルがある場合は、ファイルをアップロードする
      if (newMessageFiles?.length) {
        if (message.filePaths === undefined) {
          throw new Error("message.filePaths is undefined");
        }
        if (newMessageFiles.length !== message.filePaths.length) {
          throw new Error("newMessageFiles.length !== message.filePaths.length");
        }
        await fileAPIAdapter.uploadFiles(
          newMessageFiles.map((newMessageFile, index) => {
            const messageFilePath = message.filePaths as string[];
            const filePath = messageFilePath[index] as (typeof messageFilePath)[number];
            return {
              path: filePath,
              file: newMessageFile,
            };
          })
        );
      }

      await apiClient.post("/post_contact_rooms_messages", {
        contactRoomId: message.contactRoomId,
        messageText: message.text || "",
        messageFilePaths: message.filePaths,
        stickerId: message.stickerId,
      });
    },
    []
  );

  type PostMessageArgs =
    | {
        employee: Employee;
        newMessageText: string;
        newMessageFiles: File[];
        stickerId?: never;
      }
    | {
        employee: Employee;
        newMessageText?: never;
        newMessageFiles?: never;
        stickerId: string;
      };

  // 楽観的更新を行い、メッセージを送信する
  const postMessage = useCallback(
    async ({ newMessageText, employee, newMessageFiles, stickerId }: PostMessageArgs) => {
      try {
        setIsSending(true);
        if (newMessageFiles?.length) setIsFileSending(true);
        const newMessage =
          typeof stickerId === "string"
            ? newStampContactMessage({
                tenantId,
                contactRoomId,
                employee,
                stickerId,
              })
            : newContactMessage({
                tenantId,
                contactRoomId,
                employee,
                text: newMessageText,
                newMessageFiles,
              });
        setMessageId(newMessage.id);

        // 楽観的更新で使用するメッセージ送信後のcontactRoomAndMessages
        const updatedContactRoomAndMessages = (() => {
          const newMessageWithCreator = new ContactMessageWithCreator({
            ...newMessage,
            creator: employee,
          });
          const currentMessages = contactRoomAndMessages?.messages || [];

          const _updatedContactRoomAndMessages = {
            ...contactRoomAndMessages,
            messages: [
              ...currentMessages,
              {
                ...newMessageWithCreator,
                ...newMessageWithCreator.getCreatorInfo(tenant),
              },
            ],
          };
          return _updatedContactRoomAndMessages;
        })();

        await mutate(
          generateContactMessagesKey({ tenantId, contactRoomId }),
          async () => {
            await postContactMessageAndFile({
              newMessageFiles,
              message: newMessage,
            });
            return updatedContactRoomAndMessages;
          },
          {
            optimisticData: updatedContactRoomAndMessages,
            rollbackOnError() {
              // contactMessages.isFailedDeliveryがtrueの場合に画面上に「送信失敗」を表示するために、ロールバックを行わず
              // catch先で再度mutateを呼び出す
              return false;
            },
          }
        );
      } catch (error) {
        mutate(generateContactMessagesKey({ tenantId, contactRoomId }));
        enqueueSnackbar(
          "メッセージの送信に失敗しました。Onnサポートチームまでお問い合わせください。",
          { variant: "error" }
        );
        if (error instanceof Error) {
          captureException({ error, tags: { type: "postContactMessageAndFile" } });
        }
      } finally {
        setIsSending(false);
        setIsFileSending(false);
        setMessageId(undefined);
      }
    },
    [
      contactRoomAndMessages,
      contactRoomId,
      enqueueSnackbar,
      postContactMessageAndFile,
      tenant,
      tenantId,
    ]
  );

  return { postMessage, isSending, isFileSending, messageId };
};
