import React, { FC } from "react";

export const Clock: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        d="M12.734,13.849a.7.7,0,0,0,.555.235.82.82,0,0,0,.573-.254.769.769,0,0,0,.263-.583.736.736,0,0,0-.244-.564l-2.313-2.313V7.532A.728.728,0,0,0,11.352,7,.751.751,0,0,0,10.8,6.78.759.759,0,0,0,10.252,7a.734.734,0,0,0-.226.555v3.121a.856.856,0,0,0,.066.329,1.073,1.073,0,0,0,.179.291ZM10.741,18.7a7.758,7.758,0,0,1-3.112-.63A8.009,8.009,0,0,1,3.38,13.821a8,8,0,0,1,0-6.223A8.09,8.09,0,0,1,5.091,5.06a8.223,8.223,0,0,1,2.538-1.72,7.893,7.893,0,0,1,6.223,0A8.129,8.129,0,0,1,18.111,7.6a7.893,7.893,0,0,1,0,6.223,8.223,8.223,0,0,1-1.72,2.538,8.09,8.09,0,0,1-2.538,1.711A7.758,7.758,0,0,1,10.741,18.7ZM10.741,10.709Zm-.019,6.223a6.042,6.042,0,0,0,4.409-1.814,5.971,5.971,0,0,0,1.833-4.409,5.98,5.98,0,0,0-1.833-4.4,6,6,0,0,0-4.39-1.824,6,6,0,0,0-4.4,1.824,6,6,0,0,0-1.824,4.4,5.986,5.986,0,0,0,1.824,4.409A5.989,5.989,0,0,0,10.722,16.933Z"
        transform="translate(-2.75 -2.7)"
        stroke="none"
      />
    </svg>
  );
};
