import { Box } from "@material-ui/core";
import { DEFAULT_MAX_FILE_SIZE_MB, DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES } from "@onn/common";
import React, { FC, useState, useMemo, ReactNode, useCallback } from "react";
import styled from "styled-components";

import {
  Paper,
  TextField,
  TextareaAutosizeAttachableFile,
  Typography,
  Button,
} from "~/components/uiParts";

type AttachedFile = { fileName: string; src: string };

type Props = {
  title?: string;
  text?: string;
  attachedFiles: AttachedFile[];
  onSubmit: ({
    title,
    text,
    attachedFiles,
  }: {
    title: string;
    text: string;
    attachedFiles: (File | Pick<File, "name">)[];
  }) => Promise<void>;
  onCancel?: () => void;
  onDropFilesError: (message: string) => void;
};

export const MemoForm: FC<Props> = (props) => {
  const { onSubmit } = props;
  const [title, setTitle] = useState(props.title || "");
  const [text, setText] = useState(props.text || "");
  const [attachedFiles, setAttachedFiles] = useState<(File | Pick<File, "name">)[]>(
    props.attachedFiles.map((file) => new File([file.src], file.fileName))
  );

  const submissionDisabled = useMemo(() => !title.trim() || !text.trim(), [title, text]);

  const handleSubmit = useCallback(async () => {
    await onSubmit({ title, text, attachedFiles });
    setTitle("");
    setText("");
    setAttachedFiles([]);
  }, [attachedFiles, onSubmit, text, title]);

  const handleChangeFiles = (newFiles: (File | Pick<File, "name">)[]) => {
    setAttachedFiles(newFiles);
  };

  const footerButtons = useMemo(() => {
    const buttons: ReactNode[] = [];
    if (props.onCancel) {
      buttons.push(
        <Button
          key="cancel"
          onClick={props.onCancel}
          borderRadius="regular"
          variant="outlined"
          color="default"
        >
          キャンセル
        </Button>
      );
    }
    buttons.push(
      <Button
        key="save"
        onClick={handleSubmit}
        borderRadius="regular"
        variant="contained"
        color="primary"
        disabled={submissionDisabled}
      >
        保存
      </Button>
    );

    return buttons;
  }, [handleSubmit, props.onCancel, submissionDisabled]);

  return (
    <StyledPaper>
      <StyledTextField
        autoFocus
        fullWidth
        onChange={(e) => setTitle(e.target.value)}
        value={title}
        name="title"
        placeholder="タイトルを入力してください"
      />
      <Box mt={3}>
        <TextareaAutosizeAttachableFile
          name="description"
          value={text}
          fullWidth
          placeholder="メンバーに関する情報やチームへの伝達事項を入力してください。"
          onChange={(e) => setText(e.target.value)}
          minRows={4}
          maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
          accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
          onChangeFiles={handleChangeFiles}
          attachedFiles={attachedFiles}
          footerButtons={footerButtons}
        />
        <Box height={8} />
        <Typography variant="overline" color="textSecondary">
          ※投稿したメモは管理者・バディ・サポートメンバーにのみ共有されます。
        </Typography>
      </Box>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding-top: 24px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    padding-bottom: 8px;
  }
`;
