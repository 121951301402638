import { Box, Grid } from "@material-ui/core";
import { isAfter } from "date-fns";
import React, {
  ComponentProps,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from "react";

import { MemoItemMenu } from "./MemoItemMenu";

import { CommentList, DeleteCommentConfirmModal } from "~/components/domains/comments";
import { MemoForm, MemoPreview } from "~/components/domains/transactions";
import { UserIcon, Divider, Icon, IconButton, Typography, Accordion } from "~/components/uiParts";
import { useDeleteComment } from "~/hooks/comment/useDeleteComment";
import { useScrollToTargetElement } from "~/hooks/employee";
import { useSnackbar, useClipboard } from "~/hooks/shared";

type MemoFormProps = ComponentProps<typeof MemoForm>;

type CommentListProps = ComponentProps<typeof CommentList>;

type Memo = {
  id: string;
  title: string;
  text: string;
  createdUser: {
    name: string;
    profileIconImageUrl?: string;
    deleted?: boolean;
  };
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  isScrollTarget: boolean;
  shareLink: string;
};

type AttachedFile = {
  fileName: string;
  src: string;
  path: string;
};

type Props = {
  memo: Memo;
  currentUserId: string;
  commentFocused: boolean;
  alreadyScrolledToMemo: boolean;
  attachedFiles: AttachedFile[];
  attachedFilesLoading: boolean;
  onScrolledToMemo: () => void;
  onEditMemo: (
    id: string,
    { text, title }: { text: string; title: string; attachedFiles: (File | Pick<File, "name">)[] }
  ) => void;
  onClickDeleteMemoMenu: (id: string) => void;
  commentListProps: Pick<
    CommentListProps,
    "comments" | "commentDisabled" | "suggestedMentionUsers" | "loadTransactionComments"
  >;
  onDownloadAttachedFile: (url: string) => void;
  onDeleteAttachedFile: (path: string) => void;
  isActive?: boolean;
};

export const MemoItem: FC<Props> = ({
  memo,
  currentUserId,
  commentFocused,
  alreadyScrolledToMemo,
  attachedFiles,
  attachedFilesLoading,
  onScrolledToMemo,
  onEditMemo,
  onClickDeleteMemoMenu,
  commentListProps,
  onDownloadAttachedFile,
  onDeleteAttachedFile,
  isActive = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { deleteComment } = useDeleteComment();
  const { handleCopy } = useClipboard();

  const rootRef = useRef<HTMLDivElement>(null);
  const menuButtonWrapperRef = useRef<HTMLDivElement>(null);

  // 自動スクロールの対象である場合には、デフォルトで開いた状態にする
  const [expanded, setExpanded] = useState(memo.isScrollTarget);
  const [menuOpen, setMenuOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleteTargetCommentId, setDeleteTargetCommentId] = useState("");

  const edited = useMemo(
    () => isAfter(new Date(memo.updatedAt), new Date(memo.createdAt)),
    [memo.createdAt, memo.updatedAt]
  );

  const deleteTargetComment = useMemo(
    () => commentListProps.comments.find((comment) => comment.id === deleteTargetCommentId),
    [commentListProps.comments, deleteTargetCommentId]
  );

  const handleToggleExpanded = useCallback((value: boolean) => setExpanded(value), []);

  const handleClickMenuButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // クリックイベントがバブリングして、Accordionの開閉がトリガーされるのを防ぐ
    setMenuOpen(true);
  }, []);

  const handleClickEditMemoMenu = useCallback(() => {
    setEditing(true);
    setMenuOpen(false);
  }, []);

  const handleCancelEditMemo = useCallback(() => setEditing(false), []);

  const handleClickDeleteMemoMenu = useCallback(
    () => onClickDeleteMemoMenu(memo.id),
    [memo.id, onClickDeleteMemoMenu]
  );

  const handleClickCopyShareLinkMenu = useCallback(
    (shareLink: string) => {
      handleCopy(shareLink, "リンク");
      setMenuOpen(false);
    },
    [handleCopy]
  );

  const handleEditMemo = useCallback<MemoFormProps["onSubmit"]>(
    async ({ title, text, attachedFiles }) => onEditMemo(memo.id, { title, text, attachedFiles }),
    [memo.id, onEditMemo]
  );

  const handleAcceptDeleteComment = useCallback(
    async (commentId: string) => {
      setDeleteTargetCommentId("");
      await deleteComment(commentId, { transactionId: memo.id });
      commentListProps.loadTransactionComments(memo.id);
    },
    [commentListProps, deleteComment, memo.id]
  );

  const handleDropAttachedFilesError = useCallback(
    (message: string) => enqueueSnackbar(message, { variant: "error" }),
    [enqueueSnackbar]
  );

  useScrollToTargetElement({
    enabled: !alreadyScrolledToMemo && memo.isScrollTarget,
    targetRef: rootRef,
    onScrolled: onScrolledToMemo,
  });

  return (
    <div ref={rootRef}>
      {!editing ? (
        <Accordion
          isActive={isActive}
          expanded={expanded}
          hideToggleExpandedButton={memo.deleted && !commentListProps.comments.length}
          onToggleExpanded={handleToggleExpanded}
          headerContent={
            !memo.deleted ? (
              <Box display="flex" width="100%">
                <Box display="flex" width="calc(100% - 48px)">
                  {expanded ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">{memo.title}</Typography>
                      <Box minWidth="140px" ml={5}>
                        <Typography variant="caption">{memo.createdAt} 投稿</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Grid container spacing={1} alignItems="center">
                      <Grid item sm={5}>
                        <Typography variant="body2" noWrap>
                          {memo.title}
                        </Typography>
                      </Grid>
                      <Grid container item sm={5} alignItems="center">
                        <Grid item sm={6}>
                          <Box display="flex" alignItems="center">
                            <Box mr={0.5}>
                              <UserIcon
                                username={memo.createdUser.name}
                                profileIconImageUrl={memo.createdUser.profileIconImageUrl}
                                size="small"
                                circular={!memo.createdUser.name}
                              />
                            </Box>
                            <Typography variant="caption" noWrap>
                              {memo.createdUser.name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="caption">
                            {memo.updatedAt} {edited ? "更新" : "投稿"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={2}>
                        <Typography variant="caption">
                          {commentListProps.comments.length} comments
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <Box>
                  <div ref={menuButtonWrapperRef}>
                    <IconButton icon="menuVert" onClick={handleClickMenuButton} />
                  </div>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flex="1 1 auto">
                <Grid container spacing={1} alignItems="center">
                  <Grid item sm={10}>
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <Icon icon="trash" size="md" color="grey" />
                      </Box>
                      <Typography variant="body2">このメモは削除されました。</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={2}>
                    <Typography variant="caption">
                      {commentListProps.comments.length} comments
                    </Typography>
                  </Grid>
                </Grid>
                {/* 非削除時とコメント件数表示のためのセルの位置を揃えるためのスペース */}
                <Box display="inline-block" width="48px" />
              </Box>
            )
          }
        >
          {!memo.deleted ? (
            <>
              <MemoPreview
                {...memo}
                attachedFiles={attachedFiles}
                attachedFilesLoading={attachedFilesLoading}
                onDeleteFile={onDeleteAttachedFile}
                onDownloadFile={onDownloadAttachedFile}
              />
              <Box height="16px" />
              <Box flex="1 1 auto" mt={1.5} mb={1.5}>
                <Divider />
              </Box>
            </>
          ) : null}
          <Box pt={2} pb={2}>
            <CommentList
              currentUserId={currentUserId}
              transactionId={memo.id}
              submitCommentFormFocused={commentFocused && memo.isScrollTarget}
              onClickDeleteCommentButton={(commentId) => setDeleteTargetCommentId(commentId)}
              {...commentListProps}
            />
          </Box>
        </Accordion>
      ) : (
        <MemoForm
          title={memo.title}
          text={memo.text}
          attachedFiles={attachedFiles}
          onSubmit={handleEditMemo}
          onCancel={handleCancelEditMemo}
          onDropFilesError={handleDropAttachedFilesError}
        />
      )}
      <MemoItemMenu
        open={menuOpen}
        anchorElement={menuButtonWrapperRef.current}
        onClose={() => setMenuOpen(false)}
        onClickEditMemo={handleClickEditMemoMenu}
        onClickDeleteMemo={handleClickDeleteMemoMenu}
        onClickCopyShareLink={() => handleClickCopyShareLinkMenu(memo.shareLink)}
      />
      {deleteTargetComment && (
        <DeleteCommentConfirmModal
          open={!!deleteTargetCommentId}
          comment={deleteTargetComment}
          onAccept={handleAcceptDeleteComment}
          onCancel={() => setDeleteTargetCommentId("")}
        />
      )}
    </div>
  );
};
