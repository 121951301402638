import { ContactMessageDraft } from "@onn/common";
import { useCallback } from "react";

import { ContactMessageDraftRepository } from "~/infrastructure/api/contactMessageDraftRepository";
import { SaveContactMessageDraftUseCase } from "~/service/usecases/contactMessage/SaveContactMessageDraftUseCase";

const saveContactMessageDraftUseCase = new SaveContactMessageDraftUseCase(
  new ContactMessageDraftRepository()
);

export const useSaveContactMessageDraft = (): ((
  contactMessage: ContactMessageDraft
) => Promise<void>) => {
  return useCallback(async (contactMessageDraft) => {
    await saveContactMessageDraftUseCase.execute(contactMessageDraft);
  }, []);
};
