import { Revision } from "@onn/common";

import { RevisionRepository } from "~/infrastructure/api/revisionRepository";

const revisionRepository = new RevisionRepository();

export class RevisionUseCase {
  execute(onFetchNewRevision: (revision: Revision) => void) {
    revisionRepository.listenNewRevision(onFetchNewRevision);
  }
}
