import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Library } from "@onn/common";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { LibraryTableRow } from "./LibraryTableRow";

import { Typography } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { LibraryUseCase } from "~/service/usecases/libraryUseCase";
import { captureException } from "~/util";

const libraryUseCase = new LibraryUseCase();

type Props = {
  libraries: Library[];
  syncUpdatedLibrary: ({
    updatedLibrariesIds,
    updatedLibraries,
  }: {
    updatedLibrariesIds: string[];
    updatedLibraries: Library[];
  }) => void;
};

export const LibraryTable: FC<Props> = ({ libraries, syncUpdatedLibrary }) => {
  const { tenantSettings } = useTenantSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleClickChevronButton = async ({
    library,
    isUp,
  }: {
    library: Library;
    isUp: boolean;
  }) => {
    setIsUpdating(true);

    // 上向きの時は index は下がる
    const targetIndex = isUp ? library.index - 1 : library.index + 1;

    const targetLibrary = libraries.find((v) => v.index === targetIndex);

    if (!targetLibrary) return;

    const updatedLibrariesIds: string[] = [library.id, targetLibrary.id];
    const updatedLibraries: Library[] = [
      { ...library, index: targetIndex },
      { ...targetLibrary, index: library.index },
    ];

    try {
      await Promise.all(
        updatedLibraries.map((updatedLibrary) => {
          return libraryUseCase.updateLibrary({
            libraryId: updatedLibrary.id,
            library: updatedLibrary,
          });
        })
      );
      syncUpdatedLibrary({
        updatedLibrariesIds,
        updatedLibraries,
      });
      setIsUpdating(false);
    } catch (e) {
      enqueueSnackbar("更新に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "LibraryTable:handleClickChevronButton" },
      });
    }
  };

  const handleClickDeleteButton = async (library: Library) => {
    try {
      await libraryUseCase.deleteById(library.id);
      // index を調整するために消したものより大きい index の場合は -1 する
      const updatedLibraries = libraries
        .map((v) => {
          if (v.index > library.index) {
            return { ...v, index: v.index - 1 };
          }
          return v;
        })
        .sort((a, b) => (a.index < b.index ? -1 : 1));
      syncUpdatedLibrary({
        updatedLibrariesIds: updatedLibraries.map((v) => v.id),
        updatedLibraries: updatedLibraries.filter((v) => v.id !== library.id),
      });
      enqueueSnackbar("カテゴリーを削除しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("削除に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "LibraryTable:handleClickDeleteButton" },
      });
    }
  };

  return (
    <>
      <TableContainer>
        <StyledPaper square>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell width={"8%"}>
                  <Typography variant="overline" noWrap color="textSecondary">
                    表示順
                  </Typography>
                </StyledTableCell>
                <StyledTableCell width={"40%"}>
                  <Typography variant="overline" noWrap color="textSecondary">
                    カテゴリー
                  </Typography>
                </StyledTableCell>
                <StyledTableCell width={"10%"}>
                  <Typography variant="overline" noWrap color="textSecondary">
                    コンテンツ数
                  </Typography>
                </StyledTableCell>
                <StyledTableCell width={"19%"}>
                  <Typography variant="overline" noWrap color="textSecondary">
                    最終更新
                  </Typography>
                </StyledTableCell>
                <StyledTableCell width={"15%"}>
                  <Typography variant="overline" noWrap color="textSecondary">
                    公開ステータス
                  </Typography>
                </StyledTableCell>
                <StyledTableCell width={"8%"}></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {libraries.map((library) => {
                return (
                  <LibraryTableRow
                    key={library.id}
                    library={library}
                    displayChevronUpButton={library.index !== 1}
                    displayChevronDownButton={library.index !== libraries.length}
                    disabledLibrary={!tenantSettings.features.library}
                    onClickChevronButton={handleClickChevronButton}
                    onClickDeleteButton={handleClickDeleteButton}
                    syncUpdatedLibrary={syncUpdatedLibrary}
                    isUpdating={isUpdating}
                  />
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledPaper>
      </TableContainer>
    </>
  );
};

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 0 10px;
  }
`;

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    table-layout: fixed;
  }
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
  }
`;
