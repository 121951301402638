import React, { FC } from "react";

export const ArrowDropUp: FC = () => {
  return (
    <svg
      className="MuiSvgIcon-root jss230"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      tabIndex={-1}
      data-ga-event-category="material-icons"
      data-ga-event-action="click"
      data-ga-event-label="ArrowDropUp"
    >
      <path d="M7 14l5-5 5 5z"></path>
    </svg>
  );
};
