import { Employee, MemoTransaction, convertFileToBase64 } from "@onn/common";
import { format } from "date-fns";
import { useCallback, useState, useMemo, useEffect } from "react";

import { OtherEmployee } from "./useOtherEmployees";

import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useQuery, useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { apiClient } from "~/libs";
import { memoTransactionUseCase } from "~/service/usecases/transactionUseCase";
import { captureException } from "~/util";
import { downloadFileAsync } from "~/util/fileUtil";

type AttachedFile = {
  fileName: string;
  src: string;
  path: string;
};

type AttachedFilesMap = {
  [memoId: string]: AttachedFile[];
};

type Memo = {
  id: string;
  title: string;
  text: string;
  createdUser: {
    name: string;
    profileIconImageUrl?: string;
    deleted?: boolean;
  };
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  isScrollTarget: boolean;
  shareLink: string;
};

type HandleCreateMemo = ({
  text,
  title,
  employeeId,
  attachedFiles,
}: {
  text: string;
  title: string;
  employeeId: string;
  attachedFiles: File[];
}) => void;

type HandleEditMemo = (
  memoId: string,
  {
    text,
    title,
    attachedFiles,
  }: {
    text: string;
    title: string;
    attachedFiles: File[];
  }
) => void;

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useMemoUseCase = ({
  newHireEmployee,
  otherEmployees,
  memoTransactions,
  onSuccess,
}: {
  newHireEmployee?: Employee;
  otherEmployees: OtherEmployee[];
  memoTransactions: MemoTransaction[];
  onSuccess: () => void;
}): {
  loading: boolean;
  attachedFilesLoading: boolean;
  memos: Memo[];
  attachedFilesMap: AttachedFilesMap;
  handleCreateMemo: HandleCreateMemo;
  handleEditMemo: HandleEditMemo;
  handleDeleteMemo: (memoId: string) => void;
  handleDownloadAttachedFile: (url: string) => void;
  handleDeleteAttachedFile: (path: string) => void;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { query } = useQuery();

  const [loading, setLoading] = useState(false);
  const [attachedFilesLoading, setAttachedFilesLoading] = useState(false);
  const [attachedFilesMap, setAttachedFilesMap] = useState<AttachedFilesMap>({});

  const memoId = query.get("memoId");

  const memos = useMemo<Memo[]>(
    () =>
      memoTransactions
        // DBで取得したtransactionsはソートされていないので、ここで作成日降順になるようにソートする
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .map<Memo>(({ id, contents, createdAt, updatedAt, deleted }) => ({
          id,
          title: contents.title,
          text: contents.text,
          createdUser: otherEmployees.find(
            (employee) => employee.id === contents.createdEmployeeId
          ) ?? { name: "", deleted: false },
          createdAt: format(createdAt, TIMESTAMP_FORMAT),
          updatedAt: format(updatedAt, TIMESTAMP_FORMAT),
          isScrollTarget: id === memoId,
          shareLink: `${window.location.origin}/employee/${newHireEmployee?.id}?memoId=${id}`,
          deleted: deleted || false,
        })),
    [memoTransactions, newHireEmployee, otherEmployees, memoId]
  );

  const handleDeleteMemo = useCallback(
    async (memoId: string) => {
      setLoading(true);
      try {
        await memoTransactionUseCase.delete(memoId);
        await onSuccess();
        enqueueSnackbar("メモを削除しました", { variant: "success" });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("メモの削除に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useMemoUseCase:handleDeleteMemo" },
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, onSuccess]
  );

  const handleCreateMemo = useCallback<HandleCreateMemo>(
    async ({ text, title, attachedFiles }) => {
      if (!currentUser || !newHireEmployee) {
        return;
      }
      setLoading(true);
      try {
        const encodedFiles = await Promise.all(
          attachedFiles.map(async (file) => ({
            filename: file.name,
            base64: await convertFileToBase64(file),
          }))
        );

        await apiClient.post(`/memoapi`, {
          text,
          title,
          employeeId: newHireEmployee.id,
          attachedFiles: encodedFiles,
        });

        onSuccess();
        enqueueSnackbar("メモを投稿しました", { variant: "success" });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("メモの投稿に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useMemoUseCase:handleCreateMemo" },
        });
      } finally {
        setLoading(false);
      }
    },
    [newHireEmployee, currentUser, enqueueSnackbar, onSuccess]
  );

  const handleDownloadAttachedFile = useCallback(
    async (url: string) => {
      const attachedFile = Object.values(attachedFilesMap)
        .flat()
        .find((file) => file.src === url);

      if (!attachedFile?.fileName) {
        return;
      }

      try {
        await downloadFileAsync({
          url,
          fileName: attachedFile.fileName,
        });
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar("ファイルのダウンロードに失敗しました", { variant: "error" });
          captureException({ error, tags: { type: "downloadFileAsync" } });
        }
      }
    },
    [attachedFilesMap, enqueueSnackbar]
  );

  const initialize = useCallback(async () => {
    if (!newHireEmployee) {
      return;
    }

    setAttachedFilesLoading(true);

    const attachedFilesMap = (
      await Promise.all(
        memos.map(async (memo) => {
          try {
            const attachedFiles = await fileAPIAdapter.listMetadata({
              path: `tenants/${newHireEmployee.tenantId}/employees/${newHireEmployee.id}/memos/${memo.id}`,
            });

            return {
              memoId: memo.id,
              attachedFiles: attachedFiles.map(({ fileName, url, path }) => ({
                fileName,
                src: url,
                path,
              })),
            };
          } catch (error) {
            if (error instanceof Error) {
              // 仮に取得に失敗した場合には、該当メモの添付ファイルについてはスルーするが、こちら側で気付けるようにSentryに吐いておく
              captureException({ error, tags: { type: "fileAPIAdapter.listMetadata" } });
            }
            return;
          }
        })
      )
    ).reduce(
      (acc, value) => ({ ...acc, ...(value ? { [value.memoId]: value.attachedFiles } : {}) }),
      {} as AttachedFilesMap
    );

    setAttachedFilesMap(attachedFilesMap);
    setAttachedFilesLoading(false);
  }, [memos, newHireEmployee]);

  const handleEditMemo = useCallback<HandleEditMemo>(
    async (memoId: string, { title, text, attachedFiles }) => {
      if (!newHireEmployee) return;

      setLoading(true);

      /** 既に添付済みのファイル */
      const existingFiles = attachedFilesMap[memoId] ?? [];

      /** 削除対象のファイル （= 既に添付済みのファイルのうち、編集後のファイルに含まれないファイル） */
      const deleteTargetFiles = existingFiles.filter(
        (existingFile) =>
          !attachedFiles.find((attachedFile) => attachedFile.name === existingFile.fileName)
      );

      /** アップロード対象のファイル （= 編集後のファイルのうち、既に添付済みのファイルに含まれないファイル） */
      const uploadTargetFiles = attachedFiles.filter(
        (attachedFile) =>
          !existingFiles.find((existingFile) => existingFile.fileName === attachedFile.name)
      );

      try {
        await memoTransactionUseCase.edit(memoId, { title, text });

        await Promise.all([
          ...deleteTargetFiles.map(async (file) => {
            await fileAPIAdapter.delete({
              path: `tenants/${newHireEmployee.tenantId}/employees/${newHireEmployee.id}/memos/${memoId}/${file.fileName}`,
            });
          }),
          ...uploadTargetFiles.map(async (file) => {
            await fileAPIAdapter.upload({
              path: `tenants/${newHireEmployee.tenantId}/employees/${newHireEmployee.id}/memos/${memoId}/${file.name}`,
              file,
            });
          }),
        ]);

        await onSuccess();
        enqueueSnackbar("メモを編集しました", { variant: "success" });
      } catch (e) {
        console.error(e);
        enqueueSnackbar("メモの編集に失敗しました", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, onSuccess, newHireEmployee, attachedFilesMap]
  );

  const handleDeleteAttachedFile = useCallback(
    async (path: string) => {
      try {
        await fileAPIAdapter.delete({ path });
        await initialize();
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar("ファイルの削除に失敗しました", { variant: "error" });
          captureException({ error, tags: { type: "fileAPIAdapter.delete" } });
        }
      }
    },
    [enqueueSnackbar, initialize]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return {
    loading,
    attachedFilesLoading,
    memos,
    attachedFilesMap,
    handleCreateMemo,
    handleEditMemo,
    handleDeleteMemo,
    handleDownloadAttachedFile,
    handleDeleteAttachedFile,
  };
};

const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";
