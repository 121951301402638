import React, { FC } from "react";

export const EmojiSmile: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 26 26">
      <g transform="translate(-362 -342)">
        <g transform="translate(362 342)">
          <path
            className="a"
            d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,22.286A10.286,10.286,0,1,1,22.286,12,10.286,10.286,0,0,1,12,22.286Z"
            stroke="none"
          />
          <g transform="translate(6 8.571)" stroke="none">
            <circle className="a" cx="1.714" cy="1.714" r="1.714" />
          </g>
          <g transform="translate(14.571 8.571)" stroke="none">
            <circle className="a" cx="1.714" cy="1.714" r="1.714" />
          </g>
          <g transform="translate(8.547 14.552)">
            <path
              className="a"
              d="M176.924,290.094a.857.857,0,0,0-1.2-.174l-.048.038h0a3.45,3.45,0,0,1-4.07,0,.857.857,0,1,0-1.121,1.3l.048.039a5.118,5.118,0,0,0,6.216,0A.857.857,0,0,0,176.924,290.094Z"
              transform="translate(-170.189 -289.75)"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
