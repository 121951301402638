import { RefObject } from "react";

/**
 * メッセージ入力textareaのカーソル位置にテンプレートを挿入する
 * カーソル位置が存在しない場合は、末尾にテンプレートを挿入する
 */
export const generateMessageWithTemplateInserted = ({
  currentMessage,
  templateMessage,
  textareaAutosizeAttachableFileRef,
}: {
  currentMessage: string;
  templateMessage: string;
  textareaAutosizeAttachableFileRef: RefObject<HTMLTextAreaElement>;
}) => {
  const target = textareaAutosizeAttachableFileRef.current;
  if (target) {
    // カーソルの前の文字列
    const firstHalfCurrentMessage = currentMessage.substring(0, target.selectionStart);
    // カーソルの後の文字列
    const secondHalfCurrentMessage = currentMessage.substring(target.selectionStart);
    return firstHalfCurrentMessage + templateMessage + secondHalfCurrentMessage;
  } else {
    return currentMessage + templateMessage;
  }
};
