import { MenuItem } from "@material-ui/core";

import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

type Props = {
  label: string;
  onClick: () => void;
};

export const TemplateChangeMenuItem: FC<Props> = ({ label, onClick }) => {
  return (
    <StyledMenuItem onClick={onClick}>
      <Typography variant="body2">{label}</Typography>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 8px 24px;
  }
`;
