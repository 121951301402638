import { Employee } from "@onn/common";
import { useState } from "react";

export const useFirstAndLastNameInput = (employee?: Employee) => {
  const defaultFirstName = employee?.firstName ?? "";
  const defaultLastName = defaultFirstName && (employee?.lastName ?? "");
  const [firstName, setFirstName] = useState<string>(defaultFirstName);
  const [lastName, setLastName] = useState<string>(defaultLastName);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);

  const onChangeFirstName = (value: string) => {
    setFirstName(value);
    if (value.length > 0) setFirstNameError(false);
    else setFirstNameError(true);
  };

  const onChangeLastName = (value: string) => {
    setLastName(value);
    if (value.length > 0) setLastNameError(false);
    else setLastNameError(true);
  };

  return {
    onChangeFirstName,
    onChangeLastName,
    firstName,
    lastName,
    firstNameError,
    lastNameError,
  };
};
