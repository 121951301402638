import { Box } from "@material-ui/core";
import { Library, EMPLOYEE_ACTION_TYPE } from "@onn/common";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Loading, Divider, Icon, Typography, PortalListItem } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useLocalStorage, useQuery } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { OgpAdapter } from "~/infrastructure/usecases/ogp/ogpAdapter";
import { NotFound } from "~/pages/NotFound";
import { EmployeeActiveLogUseCase } from "~/service/usecases/employeeActiveLogUseCase";
import { LibraryUseCase } from "~/service/usecases/libraryUseCase";

const libraryUseCase = new LibraryUseCase();
const employeeActiveLogUseCase = new EmployeeActiveLogUseCase();

const LIBRARY_FOR_PREVIEW = "libraryForPreview";

export const LibraryDetail: FC = () => {
  const { currentUser } = useCurrentUser();
  const { tenantSettings } = useTenantSettings();
  const { retrieveValue } = useLocalStorage();

  const [loading, setLoading] = useState(true);
  const [library, setLibrary] = useState<Library | null>();
  // 編集時には id が存在しない場合があるので index を key にとる
  const [contentIndexToOgp, setContentIndexToOgp] = useState<{
    [key: string]: { [key: string]: string };
  }>();
  const [readContents, setReadContents] = useState<string[]>([]);
  const [domRectsMap, setDomRectsMap] = useState<{
    [key: number]: DOMRect;
  }>({});

  const { libraryId } = useParams<"libraryId">();
  const { query } = useQuery();

  const isEditing = query.get("editing") === "true";
  const isPreview = query.get("preview") === "true";

  const fetchLibrary = useCallback(async (libraryId: string) => {
    setLoading(true);
    await libraryUseCase
      .findById(libraryId)
      .then((res) => setLibrary(res))
      .finally(() => setLoading(false));
  }, []);

  const handleOpenContent = useCallback((url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  }, []);

  const createActiveLog = useCallback(
    async (contentId: string) => {
      // すでに読んでいるときとプレビューモードのときは何もしない
      if (readContents.includes(contentId) || isPreview) {
        return;
      }

      // DB の更新を待たずに state を更新してしまう
      setReadContents((prevState) => {
        return [...prevState, contentId];
      });

      return employeeActiveLogUseCase.create({
        employeeId: currentUser.id,
        type: EMPLOYEE_ACTION_TYPE.VISITED_LIBRARY_CONTENT,
        tenantId: currentUser.tenantId,
        targetId: contentId,
      });
    },
    [currentUser.id, currentUser.tenantId, isPreview, readContents]
  );

  const fetchOgp = useCallback(async () => {
    if (!library) return;
    const contentIdToOgp: { [key: number]: { [key: string]: string } } = {};
    await Promise.allSettled(
      library.contents.map(async (content) => {
        const ogp = await new OgpAdapter().getOgpByUrl({ url: content.url });
        return (contentIdToOgp[content.index] = ogp);
      })
    );
    setContentIndexToOgp(contentIdToOgp);
  }, [library]);

  // ogp を取得する
  useEffect(() => {
    if (library) {
      fetchOgp();
    }
  }, [fetchOgp, library]);

  // activeLog を取得する
  useEffect(() => {
    const fetchActiveLogs = async () => {
      const activeLogs = await employeeActiveLogUseCase.findByEmployeeId(
        currentUser.id,
        currentUser.tenantId
      );
      setReadContents(activeLogs.flatMap((activeLog) => activeLog.targetId ?? [])); // map + filter
    };
    fetchActiveLogs();
  }, [currentUser.id, currentUser.tenantId]);

  useEffect(() => {
    // ライブラリ編集中の場合は db から fetch せず localStorage に保存したものを参照する
    if (isEditing) {
      setLibrary(retrieveValue<Library>(LIBRARY_FOR_PREVIEW));
      return setLoading(false);
    }

    if (libraryId) fetchLibrary(libraryId);
  }, [fetchLibrary, isEditing, libraryId, retrieveValue]);

  const calculateTop = useCallback(
    (index: number) => {
      return (
        (domRectsMap[index] as (typeof domRectsMap)[number])?.top -
        (domRectsMap[0] as (typeof domRectsMap)[number])?.top +
        (domRectsMap[index] as (typeof domRectsMap)[number])?.height / 2 -
        20
      );
    },
    [domRectsMap]
  );

  const measureRef = useCallback(
    (node: HTMLDivElement | null, index: number) => {
      if (node) {
        const domRect = node.getBoundingClientRect();
        if (
          // y座標か要素の高さが変わっていた時に更新する
          domRectsMap[index]?.top !== domRect.top ||
          domRectsMap[index]?.height !== domRect.height
        ) {
          setDomRectsMap((prevState) => {
            return { ...prevState, [index]: domRect };
          });
        }
      }
    },
    [domRectsMap]
  );

  if (loading) {
    return <Loading size="large" />;
  }

  // ライブラリ機能オン and 公開中のとき閲覧可能だが、プレビューのときは常に閲覧可能
  const visitableLibrary =
    isPreview || (library && library.isPublic && tenantSettings.features.library);
  if (!library || !visitableLibrary) {
    return <NotFound />;
  }

  return (
    <Box px={3} py={5}>
      <Typography variant="h2" color="textPrimary">
        {library.label}
        {library.title}
      </Typography>
      <Box height="40px" />
      <Typography variant="body1" color="textPrimary">
        {/* TODO: 中途の方を文言変更(https://app.clickup.com/t/864emfdcf) */}
        会社の理解を深めていきましょう！
      </Typography>
      <Divider margin={16} orientation="horizontal" />
      <Box display="flex">
        <Box width="20px" mr="16px" position="relative">
          {library.contents.map((content, index) => {
            const targetTop = calculateTop(index);
            const nextTop = calculateTop(index + 1);

            return (
              <StyledBox
                textAlign="center"
                position="absolute"
                key={content.id}
                $borderHeight={nextTop - targetTop - 40}
                $Top={targetTop}
              >
                <Typography
                  color="textPrimary"
                  variant="caption"
                >{`${content.index}/${library.contents.length}`}</Typography>
                {readContents.includes(content.id) ? (
                  <StyledBoxForCheckCircle height="20px" width="20px">
                    <Icon color="primary" icon="checkCircle" size="sm" />
                  </StyledBoxForCheckCircle>
                ) : (
                  <Box>
                    <StyledCircle />
                  </Box>
                )}
              </StyledBox>
            );
          })}
        </Box>
        <Box width="100%">
          {library.contents.map((content, index) => {
            return (
              <Box key={content.id} mb="40px">
                <div ref={(node) => measureRef(node, index)}>
                  <Box width="100%">
                    <PortalListItem
                      title={content.title}
                      imageUrl={contentIndexToOgp?.[content.index]?.["og:image"]}
                      onClick={() => {
                        handleOpenContent(content.url);
                        createActiveLog(content.id);
                      }}
                      border={readContents.includes(content.id)}
                    />
                  </Box>
                </div>
                {content.description && (
                  <>
                    <Box height="10px" />
                    <Typography variant="caption" color="textSecondary">
                      {content.description}
                    </Typography>
                  </>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledBoxForCheckCircle = styled(Box)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledBox = styled(Box)<{ $borderHeight: number; $Top: number }>`
  ::after {
    width: 3px;
    left: 8px;
    position: absolute;
    height: ${(props) => props.$borderHeight}px;
    content: "" "";
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  top: ${(props) => props.$Top}px;
`;
