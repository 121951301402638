import { Box, RadioGroup } from "@material-ui/core";
import React, { useCallback, useMemo, Fragment, FC, ReactNode } from "react";

import styled from "styled-components";

import { FormControlLabel } from "~/components/uiParts/FormControlLabel";
import { RadioButton } from "~/components/uiParts/RadioButton";
import { TextContext } from "~/components/uiParts/TextContent";
import { TextareaAutosize } from "~/components/uiParts/TextareaAutosize";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  value: number | null;
  freeAnswerText?: string;
  name: string;
  onChangeFreeAnswerText: (value: string) => void;
  isReadOnly?: boolean;
  onChange: (value: number) => void;
  options: { index: number; option: string | ReactNode; isOther: boolean; disabled?: boolean }[];
  defaultColor?: "primary" | "default";
};

export const RadioButtonGroup: FC<Props> = ({
  value,
  freeAnswerText,
  name,
  onChangeFreeAnswerText,
  isReadOnly = false,
  onChange,
  options,
  ...props
}) => {
  const freeAnswerSection = useMemo(() => {
    if (isReadOnly) {
      return (
        <TextContext padding={2} isShowBg>
          {freeAnswerText || "回答なし"}
        </TextContext>
      );
    }
    return (
      <TextareaAutosize
        name="free-answer-text"
        autoFocus
        fullWidth
        placeholder="回答を入力"
        value={freeAnswerText || ""}
        minRows={4}
        onChange={(e) => onChangeFreeAnswerText(e.target.value)}
      />
    );
  }, [freeAnswerText, isReadOnly, onChangeFreeAnswerText]);

  const handleChangeRadioGroup = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isReadOnly) {
        onChange(parseInt(e.target.value));
      }
    },
    [isReadOnly, onChange]
  );

  return (
    <RadioGroup value={value} onChange={handleChangeRadioGroup} {...props}>
      {options.map((v) => {
        return (
          <Fragment key={v.index}>
            <StyledFormControlLabel
              disabled={v.disabled}
              control={
                <RadioButton
                  value={v.index}
                  color="primary"
                  isReadOnly={isReadOnly}
                  disableRipple={isReadOnly}
                  name={name}
                  defaultColor={value === v.index ? "primary" : "default"}
                  disabled={v.disabled}
                />
              }
              label={
                typeof v.option === "string" ? (
                  <Typography variant="body1" color="textPrimary">
                    {v.option}
                  </Typography>
                ) : (
                  v.option
                )
              }
              disableEffect={isReadOnly}
            />
            {v.isOther && <Box pl="20px">{freeAnswerSection}</Box>}
          </Fragment>
        );
      })}
    </RadioGroup>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label.Mui-disabled > .MuiTypography-root {
    color: ${(props) => props.theme.palette.grey[200]};
  }
  .MuiFormControlLabel-label.Mui-disabled > .MuiButtonBase-root {
    color: ${(props) => props.theme.palette.grey[100]};
  }
`;
