import { v4 } from "uuid";

export class ReadLog {
  readonly id: string;
  readonly employeeId: string;
  readAt: Date;
  constructor(init: Pick<ReadLog, "id" | "employeeId" | "readAt">) {
    this.id = init.id;
    this.employeeId = init.employeeId;
    this.readAt = init.readAt;
  }

  update() {
    this.readAt = new Date();
    return this;
  }

  public static createByEmployeeId(employeeId: string) {
    return new ReadLog({
      id: v4(),
      employeeId,
      readAt: new Date(),
    });
  }

  static plainToInstance(init: ConstructorParameters<typeof ReadLog>[0]): ReadLog {
    return new ReadLog(init);
  }
}
