import { Box } from "@material-ui/core";
import styled from "styled-components";

export const TableRowWrapper = styled(Box)`
  :not(:last-child)&::after {
    content: "";
    background-color: ${(props) => props.theme.palette.grey[100]};
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 0%;
    height: 1px;
  }
`;
