import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  disabled?: boolean;
};

export const Unchecked: FC<Props> = ({ disabled = false }) => (
  <svg id="checkbox" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <StyledGroup
      id="長方形_170Unchecked2"
      data-name="長方形 1702"
      fill="none"
      strokeWidth="1"
      $disabled={disabled}
    >
      <rect width="18" height="18" rx="4" stroke="none" />
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="none" />
    </StyledGroup>
  </svg>
);

const StyledGroup = styled.g<{ $disabled: boolean }>`
  stroke: ${(props) =>
    props.$disabled ? props.theme.palette.grey[100] : props.theme.palette.grey[200]};
`;
