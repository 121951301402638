import { ContactMessageDraft } from "@onn/common";

import { IContactMessageDraftRepository } from "~/service/repository/iContactMessageDraftRepository";

export class GetContactMessageDraftsUseCase {
  constructor(private readonly contactMessageRepository: IContactMessageDraftRepository) {}

  async execute(
    params: Pick<ContactMessageDraft, "createdUserId" | "tenantId">
  ): Promise<ContactMessageDraft[]> {
    return this.contactMessageRepository.fetchByCreatedUserId(params);
  }
}
