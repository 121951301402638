import { useState, useCallback } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { CommentUseCase } from "~/service/usecases/commentUseCase";

const commentUseCase = new CommentUseCase();

/**
 * コメントを削除する
 * @returns isLoading データを削除中かどうか
 * @returns updateComment コメントを削除する関数
 */
export const useDeleteComment = (): {
  isLoading: boolean;
  deleteComment: (
    commentId: string,
    {
      transactionId,
    }: {
      transactionId: string;
    }
  ) => Promise<void>;
} => {
  const { currentUser } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);

  const deleteComment = useCallback(
    async (commentId: string, { transactionId }: { transactionId: string }) => {
      setIsLoading(true);
      await commentUseCase.delete({
        commentId,
        transactionId,
        employeeId: currentUser.id,
      });
      setIsLoading(false);
    },
    [currentUser.id]
  );

  return {
    isLoading,
    deleteComment,
  };
};
