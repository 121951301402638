import React, { FC } from "react";

export const LineChat: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      stroke="none"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="24" height="24" rx="12" transform="translate(0 0)" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(0 0.119)">
        <g transform="translate(0 -0.119)" clipPath="url(#clip-path)">
          <path
            d="M18.546,23.944H5.4a5.4,5.4,0,0,1-5.4-5.4V5.4A5.4,5.4,0,0,1,5.4,0H18.546a5.4,5.4,0,0,1,5.4,5.4V18.546a5.4,5.4,0,0,1-5.4,5.4"
            transform="translate(0 0.056)"
            fill="#06c755"
          />
          <path
            d="M69.245,64.823c0-3.572-3.581-6.478-7.983-6.478s-7.983,2.906-7.983,6.478c0,3.2,2.84,5.884,6.676,6.391.26.056.614.171.7.394a1.64,1.64,0,0,1,.026.722s-.094.563-.114.683c-.035.2-.16.789.691.43a25.775,25.775,0,0,0,6.272-4.635h0a5.764,5.764,0,0,0,1.711-3.986"
            transform="translate(-49.292 -53.923)"
            fill="#fff"
          />
          <path
            d="M201.547,125.645H199.3a.152.152,0,0,1-.152-.152v0h0v-3.478h0v0a.152.152,0,0,1,.152-.152h2.243a.153.153,0,0,1,.152.152v.566a.152.152,0,0,1-.152.152h-1.524v.588h1.524a.153.153,0,0,1,.152.152v.566a.152.152,0,0,1-.152.153h-1.524v.588h1.524a.153.153,0,0,1,.152.152v.566a.152.152,0,0,1-.152.152"
            transform="translate(-184.251 -112.682)"
            fill="#06c755"
          />
          <path
            d="M90.673,125.645a.152.152,0,0,0,.152-.152v-.566a.153.153,0,0,0-.152-.152H89.149v-2.765a.153.153,0,0,0-.152-.152H88.43a.152.152,0,0,0-.152.152v3.481h0v0a.152.152,0,0,0,.152.152h2.243Z"
            transform="translate(-81.673 -112.682)"
            fill="#06c755"
          />
          <path
            d="M129.449,121.855h-.566a.152.152,0,0,0-.152.152v3.484a.152.152,0,0,0,.152.152h.566a.152.152,0,0,0,.152-.152v-3.484a.152.152,0,0,0-.152-.152"
            transform="translate(-119.098 -112.681)"
            fill="#06c755"
          />
          <path
            d="M150.8,121.855h-.566a.152.152,0,0,0-.152.152v2.069l-1.594-2.153-.012-.016h0l-.009-.009,0,0-.008-.007,0,0-.008-.006,0,0-.008,0,0,0-.009,0-.005,0-.009,0H148.4l-.009,0H147.8a.152.152,0,0,0-.152.152v3.484a.152.152,0,0,0,.152.152h.566a.152.152,0,0,0,.152-.152v-2.069l1.6,2.155a.154.154,0,0,0,.039.038h0l.01.006,0,0,.007,0,.007,0,0,0,.011,0h0a.155.155,0,0,0,.04.005h.563a.152.152,0,0,0,.152-.152v-3.484a.152.152,0,0,0-.152-.152"
            transform="translate(-136.598 -112.681)"
            fill="#06c755"
          />
        </g>
      </g>
    </svg>
  );
};
