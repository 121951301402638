import { Box } from "@material-ui/core";
import React, { ChangeEvent, Fragment, FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type Props = {
  name?: string;
  value?: number;
  captionLeft: string;
  captionRight: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isReadOnly?: boolean;
};

export const NPSForm: FC<Props> = ({
  name,
  value,
  captionLeft,
  captionRight,
  onChange,
  isReadOnly,
}) => {
  const buttonLength = 11;
  const captionMaxWidth = `${40 * buttonLength + 8 * (buttonLength - 1)}px`; // caption部分の最大横幅を計算

  return (
    <StyledFieldset>
      <Box display="flex">
        {[...Array(buttonLength)].map((_, index) => (
          <Fragment key={`nps-form-${index}`}>
            <StyledInput
              id={`nps-form-${index}`}
              type="radio"
              value={index}
              readOnly={isReadOnly}
              checked={index === value}
              name={name}
              onChange={isReadOnly ? undefined : onChange}
            />
            <StyledLabel htmlFor={`nps-form-${index}`} $isReadOnly={Boolean(isReadOnly)}>
              {index}
            </StyledLabel>
          </Fragment>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1} maxWidth={captionMaxWidth}>
        <StyledTypography variant="caption" bold>
          {captionLeft}
        </StyledTypography>
        <StyledTypography variant="caption" bold>
          {captionRight}
        </StyledTypography>
      </Box>
    </StyledFieldset>
  );
};

const StyledFieldset = styled.fieldset`
  display: block;
`;

const StyledInput = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;

  &:checked + label {
    background: ${(props) => props.theme.palette.primary.main};
    color: white;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledLabel = styled.label<{ $isReadOnly: boolean }>`
  width: 100%;
  max-width: 40px;
  height: 40px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  color: ${(props) => props.theme.palette.grey[500]};
  border: 1px solid ${(props) => props.theme.palette.grey[100]};
  background-color: ${(props) => props.theme.palette.grey[50]};

  ${(props) => props.$isReadOnly && `cursor: default;`}

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;
