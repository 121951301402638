import { useCallback, useState } from "react";

import { useSnackbar } from "../../shared";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * idをもとにサポートメンバーを解除する関数を提供するhooks
 */
export const useDismissSupportMember = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * idをもとにサポートメンバーを解除する
   * @param {string} newHireId 入社者のid
   * @param {string} supportMemberId サポートメンバーのid
   */
  const dismissSupportMember = useCallback(
    async (newHireId: string, supportMemberId: string) => {
      setIsLoading(true);
      await EmployeeUseCase.dismissSupportMember(newHireId, supportMemberId)
        .then(() => {
          enqueueSnackbar("サポートメンバーを解除しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar("サポートメンバーへの解除に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useDismissSupportMember:dismissSupportMember" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    dismissSupportMember,
  };
};
