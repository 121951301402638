import { CircularProgress } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import theme from "~/config/theme";

const SIZE = {
  small: 20,
  large: 40,
} as const;

const COLOR = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  grey: theme.palette.grey[300],
} as const;

type Props = {
  size: keyof typeof SIZE;
  color?: keyof typeof COLOR;
  fullHeight?: boolean;
};

export const Loading: FC<Props> = ({ size, fullHeight, color = "primary" }) => {
  return (
    <StyledDiv fullHeight={fullHeight} $color={color}>
      <StyledCircularProgress size={SIZE[size]} />
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{ $color: keyof typeof COLOR; fullHeight?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.fullHeight ? `${window.innerHeight}px` : `inherit`)};

  & .MuiCircularProgress-root {
    color: ${(props) => COLOR[props.$color]} !important;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: 100%;
`;
