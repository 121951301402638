import { setTags } from "@sentry/react";
import React, { ReactChild, FC } from "react";

import { useCurrentUser } from "~/hooks/employee/useCurrentUser";

type Props = {
  children: ReactChild;
};

export const GiveLoggerTagsProvider: FC<Props> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  if (currentUser) {
    setTags({ tenantId: currentUser.tenantId });
    setTags({ username: currentUser.getName() });
    setTags({ employeeId: currentUser.id });
  }

  return <>{children}</>;
};
