import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * URLSearchParams インターフェイスを提供するhooks
 * @example http://localhost:8080?employeeId=newHire1 で query.get("employeeId") を実行すると "newHire1" を取得できる
 */
export const useQuery = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  return { query };
};
