import { Chip } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";

type Props = {
  label: string;
  onDelete: () => void;
};

export const AttributeTag: FC<Props> = ({ label, onDelete }) => {
  return (
    <StyledChip
      label={label}
      onDelete={onDelete}
      deleteIcon={<Icon icon="close" size="md" color="darkGrey" />}
    />
  );
};

const StyledChip = styled(Chip)`
  &.MuiChip-root.MuiChip-deletable {
    border-radius: 5px;
    color: ${(props) => props.theme.palette.grey[500]};
    border: solid 1px ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.light};
  }
  svg {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 50%;
    }
  }
`;
