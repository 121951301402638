import { Box, Divider, Grid, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Employee, AnswerItem, ItemType, Question, SurveyType, Survey } from "@onn/common";
import React, { FC, useCallback, useState, useEffect, Fragment, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AccessibleEmployeeList } from "~/components/domains/employees";
import { SurveyForm, AnswerFooter } from "~/components/domains/transactions";
import { Button, Typography, Modal } from "~/components/uiParts";
import theme from "~/config/theme";

const useStyles = makeStyles(() =>
  createStyles({
    pcbg: {
      backgroundColor: theme.palette.grey[50],
    },
    spbg: {
      backgroundColor: theme.palette.primary.contrastText,
    },
    card: {
      boxShadow: theme.shadows[10],
      borderRadius: theme.spacing(1),
    },
  })
);

const isAnswered = (answerItems: AnswerItem[], currentSectionQuestions: Question[]): boolean => {
  const requiredQuestions = currentSectionQuestions.filter((q) => q.required);
  for (const bigQuestion of currentSectionQuestions) {
    for (const smallQuestion of bigQuestion.questions) {
      if (smallQuestion.required) {
        requiredQuestions.push(smallQuestion);
      }
    }
  }

  const unansweredQuestion = requiredQuestions.find((rq) => {
    const answerItem = answerItems.find((ai) => ai.key === rq.name);
    const found = answerItem ? true : false;
    return found ? false : answerItem && answerItem.value ? false : true;
  });

  const answeredHrOnlyQuestions = currentSectionQuestions.filter((q) => {
    if (!q.hrOnly) return;
    const answerItem = answerItems.find((ai) => ai.key === q.name);
    return (
      answerItem && (Array.isArray(answerItem.value) ? answerItem.value.length : answerItem.value)
    );
  });
  const isUnansweredHrOnlyComment =
    answeredHrOnlyQuestions.length > 0 &&
    answeredHrOnlyQuestions.every((q) => q.type !== ItemType.SERIAL_NUMBER_TEXT);

  return !unansweredQuestion && !isUnansweredHrOnlyComment;
};

interface Props {
  survey: Survey;
  answerItems: AnswerItem[];
  mentor?: Employee;
  admins: Employee[];
  supportMembers: Employee[];
  onSubmit: (answerItems: AnswerItem[]) => Promise<void>;
}

export const AnswerMain: FC<Props> = ({
  survey,
  answerItems,
  mentor,
  admins,
  supportMembers,
  onSubmit,
}) => {
  const { t } = useTranslation(["survey"]);

  const classes = useStyles();

  const [newAnswerItems, setNewAnswerItems] = useState<AnswerItem[]>(answerItems);

  const changeAnswer = useCallback(
    async (
      event: React.ChangeEvent<{ name: string; type: string; value: string }>,
      answerItems: AnswerItem[],
      question: Question
    ): Promise<void> => {
      const target = event.target;
      const targetAnswerItem = answerItems.find((v) => v.key === target.name);
      const isCheckbox = question.type === ItemType.CHECK_BOX;
      const isTextCheckbox = isCheckbox && target.type === "textarea";
      let removeIndex: number;
      const questionItemName =
        question.questionItems.length > 0 &&
        (question.questionItems.slice(-1)[0] as (typeof question.questionItems)[number]).name;

      if (!targetAnswerItem) {
        setNewAnswerItems((prev) => {
          return [
            ...prev,
            {
              key: target.name,
              value: isCheckbox ? [target.value] : target.value,
            },
          ];
        });
        return;
      }

      const isDup = targetAnswerItem.value.indexOf(target.value) >= 0;

      if (isCheckbox && isDup && !isTextCheckbox) {
        removeIndex = targetAnswerItem.value.indexOf(target.value);
        (targetAnswerItem.value as string[]).splice(removeIndex, 1);
      }

      if (isCheckbox && !isDup && !isTextCheckbox) {
        (targetAnswerItem.value as string[]).push(target.value);
      }

      if (!isCheckbox) {
        targetAnswerItem.value = target.value;
      }

      if (isTextCheckbox) {
        const isDupTextCheckbox = targetAnswerItem.value.indexOf(questionItemName.toString()) >= 0;
        !isDupTextCheckbox &&
          (targetAnswerItem.value as string[]).push(questionItemName.toString());
        targetAnswerItem.text = target.value;
      }

      setNewAnswerItems(
        answerItems.flatMap((v) => {
          if (v.key === target.name) {
            return targetAnswerItem.value.length < 1 ? [] : targetAnswerItem;
          }
          return v;
        })
      );
    },
    []
  );

  const questionCount = survey.questions
    .map((v) => (v.questions.length > 0 ? v.questions.length : 1))
    .reduce((prev, current) => {
      return prev + current;
    });

  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState(1);
  const currentSectionRef = useRef(currentSection);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openViewableModal, setOpenViewableModal] = useState(false);

  const currentSectionQuestions = survey.questions.filter(
    (q) => (q.section ? q.section : 1) === currentSection
  );
  const isAnsweredRequiredQuestions = isAnswered(newAnswerItems, currentSectionQuestions);
  const isPc = useMediaQuery(theme.breakpoints.up("sm"));
  const basePadding = isPc ? 10 : 0;

  const isJoinedSurvey = survey.type === SurveyType.JOINED;

  const changeCurrentSection = (section: number) => {
    currentSectionRef.current = section;
    setCurrentSection(section);
  };

  const onBackButtonEvent = useCallback(
    (e: PopStateEvent) => {
      e.preventDefault();
      if (currentSectionRef.current > 1) {
        window.history.pushState(null, "", window.location.pathname);
        setQuestionIndex(1);
        changeCurrentSection(currentSectionRef.current - 1);
      } else {
        navigate(-1);
      }
    },
    [navigate]
  );

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [currentSection, onBackButtonEvent]);

  const handleSubmit = useCallback(
    async (newAnswerItems: AnswerItem[]) => {
      setLoading(true);
      onSubmit(newAnswerItems).finally(() => {
        setLoading(false);
      });
    },
    [onSubmit]
  );

  const next = () => async () => {
    setQuestionIndex(currentSectionQuestions.length + 1);
    changeCurrentSection(currentSection + 1);
    window.scrollTo(0, 0);
  };

  const back = () => async () => {
    setQuestionIndex(1);
    changeCurrentSection(currentSection - 1);
    window.scrollTo(0, 0);
  };

  /**
   * 回答を共有する対象となるバディさん＋管理者数を計算する
   */
  const SharedEmployeesElement: FC<{ isAdminOnly: boolean }> = ({ isAdminOnly }) => {
    if (isAdminOnly) {
      return (
        <Typography variant="body2">
          {/* 2ページ目（つまり管理者向け回答）では表示するcaptionを切り替える */}
          <Trans
            i18nKey="captionAdminOnly"
            ns="survey"
            components={{
              t: <Typography variant="body2" bold color="secondary" display="inline" />,
            }}
          />
        </Typography>
      );
    } else {
      let context: string;
      const hasMentor = Boolean(mentor);
      const hasSupportMembers = supportMembers.length > 0;
      if (hasMentor && hasSupportMembers) {
        context = "withMentorAndSupportingMembers";
      } else if (hasMentor && !hasSupportMembers) {
        context = "withMentor";
      } else if (!hasMentor && hasSupportMembers) {
        context = "withSupportingMembers";
      } else {
        context = "withAdminOnly";
      }

      return (
        <>
          <Typography variant="body2" display="inline">
            <Trans
              // FIXME: contextつきのkeyをTransコンポーネントでcontextなしで利用すると型エラーが発生する
              // issueをOpen中 https://github.com/i18next/react-i18next/issues/1688
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              i18nKey="captionDefault"
              context={context}
              ns="survey"
              values={{ mentor: mentor?.getName() }}
              components={{
                l: (
                  <StyledPointerTypography
                    variant="body2"
                    component="span"
                    bold
                    color="primary"
                    onClick={() => setOpenViewableModal(true)}
                  />
                ),
              }}
            />
          </Typography>
        </>
      );
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        classes={{ root: isPc ? classes.pcbg : classes.spbg }}
      >
        <Grid item md={10} xs={12}>
          <Box p={3} mb={3}>
            <Box mb={4} mt={isPc ? 10 : 5}>
              <Typography variant="h2">{survey.title}</Typography>
            </Box>
            <Box
              bgcolor="primary.contrastText"
              pl={basePadding}
              pr={basePadding}
              pb={1}
              pt={isPc ? 14 : 0}
              mb={isPc ? 16 : 3}
              className={isPc ? classes.card : ""}
            >
              <Box>
                <Typography variant="body2" bold>
                  {t("description")}
                </Typography>

                <SharedEmployeesElement isAdminOnly={isJoinedSurvey && currentSection === 2} />

                <Box mt={2} mb={5}>
                  <Divider />
                </Box>
              </Box>
              {currentSectionQuestions.map((question, i) => (
                <Fragment key={`SurveyForm__${i}`}>
                  <SurveyForm
                    key={"Question" + currentSection * i}
                    answerItems={newAnswerItems}
                    question={question}
                    changeAnswer={(e) => changeAnswer(e, newAnswerItems, question)}
                    index={i + questionIndex}
                  />
                  <Box height="80px" />
                </Fragment>
              ))}
              <StyledButtonWrapper>
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                  {currentSection > 1 && (
                    <StyledGrid item sm={4} xs={12} order={2}>
                      <Button
                        borderRadius="circle"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={back()}
                      >
                        {t("backButton")}
                      </Button>
                    </StyledGrid>
                  )}
                  <StyledGrid item sm={4} xs={12} order={1}>
                    <Button
                      fullWidth
                      borderRadius="circle"
                      variant="contained"
                      color="primary"
                      onClick={
                        currentSection < survey.totalSection
                          ? next()
                          : () => handleSubmit(newAnswerItems)
                      }
                      isLoading={loading}
                      disabled={!isAnsweredRequiredQuestions}
                    >
                      {currentSection < survey.totalSection ? t("nextButton") : t("submitButton")}
                    </Button>
                  </StyledGrid>
                </Grid>
              </StyledButtonWrapper>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* オンボーディングアンケートではフッターを表示しない */}
      {/* FIXME: 本来はdomainにisOnboardingSurveyを定義してハードコードでなくtypeで判断すべき */}
      {!survey.title.includes("オンボーディング") && (
        <AnswerFooter answerItems={newAnswerItems} questionCount={questionCount} />
      )}
      <Modal
        open={openViewableModal}
        title="回答を閲覧できるメンバー"
        content={
          <AccessibleEmployeeList mentor={mentor} supportMembers={supportMembers} admins={admins} />
        }
        onCancel={() => setOpenViewableModal(false)}
      />
    </>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 80px;
`;

const StyledGrid = styled(Grid)<{ order: number }>`
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    order: ${(props) => props.order};
  }
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
