import { Box } from "@material-ui/core";
import {
  ContactMessageDraft,
  DEFAULT_MAX_FILE_SIZE_MB,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
} from "@onn/common";
import React, { FC, useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

import { Button, TextareaAutosizeAttachableFile } from "~/components/uiParts";
import { useDebouncedCallback } from "~/hooks/shared";

type Props = {
  onSubmit: (newMessage: string, newMessageFiles: File[]) => Promise<void>;
  onChangeHeight: (height: number) => void;
  contactMessageDraft: ContactMessageDraft;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
};

export const TextareaFooter: FC<Props> = ({
  onSubmit,
  onChangeHeight,
  contactMessageDraft,
  saveContactMessageDraft,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [newMessage, setNewMessage] = useState("");
  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNewMessage(contactMessageDraft?.text ?? "");
  }, [contactMessageDraft?.text]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setNewMessage("");
    setNewMessageFiles([]);
    await onSubmit(newMessage, newMessageFiles);
    await saveContactMessageDraft(
      new ContactMessageDraft({
        ...contactMessageDraft,
        text: "",
      })
    );
    setIsLoading(false);
  }, [contactMessageDraft, onSubmit, newMessage, newMessageFiles, saveContactMessageDraft]);

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 500);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewMessage(e.target.value);
      handleDebounceCallback(() => {
        saveContactMessageDraft(
          new ContactMessageDraft({
            id: contactMessageDraft.id,
            contactRoomId: contactMessageDraft.contactRoomId,
            createdUserId: contactMessageDraft.createdUserId,
            text: e.target.value,
            tenantId: contactMessageDraft.tenantId,
          })
        );
      });
    },
    [contactMessageDraft, handleDebounceCallback, saveContactMessageDraft, setNewMessage]
  );

  useEffect(() => {
    if (ref.current) {
      onChangeHeight(ref.current.clientHeight);
    }
    // メッセージの変更時、添付ファイルの変更時に高さを再計算する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, newMessage, newMessageFiles]);

  return (
    // MUI v4ではBoxにrefを渡せないため、divにstyleをつけている
    <StyledDiv ref={ref}>
      <Box width="100%" maxWidth="600px">
        <TextareaAutosizeAttachableFile
          value={newMessage}
          fullWidth
          placeholder="メッセージを入力してください"
          onChange={handleChange}
          minRows={1}
          // FIXME: スタイルの関係で1行増えるため、4行表示されるように3を指定している。
          maxRows={3}
          maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
          accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
          onChangeFiles={(newFiles: (File | Pick<File, "name">)[]) => {
            setNewMessageFiles(
              newFiles.filter((v): v is File => {
                return v instanceof File;
              })
            );
          }}
          attachedFiles={newMessageFiles}
          footerButtons={[
            <Button
              key="submit"
              onClick={handleSubmit}
              borderRadius="regular"
              variant="contained"
              color="primary"
              disabled={!newMessage.trim() || isLoading}
            >
              送信
            </Button>,
          ]}
        />
      </Box>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100vw;
  bottom: 0;
  left: 0;
  padding: 16px;
  background-color: white;
`;
