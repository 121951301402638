/**
 * オブジェクトからundefinedを除外するための関数
 * @param object
 * @returns
 */
export const removeUndefinedFromObject = <V>(object: V): OmitByValue<V, undefined> => {
  if (!object) {
    return object;
  }

  return Object.fromEntries<V>(
    Object.entries<V>(object).filter(([, v]) => v !== undefined)
  ) as OmitByValue<V, undefined>;
};
