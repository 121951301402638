import { Content } from "./Content";

export class Library {
  id: string;
  tenantId: string;
  spaceId?: string;
  index: number;
  label?: string;
  title: string;
  isPublic: boolean;
  createdEmployeeId: string;
  updatedEmployeeId: string;
  contents: Content[];
  createdAt: Date;
  updatedAt: Date;
  constructor({
    id,
    tenantId,
    spaceId,
    index,
    label,
    title,
    isPublic,
    createdEmployeeId,
    updatedEmployeeId,
    contents,
    createdAt,
    updatedAt,
  }: {
    id: string;
    tenantId: string;
    spaceId?: string;
    index: number;
    label?: string;
    title: string;
    isPublic: boolean;
    createdEmployeeId: string;
    updatedEmployeeId: string;
    contents: Content[];
    createdAt: Date;
    updatedAt: Date;
  }) {
    this.id = id;
    this.tenantId = tenantId;
    this.spaceId = spaceId;
    this.index = index;
    this.label = label;
    this.title = title;
    this.isPublic = isPublic;
    this.createdEmployeeId = createdEmployeeId;
    this.updatedEmployeeId = updatedEmployeeId;
    this.contents = contents;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
