import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from "styled-components";

import theme, { constants } from "~/config/theme";
import { useLocalStorage } from "~/hooks/shared";

type Props = {
  children: ReactNode;
};

export const ThemeProvider: FC<Props> = ({ children }: Props) => {
  const { retrieveValue } = useLocalStorage();

  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(
    Boolean(retrieveValue("isOpenSidebar"))
  );

  // theme propsで利用したいlocalStorageの値をstateにsetする
  const setStyleProps = useCallback(() => {
    setIsOpenSidebar(retrieveValue("isOpenSidebar") ?? true); // デフォルトでtrue(初期値はnullかundefined)
  }, [retrieveValue]);

  useEffect(() => {
    window.addEventListener("storage", setStyleProps);
    setStyleProps();
  }, [setStyleProps]);

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <StyledThemeProvider theme={{ ...theme, constants, isOpenSidebar }}>
        {children}
      </StyledThemeProvider>
      <GlobalStyle />
    </MuiThemeProvider>
  );
};

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: Montserrat, "Noto Sans JP", sans-serif !important;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html {
    height: 100%;
  }
  body {
    height: 100%;
  }
  #root {
    height: 100%;
  }

`;
