import { Box } from "@material-ui/core";
import React, { ComponentProps, useCallback, useEffect, useRef, useState, FC } from "react";
import styled from "styled-components";

import { Comment } from "./Comment";
import { CommentForm } from "./CommentForm";

import { Typography } from "~/components/uiParts";
import { useCreateComment } from "~/hooks/comment/useCreateComment";
import { useUpdateComment } from "~/hooks/comment/useUpdateComment";

type CommentProps = ComponentProps<typeof Comment>;

type CommentFormProps = ComponentProps<typeof CommentForm>;

type Props = {
  comments: Omit<
    CommentProps,
    "currentUserId" | "onClickDeleteButton" | "onClickSaveButton" | "suggestedMentionUsers"
  >[];
  commentDisabled?: boolean;
  currentUserId: string;
  transactionId: string;
  submitCommentFormFocused?: boolean;
  isCloseGapNameAndDate?: boolean;
  onBlurSubmitCommentForm?: () => void;
  onClickDeleteCommentButton: (commentId: string) => void;
  loadTransactionComments: (transactionId: string) => void;
} & Pick<CommentFormProps, "suggestedMentionUsers">;

const SCROLL_TO_CONTENT_Y_OFFSET = 24;

export const CommentList: FC<Props> = ({
  comments,
  commentDisabled,
  currentUserId,
  transactionId,
  suggestedMentionUsers,
  submitCommentFormFocused,
  isCloseGapNameAndDate,
  onBlurSubmitCommentForm,
  onClickDeleteCommentButton,
  loadTransactionComments,
}) => {
  const commentListRef = useRef<HTMLUListElement>(null);
  const commentBoxRef = useRef<HTMLUListElement>(null);
  const [commentFormValue, setCommentFormValue] = useState("");

  const handleChangeCommentFormValue = useCallback(
    (value: string) => setCommentFormValue(value),
    []
  );
  const { updateComment } = useUpdateComment();
  const { createComment } = useCreateComment();

  const handleScrollCommentListToBottom = useCallback(() => {
    if (!commentListRef.current) {
      return;
    }

    commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
  }, []);

  const handleSubmitCommentFormValue = useCallback(async () => {
    setCommentFormValue("");
    await createComment(commentFormValue, { transactionId });
    loadTransactionComments(transactionId);
    handleScrollCommentListToBottom(); // コメント追加後に一番下までスクロールした状態にしておく
  }, [
    createComment,
    commentFormValue,
    transactionId,
    loadTransactionComments,
    handleScrollCommentListToBottom,
  ]);

  const handleClickSaveCommentButton = useCallback<CommentProps["onClickSaveButton"]>(
    async (commentId, { content }) => {
      await updateComment(commentId, { content, transactionId });
      loadTransactionComments(transactionId);
    },
    [loadTransactionComments, transactionId, updateComment]
  );

  const handleClickDeleteCommentButton = useCallback<CommentProps["onClickDeleteButton"]>(
    (commentId) => onClickDeleteCommentButton(commentId),
    [onClickDeleteCommentButton]
  );

  // 初期状態で一番下までスクロールした状態にしておく
  useEffect(() => {
    handleScrollCommentListToBottom();
  }, [handleScrollCommentListToBottom]);

  // submitCommentFormFocusedが true の場合、スクロール + カーソルをの末尾に合わせる & フォーカスする
  useEffect(() => {
    if (!submitCommentFormFocused || !commentBoxRef.current) {
      return;
    }

    const commentBoxElement = commentBoxRef.current;
    // コメントが見える位置にwindowを自動スクロールする
    window.scrollTo({
      top:
        commentBoxElement.getBoundingClientRect().top + window.scrollY - SCROLL_TO_CONTENT_Y_OFFSET,
      behavior: "smooth",
    });
  }, [submitCommentFormFocused]);

  return (
    // Box に ref を渡すための ワークアラウンド:https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360
    // TODO: Mui v5に更新したら、refを直接渡せるようになるので修正する
    <Box width="100%" display="flex" flexDirection="column" {...{ ref: commentBoxRef }}>
      <Box mb={2} display="flex" alignItems="center">
        <Typography variant="body2" bold>
          受け入れチームへコメントを残す
        </Typography>
      </Box>
      {comments.length > 0 && (
        <StyledList ref={commentListRef}>
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              {...comment}
              currentUserId={currentUserId}
              suggestedMentionUsers={suggestedMentionUsers}
              isCloseGapNameAndDate={isCloseGapNameAndDate}
              onClickDeleteButton={handleClickDeleteCommentButton}
              onClickSaveButton={handleClickSaveCommentButton}
            />
          ))}
        </StyledList>
      )}
      <CommentForm
        autoFocus={submitCommentFormFocused}
        value={commentFormValue}
        onChange={handleChangeCommentFormValue}
        onBlur={onBlurSubmitCommentForm}
        suggestedMentionUsers={suggestedMentionUsers}
        disabled={commentDisabled}
        textAreaMinHeight={80}
        textAreaMaxHeight={80}
        onSubmit={handleSubmitCommentFormValue}
      />
      <Box height={8} />
      <StyledTypography variant="caption" color="secondary">
        ※入社者本人にこのコメントは届きません。
      </StyledTypography>
    </Box>
  );
};

const StyledList = styled.ul`
  margin: 0px 0px 24px 0px;
  padding: 0;
  max-height: calc(100% - 190px);
  overflow-y: auto;
`;

const StyledTypography = styled(Typography)`
  font-size: 10px;
`;
