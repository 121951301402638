import React, { FC } from "react";

export const File: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 26.998">
      <path
        d="M-14360.208-6259a1.5,1.5,0,0,1-1.5-1.5v-24a1.5,1.5,0,0,1,1.5-1.5h16.5c-.013.046,6.038,6.02,6,6v19.5a1.5,1.5,0,0,1-1.5,1.5Zm1.5-3h18v-15l-1.738,0-2.76,0a1.254,1.254,0,0,1-.173-.011,1.491,1.491,0,0,1-1.327-1.5v-.251c0-.165,0-.4,0-.682v-2.062c0-.521,0-1.041,0-1.5h-12Zm15-18,1.263,0h.481c-.565-.562-1.179-1.168-1.744-1.733Z"
        transform="translate(14361.708 6285.999)"
        stroke="none"
      />
    </svg>
  );
};
