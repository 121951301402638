import { useContext } from "react";

import { CurrentTenantIdContext } from "~/components/providers";

/**
 * ローカルストレージに保存されているテナントIDを管理する
 * @returns
 */
export const useCurrentTenantId = () => {
  return useContext(CurrentTenantIdContext);
};
