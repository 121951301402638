import { Revision } from "@onn/common";
import {
  DocumentData,
  CollectionReference,
  collection,
  query,
  onSnapshot,
  getDoc,
  doc,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IRevisionRepository } from "~/service/repository/iRevisionRepository";

const COLLECTION_NAME = "revisions";
export class RevisionRepository implements IRevisionRepository {
  private unsubscribe?: () => void = undefined;

  async findById(id: string): Promise<Revision | null> {
    const ref = await getDoc(doc(firestore, COLLECTION_NAME, id));

    if (!ref.data()) {
      throw new Error("対象のRevisionが見つかりませんでした。");
    }

    return (ref.data() as Revision) || null;
  }

  listenNewRevision(onFetchNewRevision: (revision: Revision) => void): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.unsubscribe = onSnapshot(query(this.collection()), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const newRevision = Revision.plainToInstance(
          change.doc.data() as DeepExcludeMethods<Revision>
        );
        switch (change.type) {
          case "added":
          case "modified":
            return onFetchNewRevision(newRevision);
          // 消えることはないので一旦ここはむし
          case "removed":
          default:
            return;
        }
      });
    });
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}
