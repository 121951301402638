import { addDays, isWeekend } from "date-fns";

import { isHolidays } from "../isHolidays";

const shiftDate = (date: Date, amount: number, count: number): Date => {
  if (count === amount) {
    return date;
  }

  const nextDay = addDays(date, 1);
  if (isHolidays(nextDay) || isWeekend(nextDay)) {
    return shiftDate(nextDay, amount, count);
  }

  count++;
  return shiftDate(nextDay, amount, count);
};

/**
 * 日本の祝日を反映させて日付を足す関数
 * @param {Date} referenceDate
 * @param {number} amount
 */
export const addBusinessDays = (referenceDate: Date, amount: number) => {
  return shiftDate(referenceDate, amount, 0);
};
