import { ContactRoomWithEmployee } from "../ContactRoomWithEmployee/ContactRoomWithEmployee";

// Onnコンタクトルーム向けのクラス
export class OnnContactRoom extends ContactRoomWithEmployee {
  constructor({
    initSuper,
  }: {
    initSuper: ConstructorParameters<typeof ContactRoomWithEmployee>[0];
  }) {
    super(initSuper);
  }

  // ルーム名を取得するメソッド
  public getRoomName(): string {
    return this.employee?.getName() || "";
  }

  public getHonorificRoomName(): string {
    return `${this.getRoomName()}さん`;
  }

  // ルームアイコンを取得するメソッド
  public getRoomImageUrl(): string | undefined {
    return this.employee?.profileIconImageUrl;
  }
}
