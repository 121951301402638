import React, { ReactNode, FC } from "react";
import ReactLinkify from "react-linkify";
import styled from "styled-components";

type Props = {
  children: ReactNode;
};

export const Linkify: FC<Props> = ({ children }) => {
  return (
    <ReactLinkify
      componentDecorator={(decoratedAdminef, decoratedText, key) => (
        <StyledAnchor target="blank" href={decoratedAdminef} key={`linkify--${key}`}>
          {decoratedText}
        </StyledAnchor>
      )}
    >
      {children}
    </ReactLinkify>
  );
};

const StyledAnchor = styled.a`
  word-break: break-all;
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
`;
