import { Switch as MuiSwitch } from "@material-ui/core";
import React, { FC, ComponentProps } from "react";
import styled from "styled-components";

type CustomProps = {
  disableEffect?: boolean;
};

type Props = ComponentProps<typeof MuiSwitch> & CustomProps;

export const Switch: FC<Props> = ({ disableEffect, ...rest }) => {
  return <StyledSwitch disableRipple={disableEffect} {...rest} />;
};

const StyledSwitch = styled(MuiSwitch)`
  &.MuiSwitch-root {
    padding: 7px;
  }
  .MuiSwitch-thumb {
    color: white;
    box-shadow: none;
  }
  .MuiSwitch-track {
    opacity: unset;
    border-radius: 12px;
    background-color: ${(props) => props.theme.palette.grey[200]};
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    opacity: unset;
  }
`;
