import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  text: string;
  to: string;
};

export const Alert: FC<Props> = ({ text, to }) => {
  return (
    <StyledLink to={to}>
      <Typography variant="body2" bold>
        {text}
      </Typography>
      <Icon size="sm" icon="arrowRight" color="secondary" />
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  background-color: white;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  padding: 16px;
  font-size: 16px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.main};
  text-decoration: none;
  align-items: center;
`;
