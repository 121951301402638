/**
 * 2つのDateの間の日付の差分をJSTベースで計算します。
 * @param {Date} a 比較対象1
 * @param {Date} b 比較対象2
 * @returns {number} 差分
 */
export const getDayDiffInJST = (a: Date, b: Date): number => {
  // JSTでの日時のDateを取る
  // 参考 https://neos21.net/blog/2020/12/09-01.html

  const timeZoneOffset = (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000;
  const aInJST = new Date(a.getTime() + timeZoneOffset);
  const bInJST = new Date(b.getTime() + timeZoneOffset);

  // 日付までを取る
  const aMonth = aInJST.getMonth() + 1 < 10 ? `0${aInJST.getMonth() + 1}` : aInJST.getMonth() + 1;
  const bMonth = bInJST.getMonth() + 1 < 10 ? `0${bInJST.getMonth() + 1}` : bInJST.getMonth() + 1;
  const aDate = aInJST.getDate() < 10 ? `0${aInJST.getDate()}` : aInJST.getDate();
  const bDate = bInJST.getDate() < 10 ? `0${bInJST.getDate()}` : bInJST.getDate();
  const aWithoutTime = new Date(`${aInJST.getFullYear()}-${aMonth}-${aDate}`);
  const bWithoutTime = new Date(`${bInJST.getFullYear()}-${bMonth}-${bDate}`);

  // 日付の差分を取る
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = aWithoutTime.getTime() - bWithoutTime.getTime();
  return Math.abs(Math.floor(diffInTime / oneDay));
};
