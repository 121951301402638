import React, { FC } from "react";

export const Search: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="Group_1973" data-name="Group 1973" transform="translate(0 -1)">
        <g
          id="Ellipse_242"
          data-name="Ellipse 242"
          transform="translate(0 1)"
          fill="none"
          strokeWidth="2"
        >
          <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
          <circle cx="8.5" cy="8.5" r="7.5" fill="none" />
        </g>
        <line
          id="Line_98"
          data-name="Line 98"
          x2="4"
          y2="4"
          transform="translate(14.5 15.5)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
