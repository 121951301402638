import { ListItem, ListItemIcon, ListItemText, Box } from "@material-ui/core";
import React, { FC, ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icon, Typography, Tooltip } from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";

type CustomProps = {
  label: string;
  to: string;
  shouldShowName: boolean;
  currentPathname: string;
  isBadge?: boolean;
  targetBlank?: boolean;
  externalLink?: boolean;
} & Partial<Pick<ComponentProps<typeof Icon>, "icon">>;

const checkActive = (to: string, currentPathname: string) => {
  return to === "/"
    ? to === currentPathname || currentPathname.includes("/employee/")
    : currentPathname.includes(to);
};

export const SidebarListItem: FC<CustomProps> = React.memo((props) => {
  const active = checkActive(props.to, props.currentPathname);

  const TooltipComponent = React.memo(({ children }: { children: ReactNode }) => {
    return props.shouldShowName ? (
      <>{children}</>
    ) : (
      <Tooltip title={props.label} placement="right" arrow>
        <> {children}</>
      </Tooltip>
    );
  });

  const StyledListItemComponent = React.memo(() => {
    return (
      <StyledBox p={0} $isSelected={active}>
        <StyledListItem selected={active} button $shouldShowName={props.shouldShowName}>
          {props.icon && (
            <ListItemIcon>
              <Badge color="secondary" variant={props.isBadge ? "dot" : "standard"}>
                <Icon icon={props.icon} size="md" color={active ? "primary" : "grey"} />
              </Badge>
            </ListItemIcon>
          )}
          {props.shouldShowName && (
            <ListItemText
              primary={
                <Typography variant="body2" bold color={active ? "primary" : "textSecondary"}>
                  {props.label}
                </Typography>
              }
            />
          )}
        </StyledListItem>
      </StyledBox>
    );
  });

  return props.externalLink ? (
    <StyledAnchor href={props.to} target={props.targetBlank ? "_blank" : ""}>
      <TooltipComponent>
        <StyledListItemComponent />
      </TooltipComponent>
    </StyledAnchor>
  ) : (
    <StyledLink to={props.to} target={props.targetBlank ? "_blank" : ""}>
      <TooltipComponent>
        <StyledListItemComponent />
      </TooltipComponent>
    </StyledLink>
  );
});

const StyledBox = styled(Box)<{ $isSelected: boolean }>`
  border-left: ${(props) => props.$isSelected && `solid 4px ${props.theme.palette.primary.main}}`};
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledListItem = styled(ListItem)<{ $shouldShowName: boolean }>`
  &.MuiButtonBase-root.MuiListItem-root {
    margin-left: 32px;
    width: ${(props) => (props.$shouldShowName ? 190 : 40)}px;
    padding: 8px;
    background-color: ${(props) => props.theme.palette.common.white};
    border-radius: 8px;
  }

  &.MuiButtonBase-root.Mui-selected {
    /* 左端のボーダーの幅4px分減らしている */
    margin-left: calc(32px - 4px);
    box-sizing: border-box;
    :hover {
      background-color: ${(props) => props.theme.palette.grey[50]};
    }
  }

  &.MuiButtonBase-root:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }

  .MuiListItemText-root {
    margin: 0px;
  }
`;
