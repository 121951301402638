import React, { ReactNode, FC } from "react";
import { Droppable } from "react-beautiful-dnd";

type Props = {
  droppableId: string;
  isDropDisabled: boolean;
  children: ReactNode;
};

export const DnDDroppable: FC<Props> = ({ droppableId, isDropDisabled, children }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
