export class Message {
  id: string;
  title: string;
  iconPath: string;
  fullName: string;
  position: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;

  constructor({ id, title, iconPath, fullName, position, content, createdAt, updatedAt }: Message) {
    this.id = id;
    this.title = title;
    this.iconPath = iconPath;
    this.fullName = fullName;
    this.position = position;
    this.content = content;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
