import { Employee, Role } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

/**
 * tenantIdをもとにadminを返すswr
 * @param tenantId tenantのid
 */
export const useAdmins = (tenantId?: string): SWRResponse<Employee[], Error> => {
  const keys = tenantId ? [`admins-${tenantId}`, tenantId] : null;

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    EmployeeUseCase.findByRole(tenantId, Role.ADMIN)
  );
};
