import { Box } from "@material-ui/core";
import React, { useCallback, useState, FC } from "react";

import { IconButton } from "~/components/uiParts/IconButton";

type Props = {
  actionType: "download" | "delete";
  onClick: () => void;
};

export const ActionButton: FC<Props> = ({ actionType, onClick }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  return (
    <Box onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <IconButton
        icon={actionType === "download" ? "download" : "trash"}
        size="md"
        color={hovered ? "primary" : "lightGrey"}
        onClick={onClick}
      />
    </Box>
  );
};
