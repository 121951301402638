import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { differenceInCalendarDays } from "date-fns";
import React, { FC } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../hooks";

import { UserIconGroup, Typography } from "~/components/uiParts";

type Props = {
  newcomerEmployee: AugmentedRequired<Employee, "joinAt">;
  users: Employee[];
};

export const BeforeJoin: FC<Props> = ({ newcomerEmployee, users }) => {
  const { getBorderColorById } = useGetBorderColorById();

  const now = new Date();
  const diff = differenceInCalendarDays(new Date(newcomerEmployee.joinAt), now);

  return (
    <>
      <Box textAlign="left">
        <Typography variant="caption" color="textSecondary">
          入社まであと
        </Typography>
      </Box>
      <Box textAlign="center">
        <Box height="16px" />
        <Typography variant="h1" bold color="primary" display="inline">
          {diff}
        </Typography>
        <Box display="inline" m="2px" />
        <Typography variant="h2" bold color="primary" display="inline">
          {diff === 1 ? "day" : "days"}
        </Typography>
        <Box height="16px" />
        <Box lineHeight={2} textAlign="center">
          <Typography variant="body2">
            {`${newcomerEmployee.lastName}さんの入社を\nメンバー全員で楽しみにお待ちしています！`}
          </Typography>
        </Box>
        <Box height="16px" />
      </Box>

      <StyledUserIconGroup
        usersInfo={users.map((u) => ({
          username: u.getName(),
          profileIconImageUrl: u.profileIconImageUrl,
          borderColor: getBorderColorById(newcomerEmployee, u.id),
        }))}
        max={users.length}
      />
    </>
  );
};

const StyledUserIconGroup = styled(UserIconGroup)`
  display: flex;
  justify-content: center;
`;
