import { AllContactRoom } from "@onn/common";
import { throttle } from "lodash";
import React, { FC, createContext, ReactNode, useEffect, useMemo } from "react";
import { KeyedMutator } from "swr";

import { useContactRoomsV2 } from "~/hooks/contactRoom";
import { useContactRoomUnreadCountV2 } from "~/hooks/contactRoomUnreadCount";
import { useCurrentUser } from "~/hooks/employee";
import { UnreadCountInfoRepository } from "~/infrastructure/api/unreadCountInfoRepository";

const unreadCountInfoRepository = new UnreadCountInfoRepository();

export const ContactContextV2 = createContext<{
  contactRoomUnreadCountList: { contactRoomId: string; unreadCount: number }[];
  totalUnreadCount: number;
  isLoadingUnreadMessageCountList: boolean;
  contactRooms: AllContactRoom[];
  contactRoomsWithoutCurrentUser: AllContactRoom[];
  isLoadingContactRooms: boolean;
  contactRoomsMutate: KeyedMutator<AllContactRoom[]>;
}>({
  contactRoomUnreadCountList: [],
  totalUnreadCount: 0,
  isLoadingUnreadMessageCountList: true,
  contactRooms: [],
  contactRoomsWithoutCurrentUser: [],
  isLoadingContactRooms: true,
  contactRoomsMutate: async () => [],
});

export const ContactProviderV2: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  const {
    data: unreadMessageCountList = [],
    mutate: mutateUnreadMessageCountList,
    isLoading: isLoadingUnreadMessageCountList,
  } = useContactRoomUnreadCountV2();

  // NOTE: 暫定対応
  const {
    data: contactRooms = [],
    isLoading: isLoadingContactRooms,
    mutate: contactRoomsMutate,
  } = useContactRoomsV2({
    tenantId: currentUser.tenantId,
    isIncludeCurrentUserContactRoom: true,
  });

  const contactRoomsWithoutCurrentUser = useMemo(
    () => contactRooms.filter((v) => v.employeeId !== currentUser.id),
    [contactRooms, currentUser.id]
  );

  // 担当者・フォロワーになっているコンタクトルームに紐付く未読情報
  // 担当・フォロワーになっていないコンタクトルームの未読情報もfirestoreで管理しているため
  const targetUnreadMessageCountList = useMemo(() => {
    return unreadMessageCountList.filter((v) => {
      return contactRooms.find((contactRoom) => contactRoom.id === v.contactRoomId);
    });
  }, [contactRooms, unreadMessageCountList]);

  const totalUnreadCount = useMemo(() => {
    return targetUnreadMessageCountList.reduce((acc, crr) => {
      acc += crr.unreadCount;
      return acc;
    }, 0);
  }, [targetUnreadMessageCountList]);

  // NOTE:throttleを使用することで、一気にメッセージの更新が行われても、CFへのリクエストが一気に行われることを防ぐ
  // とりあえず2000msにしている
  const throttleMutateUnreadMessageCountList = throttle(() => {
    mutateUnreadMessageCountList();
  }, 2000);

  useEffect(() => {
    if (targetUnreadMessageCountList.length < 0) return;
    const unsubscribeFunctions = unreadCountInfoRepository.listenUnreadCountInfo({
      contactRoomIds: targetUnreadMessageCountList.map((v) => v.contactRoomId),
      employeeId: currentUser.id,
      tenantId: currentUser.tenantId,
      onChangedUnreadCountInfo: () => {
        throttleMutateUnreadMessageCountList();
      },
    });
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
    };
    // unreadMessageCountListの参照が変わる度に実行すると無駄にlistenが走るので、lengthが変わった時だけ実行する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetUnreadMessageCountList.length]);

  return (
    <ContactContextV2.Provider
      value={{
        contactRoomUnreadCountList: unreadMessageCountList,
        isLoadingUnreadMessageCountList,
        totalUnreadCount,
        contactRoomsWithoutCurrentUser,
        contactRooms,
        isLoadingContactRooms,
        contactRoomsMutate,
      }}
    >
      {children}
    </ContactContextV2.Provider>
  );
};
