import { TableRow as MuiTableRow } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

type Props = ComponentProps<typeof MuiTableRow> & {
  children: React.ReactNode;
};

export const TableRow: FC<Props> = (props) => {
  return (
    <StyledMuiTableRow {...props}>
      {/* <TableRowContainer>
        <div style={{ width: 40 }} />
        <Grid container> */}
      {props.children}
      {/* </Grid>
        <div style={{ width: 40 }} />
      </TableRowContainer> */}
    </StyledMuiTableRow>
  );
};

const StyledMuiTableRow = styled(MuiTableRow)`
  &.MuiTableRow-root {
    padding: 0 24px;
    text-decoration: none;
  }
`;
