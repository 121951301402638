import { ListItem as MuiListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { FC, ComponentProps } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Chip, Icon, Typography } from "~/components/uiParts";

type CustomProps = {
  label: string;
  to: string;
  badgeLabel?: string;
  targetBlank?: boolean;
  externalLink?: boolean;
  onClick?: () => void;
} & Partial<Pick<ComponentProps<typeof Icon>, "icon">>;

export const ListItem: FC<CustomProps> = React.memo((props) => {
  const StyledListItemComponent = React.memo(() => {
    return (
      <StyledListItem button onClick={props.onClick}>
        {props.icon && (
          <ListItemIcon>
            <Icon icon={props.icon} size="md" color="grey" />
          </ListItemIcon>
        )}

        <ListItemText
          primary={
            <Typography variant="body1" bold color="textSecondary">
              {props.label}
              {props.badgeLabel && <StyledChip color="secondary" label={props.badgeLabel} bold />}
            </Typography>
          }
        />
      </StyledListItem>
    );
  });

  return props.externalLink ? (
    <StyledAnchor href={props.to} target={props.targetBlank ? "_blank" : ""}>
      <StyledListItemComponent />
    </StyledAnchor>
  ) : (
    <StyledLink to={props.to} target={props.targetBlank ? "_blank" : ""}>
      <StyledListItemComponent />
    </StyledLink>
  );
});

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledListItem = styled(MuiListItem)`
  &.MuiButtonBase-root.MuiListItem-root {
    height: 40px;
    padding: 8px;
  }
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: fit-content;
    width: fit-content;
    padding: 0 8px;
    margin-left: 8px;
    border-radius: 8px;

    & > .MuiChip-label {
      padding: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
