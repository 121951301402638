import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Paper, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type LocationState = {
  email?: string;
};

export default function Thanks(): JSX.Element {
  const locationState = useLocation().state as LocationState;
  const email = locationState ? locationState.email : "";
  const navigate = useNavigate();

  if (!email) {
    navigate({ pathname: "/reset_password" });
  }

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Box>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={10} sm={11}>
              <Box mb={5} textAlign="center">
                <Typography variant="h2" bold>
                  {"メールが送信されました！"}
                </Typography>
              </Box>
            </Grid>
            <Box textAlign="center">
              <Typography
                variant="body1"
                bold
                color="textSecondary"
              >{`パスワード再設定のメールを\n${email}に送信しました。\n手順に従って再設定をお願いします。`}</Typography>
            </Box>
          </Grid>
        </Box>
      </StyledPaper>
      <Box mt={4} textAlign="center">
        <Typography variant="caption" color="textSecondary">
          ブラウザを閉じて終了してください。
        </Typography>
      </Box>
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    box-shadow: 0 0 10px #0000000d;
    ${mixin.breakDown.sm`
      box-shadow: none;
    `}
  }
`;
