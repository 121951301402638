import { Failure, Success, Result, Employee } from "../../../../domain";
import { contactRoomActionTypes } from "../shared";

import { ICountUnreadByEmployeeRequest, countUnreadByEmployeeRequestSchema } from "./schema";

export class CountUnreadByEmployeeRequest implements ICountUnreadByEmployeeRequest {
  static readonly validator = countUnreadByEmployeeRequestSchema;

  actionType: typeof contactRoomActionTypes.countUnreadByEmployee = "countUnreadByEmployee";
  newHireId: Employee["id"];

  constructor(init: CountUnreadByEmployeeRequest) {
    this.newHireId = init.newHireId;
  }

  public static validate(
    data: CountUnreadByEmployeeRequest
  ): Result<CountUnreadByEmployeeRequest, Error> {
    const onnEventIdResult = CountUnreadByEmployeeRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new CountUnreadByEmployeeRequest(data));
  }
}
