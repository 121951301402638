import React, { FC } from "react";

export const ArrowLeft: FC = () => {
  return (
    <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 20.5L13.455 19.045L7.949 13.539L20 13.539V11.461L7.948 11.461L13.455 5.955L12 4.5L4 12.5L12 20.5Z"
        stroke="none"
      />
    </svg>
  );
};
