import { Box, ListItemIcon } from "@material-ui/core";
import React, { FC, memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Chip } from "~/components/uiParts";
import { Typography } from "~/components/uiParts/Typography";
import { UserIcon } from "~/components/uiParts/UserIcon";

type IconType = { name: string; url: string };

type Props = {
  title: string;
  emoji?: string;
  imageUrl?: string;
  to?: string;
  icon?: IconType;
  border?: boolean;
  error?: boolean;
  onClick?: () => void;
  caption?: string;
  badgeLabel?: string;
};

const portalIcon = (emoji: string | undefined, icon?: IconType): JSX.Element | null => {
  if (emoji) {
    return <StyledListItemIcon>{emoji}</StyledListItemIcon>;
  } else if (icon) {
    return (
      <Box height={32} mr={1}>
        <UserIcon
          circular
          username={icon.name}
          size="semiSmall"
          profileIconImageUrl={icon.url}
          borderColor="primary"
        />
      </Box>
    );
  } else {
    return null;
  }
};

const getTitleColor = (border: boolean, error: boolean) => {
  if (border) return "textPrimary";
  return error ? "secondary" : "textPrimary";
};

const getCaptionColor = (border: boolean, error: boolean) => {
  if (border) return "textSecondary";
  return error ? "secondary" : "textSecondary";
};

export const PortalListItem: FC<Props> = memo(
  ({ title, emoji, imageUrl, to, icon, border, error, onClick, caption, badgeLabel }) => {
    const Content = () => (
      <StyledDiv onClick={onClick} $border={Boolean(border)}>
        {imageUrl && (
          <StyledImageWrapper>
            <StyledImage width="100%" src={imageUrl} />
          </StyledImageWrapper>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" p={3}>
          <Box display="flex" alignItems="center">
            {portalIcon(emoji, icon)}
            <Box display="flex" flexDirection="column" gridRowGap="8px">
              <StyledTitleWrapper>
                <Typography variant="body2" color={getTitleColor(Boolean(border), Boolean(error))}>
                  {title}
                </Typography>
                {badgeLabel && <StyledChip color="secondary" label={badgeLabel} bold />}
              </StyledTitleWrapper>

              {caption && (
                <Typography
                  variant="caption"
                  color={getCaptionColor(Boolean(border), Boolean(error))}
                >
                  {caption}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </StyledDiv>
    );

    if (to) {
      return (
        <StyledLink to={to}>
          <Content />
        </StyledLink>
      );
    } else {
      return <Content />;
    }
  }
);

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledDiv = styled.div<{ $border: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  cursor: pointer;
  border-radius: 15px;
  ${(props) => props.$border && `border: 1px solid ${props.theme.palette.primary.main}`}
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  /* アスペクト比が1:1.91になるように */
  padding-top: ${(props) => (1 / props.theme.constants.STANDARD_IMAGE_WIDTH_RATIO) * 100}%;
`;

const StyledImage = styled.img`
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: fit-content;
    width: fit-content;
    font-size: 14px;
    padding: 0 8px;

    & > .MuiChip-label {
      padding: 0;
    }
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  font-size: 22px;
  width: 32px;
  height: 32px;
`;
