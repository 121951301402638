import { captureException } from "./loggerUtil";

function loadHeadway() {
  try {
    var config = {
      selector: ".headway", // CSS selector where to inject the badge
      account: "7g52oy",
    };
    window.Headway.init(config);
  } catch (error) {
    captureException({
      error: error,
      tags: { type: "headway" },
    });
  }
}

export { loadHeadway };
