import { Box } from "@material-ui/core";
import React, { FC, Fragment, useCallback } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type ContentText = { text: string } & (
  | { type: "plain" }
  | { type: "mention"; user?: { id: string; name: string } }
);

type Comment = {
  id: string;
  user?: { name: string; profileIconImageUrl?: string };
  content: ContentText[];
  createdAt: string;
};

type Props = {
  open: boolean;
  comment: Comment;
  onCancel: () => void;
  onAccept: (commentId: string) => void;
};

export const DeleteCommentConfirmModal: FC<Props> = ({ open, comment, onCancel, onAccept }) => {
  const handleAccept = useCallback(() => onAccept(comment.id), [comment.id, onAccept]);

  return (
    <>
      <Modal
        open={open}
        title="コメント削除"
        content={
          <>
            <Box mb={2} textAlign="center">
              <Typography variant="caption">
                コメントを削除しますか？この操作は取り消すことができません。
              </Typography>
            </Box>
            <StyledBox p={2} display="flex">
              <Box display="flex" flexDirection="column" alignItems="center" mr={1}>
                <UserIcon
                  circular
                  size="small"
                  username={comment.user?.name || ""}
                  profileIconImageUrl={comment.user?.profileIconImageUrl}
                />
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" justifyContent="space-between" mb={1.5}>
                  <Typography variant="body2" bold>
                    {comment.user?.name}
                  </Typography>
                  <StyledTime>{comment.createdAt}</StyledTime>
                </Box>
                <StyledParagraph>
                  {comment.content.map(({ type, text }, i) =>
                    type === "mention" ? (
                      <StyledEm key={i}>{text}</StyledEm>
                    ) : (
                      <Fragment key={i}>
                        <Linkify
                          componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={`${i}--${key}`}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          {text}
                        </Linkify>
                      </Fragment>
                    )
                  )}
                </StyledParagraph>
              </Box>
            </StyledBox>
          </>
        }
        footer={
          <StyledButtonContainer>
            <Button
              fullWidth
              borderRadius="circle"
              variant="outlined"
              color="default"
              onClick={onCancel}
            >
              キャンセル
            </Button>
            <Button
              fullWidth
              borderRadius="circle"
              variant="contained"
              color="secondary"
              onClick={handleAccept}
            >
              削除
            </Button>
          </StyledButtonContainer>
        }
        onCancel={onCancel}
      />
    </>
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
`;

const StyledTime = styled.time`
  flex-shrink: 0;
  font-size: 12px;
  font-weight: normal;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledParagraph = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  white-space: pre-wrap;
  word-break: break-all;
`;

const StyledEm = styled.em`
  font-style: normal;
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
