import React, { FC } from "react";

export const Navi: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-42 -383)">
        <g transform="translate(42 383)">
          <path
            d="M1413.208,556.794a4.535,4.535,0,0,0-6.412,0l-6.932,6.932a4.522,4.522,0,0,0-1.328,3.207v4.534h4.534a4.517,4.517,0,0,0,3.206-1.328l6.932-6.932A4.534,4.534,0,0,0,1413.208,556.794Zm-1.923,4.489-6.932,6.932a1.779,1.779,0,0,1-.4.291l-2.459-2.46a1.782,1.782,0,0,1,.29-.4l6.932-6.933a1.814,1.814,0,1,1,2.565,2.565Z"
            transform="translate(-1394.535 -551.466)"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};
