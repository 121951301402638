import { Grid, Box, TextField, Fade } from "@material-ui/core";
import { debounce } from "lodash";
import React, { FC, useRef, useState, useEffect, useCallback, RefObject, ChangeEvent } from "react";
import styled from "styled-components";

import {
  ImageUploadArea,
  TextContext,
  Typography,
  Paper,
  AdminContentWrapper,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";
import logo from "~/images/logo.svg";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { TenantUseCase } from "~/service/usecases/tenantUseCase";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

export const TenantTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useTenant();

  const basicSettingsRef = useRef<HTMLDivElement>(null);
  // const holidaySettingsRef = useRef<HTMLDivElement>(null);

  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();
  const [logoLoading, setLogoLoading] = useState(true);

  const [tenantNameSaved, setTenantNameSaved] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>();

  const fetchLogo = useCallback(async () => {
    setLogoLoading(true);
    try {
      setLogoUrl(
        await fileAPIAdapter.fetchUrl({
          path: `public/uploads/logo/${currentUser.tenantId}`,
        })
      );
    } catch (e) {
      setLogoLoading(false);
      captureException({
        error: e as Error,
        tags: { type: "TenantTab:fetchLogo" },
      });
    }

    setLogoLoading(false);
  }, [currentUser]);

  useEffect(() => {
    fetchLogo();
  }, [currentUser.tenantId, fetchLogo]);

  const autoSaveForTenantName = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSaveForTenantName(e.target.value);
  };

  const debouncedSaveForTenantName = debounce(async (tenantName: string) => {
    if (!tenant) return;

    await TenantUseCase.updateTenantName(tenant.tenantId, tenantName);

    setTenantNameSaved(true);
    setTimeout(() => {
      setTenantNameSaved(false);
    }, 3000);
  }, 200);

  const handleUploadImage = async (image: File) => {
    if (!tenant) return;
    await fileAPIAdapter.upload({
      path: `public/uploads/logo/${tenant.tenantId}`,
      file: image,
    });
    fetchLogo();
    enqueueSnackbar("ロゴが更新されました", {
      variant: "success",
    });
  };

  const handleErrorUploadImage = () => {
    enqueueSnackbar("ロゴは120px*120pxから2100px*2100pxの間で設定してください", {
      variant: "error",
    });
  };

  const FadeText = (saved: boolean, text: string) => {
    return (
      <Fade in={saved} timeout={1000}>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption" color="primary">
            {text}
          </Typography>
        </Box>
      </Fade>
    );
  };

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={3}>
            <StyledBox>
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(basicSettingsRef)}
                $isActive={scrollTargetRef === basicSettingsRef}
              >
                基本設定
              </StyledSideTypography>
              <Box height="32px" />
              {/* <StyledSideTypography
              variant="body2"
              onClick={() => setScrollTargetRef(holidaySettingsRef)}
              $isActive={scrollTargetRef === holidaySettingsRef}
            >
              休日設定
            </StyledSideTypography> */}
            </StyledBox>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} ref={basicSettingsRef}>
              <StyledTextContext padding={2} isShowBg bold>
                基本設定
              </StyledTextContext>
            </Grid>
            <Box px={2} mt={4}>
              <Grid item xs={12} md={9}>
                <Typography variant="body2" bold>
                  会社名
                </Typography>
                <Box height="8px" />
                <Typography variant="caption" color="textSecondary">
                  会社名を設定すると、ユーザーへ送られるメールなどの文章に自動反映されます。
                </Typography>
                <TextField
                  id="company_name"
                  name="company_name"
                  defaultValue={tenant.tenantName}
                  variant="outlined"
                  margin="normal"
                  size="medium"
                  fullWidth
                  color="primary"
                  onChange={autoSaveForTenantName}
                />
                {FadeText(tenantNameSaved, "変更が保存されました")}
                <Typography variant="body2" bold>
                  ロゴ
                </Typography>
                <Box height="8px" />
                <Typography variant="caption" color="textSecondary">
                  {`入社者画面に表示される会社のロゴを設定してください。未設定の場合、Onnのロゴが反映されます。\nロゴは「h120px × w420px以上」を推奨しています。`}
                </Typography>
                <Box height="8px" />
                <ImageUploadArea
                  alt="会社ロゴ"
                  loading={logoLoading}
                  defaultImage={logoUrl || logo}
                  imagePath={logoUrl || logo}
                  allowImageSize={{
                    max: { width: 2100, height: 2100 },
                    min: { width: 120, height: 120 },
                  }}
                  onChange={handleUploadImage}
                  onError={handleErrorUploadImage}
                  size="large"
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </AdminContentWrapper>
  );
};
const StyledBox = styled(Box)`
  position: sticky;
  top: 20px;
`;

const StyledSideTypography = styled(Typography)<{ $isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.$isActive &&
    `
      color: ${props.theme.palette.primary.main};
      font-weight: bold;
  `}
`;

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
`;
