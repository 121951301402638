import { differenceInMinutes } from "date-fns";
import { v4 } from "uuid";

import { EditableEntityBase } from "../../shared";

export class OnboardingTaskMemo extends EditableEntityBase {
  id: string;
  text: string;

  constructor(
    init: Pick<
      OnboardingTaskMemo,
      "id" | "text" | "createdEmployeeId" | "updatedEmployeeId" | "createdAt" | "updatedAt"
    >
  ) {
    super(init);
    this.id = init.id;
    this.text = init.text;
  }

  update(text: string, employeeId: string) {
    this.text = text;
    return super.onUpdate(employeeId);
  }

  /**
   * 通知すべき時間が経過したかどうかを返すメソッド
   * @returns boolean
   */
  getShouldNotify() {
    return differenceInMinutes(new Date(), this.updatedAt) > 5;
  }

  public static create(text: string, employeeId: string) {
    return new OnboardingTaskMemo({
      id: v4(),
      text: text,
      createdEmployeeId: employeeId,
      updatedEmployeeId: employeeId,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
