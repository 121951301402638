import { Maintenance } from "@onn/common";

import { MaintenanceRepository } from "~/infrastructure/api/maintenanceRepository";

export interface IMaintenanceRepository {
  get(): Promise<Maintenance>;
}
export const factory = {
  maintenanceRepository: (): IMaintenanceRepository => {
    return new MaintenanceRepository();
  },
};
