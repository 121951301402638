import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

type Props = {
  circleIcon?: React.ReactNode;
};
export const ActivityListItemLayout = ({
  circleIcon,
  children,
}: Props & {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <StyledListItem>
      <Box mr={2} display="flex" flexDirection="column" alignItems="center">
        <StyledCircle>{circleIcon ?? <></>}</StyledCircle>
      </Box>
      <Box width="100%">{children}</Box>
    </StyledListItem>
  );
};

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledListItem = styled.li`
  padding: 0px 8px;
  list-style: none;
  display: flex;
  :not(:last-child) {
    & > .MuiBox-root:first-of-type {
      ::after {
        width: 3px;
        // 縦線が丸に対して右にずれないよう、縦線の太さに対してマイナスマージンをかける
        margin-left: calc(-3px / 2);
        height: 100%;
        content: "" "";
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
    & > .MuiBox-root:last-of-type {
      padding-bottom: ${(props) => props.theme.spacing(5)}px;
    }
  }
`;
