import React, { FC } from "react";

export const Filter: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 16 16">
      <rect stroke="none" width="12" height="2" rx="1" />
      <rect stroke="none" width="8" height="2" rx="1" transform="translate(2 4)" />
      <rect stroke="none" width="4" height="2" rx="1" transform="translate(4 8)" />
    </svg>
  );
};
