import { Employee } from "@onn/common";
import React, { FC, useState, useCallback, FormEvent, MouseEvent, ChangeEvent } from "react";
import styled from "styled-components";

import { TextField, IconButton } from "~/components/uiParts";
import { useDebouncedCallback, useFilterObjectByPartialMatch } from "~/hooks/shared";

type Props = {
  showPlaceholder?: boolean;
  variant?: "standard" | "outlined";
  employees: Employee[];
  onSearch: (results: Employee[]) => void;
};

export const EmployeeSearchForm: FC<Props> = ({
  showPlaceholder,
  variant = "standard",
  employees,
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchSlackUserChange = useDebouncedCallback((callback) => callback(), 200);
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const handleSearchSlackUser = useCallback(
    (
      e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
      query?: string
    ) => {
      e.preventDefault();

      const results = filterObjectByPartialMatch<Employee>({
        objects: employees,
        searchText: query || "",
        getProperties: [
          (employee: Employee) => employee.getName(),
          (employee: Employee) => employee.email,
        ],
      });

      onSearch(results);
    },
    [filterObjectByPartialMatch, employees, onSearch]
  );

  return (
    <StyledForm onSubmit={(e) => handleSearchSlackUser(e, searchValue)}>
      <TextField
        autoFocus
        fullWidth
        variant={variant}
        placeholder={showPlaceholder ? "ユーザー名・メールアドレスで検索" : ""}
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);

          handleSearchSlackUserChange(() => {
            handleSearchSlackUser(e, e.target.value);
          });
        }}
        endAdornment={
          <IconButton
            size="sm"
            color="grey"
            icon="search"
            onClick={(e) => handleSearchSlackUser(e, searchValue)}
          />
        }
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
`;
