import React, { FC } from "react";

export const Suitcase: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(1.021 1.91)">
        <path
          d="M23941.229,10143.59a1.249,1.249,0,0,1-1.25-1.251v-10a1.249,1.249,0,0,1,1.25-1.251h2.748v-2.747a1.249,1.249,0,0,1,1.25-1.251h8a1.249,1.249,0,0,1,1.25,1.251v2.747h2.754a1.253,1.253,0,0,1,1.25,1.251v10a1.253,1.253,0,0,1-1.25,1.251Zm1.25-2.5h13.5v-7.5h-13.5Zm9.5-10v-1.5h-5.5v1.5Z"
          transform="translate(-23938.25 -10125.25)"
          stroke="none"
        />
      </g>
    </svg>
  );
};
