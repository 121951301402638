import { EMPLOYEE_ACTION_TYPE, EmployeeActiveLog } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  setDoc,
  where,
  query,
  getDocs,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { v4 } from "uuid";

import { firestore } from "~/config/firebase";
import { IEmployeeActiveLogRepository } from "~/service/repository/iEmployeeActiveLogRepository";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

type EmployeeActiveLogForDB = Omit<
  ConstructorParameters<typeof EmployeeActiveLog>[0],
  "createdAt"
> & {
  createdAt: Timestamp;
};

const COLLECTION_NAME = "employeeActiveLog";

export class EmployeeActiveLogRepository implements IEmployeeActiveLogRepository {
  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private dbToObject({ createdAt, ...rest }: EmployeeActiveLogForDB): EmployeeActiveLog {
    return new EmployeeActiveLog({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
    });
  }

  async create(
    employeeId: string,
    type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE],
    tenantId: string,
    targetId?: string
  ): Promise<EmployeeActiveLog> {
    const id = v4();
    const now = new Date();

    const log: EmployeeActiveLog = {
      id,
      type,
      employeeId,
      createdAt: now,
      tenantId,
      ...(targetId && { targetId }), // targetIdがundefinedのときはプロパティに追加しない
    };

    const ref = this.doc(id);
    await setDoc(ref, log);

    return log;
  }

  async findByEmployeeId(employeeId: string, tenantId: string): Promise<EmployeeActiveLog[]> {
    return await getDocs(
      query(
        this.collection(),
        where("employeeId", "==", employeeId),
        where("tenantId", "==", tenantId)
      )
    ).then(async (activityLogSnapshot) => {
      return activityLogSnapshot.docs.map((doc) => {
        return this.dbToObject(doc.data() as EmployeeActiveLogForDB);
      });
    });
  }

  async findByEmployeeIdAndType(
    employeeId: string,
    tenantId: string,
    type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE]
  ): Promise<EmployeeActiveLog[]> {
    return await getDocs(
      query(
        this.collection(),
        where("employeeId", "==", employeeId),
        where("tenantId", "==", tenantId),
        where("type", "==", type)
      )
    ).then(async (activityLogSnapshot) => {
      return activityLogSnapshot.docs.map((doc) => {
        return this.dbToObject(doc.data() as EmployeeActiveLogForDB);
      });
    });
  }

  async deleteById(employeeActiveLogId: string): Promise<void> {
    return await deleteDoc(this.doc(employeeActiveLogId));
  }
}
