import React, { FC } from "react";

export const Download: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M19,15v2.667C19,18.4,18.2,19,17.222,19H4.778C3.8,19,3,18.4,3,17.667V15"
        transform="translate(1 -1)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M7,10l4,4,4-4"
        transform="translate(1)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        y1="8"
        transform="translate(12 6)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
