import { Box, DialogContent } from "@material-ui/core";
import React, { FC } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";

import { Modal } from "~/components/uiParts/Modal";
import { Paper } from "~/components/uiParts/Paper";
import { Typography } from "~/components/uiParts/Typography";
import { UserIcon } from "~/components/uiParts/UserIcon";

type Props = {
  open: boolean;
  onCancel: () => void;
  profileIconImageUrl?: string;
  username: string;
  position?: string;
  contentText: string;
};

export const MessageModal: FC<Props> = ({
  open,
  onCancel,
  profileIconImageUrl,
  username,
  position,
  contentText,
}) => {
  const Content = (
    <Paper isShowBgImage border="primary">
      <StyledDialogContent>
        <Box pt="16" textAlign="center">
          <UserIcon
            circular
            profileIconImageUrl={profileIconImageUrl}
            username={username}
            size="medium"
            borderColor="primary"
          />
          <Box height="8px" />
          <Typography variant="body2" bold color="textSecondary">
            {username}
          </Typography>
          <Box height="2px" />
          <Typography variant="body2" color="textSecondary">
            {position}
          </Typography>
          <Box height="16px" />
          <Linkify
            componentDecorator={(decoratedAdminef, decoratedText) => (
              <StyledAnchor target="blank" href={decoratedAdminef} key={decoratedText}>
                {decoratedText}
              </StyledAnchor>
            )}
          >
            <Typography align="left">{contentText}</Typography>
          </Linkify>
        </Box>
      </StyledDialogContent>
    </Paper>
  );

  return <Modal open={open} content={Content} onCancel={onCancel} isSpMode />;
};

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    margin: 0 auto;
  }
`;

const StyledAnchor = styled.a`
  word-break: break-all;
`;
