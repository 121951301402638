import { ErrorStatus } from "@onn/common";
import { useCallback, useState } from "react";

import { InputValue } from "~/hooks/employee";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

export const useCheckInputValues = () => {
  const [isLoading, setIsLoading] = useState(false);

  const checkInputValues = useCallback(async (inputValues: InputValue[]): Promise<ErrorStatus> => {
    setIsLoading(true);
    return await EmployeeUseCase.checkValidation(inputValues).finally(() => setIsLoading(false));
  }, []);

  return { isLoading, checkInputValues };
};
