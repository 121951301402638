import { Box, Fade } from "@material-ui/core";
import { isAfter } from "date-fns";
import React, { FC, useMemo, useState, useCallback } from "react";
import styled from "styled-components";

import {
  FileThumbnail,
  UserIcon,
  Loading,
  Typography,
  TextContext,
  Tooltip,
  FileViewer,
} from "~/components/uiParts";

type AttachedFile = { fileName: string; src: string; path: string };

type Props = {
  title?: string;
  text: string;
  createdUser?: {
    name: string;
    profileIconImageUrl?: string;
    deleted?: boolean;
  };
  createdAt: string;
  updatedAt: string;
  attachedFilesLoading?: boolean;
} & AllOrNothing<{
  attachedFiles: AttachedFile[];
  onDeleteFile: (filePath: string) => void;
  onDownloadFile: (url: string) => void;
}>;

export const MemoPreview: FC<Props> = ({
  title,
  text,
  createdUser,
  attachedFiles,
  attachedFilesLoading,
  createdAt,
  updatedAt,
  onDeleteFile,
  onDownloadFile,
}) => {
  const [previewFileIndex, setPreviewFileIndex] = useState(-1);

  const handleOpenFileViewer = useCallback(
    (fileIndex: number) => setPreviewFileIndex(fileIndex),
    []
  );

  const handleCloseFileViewer = useCallback(() => setPreviewFileIndex(-1), []);

  const handlePreviewPrevFile = useCallback(
    (attachedFilesLength: number) => {
      if (previewFileIndex === 0) {
        setPreviewFileIndex(attachedFilesLength - 1);
        return;
      }

      setPreviewFileIndex((prev) => prev - 1);
    },
    [previewFileIndex]
  );

  const handlePreviewNextFile = useCallback(
    (attachedFilesLength: number) => {
      if (previewFileIndex === attachedFilesLength - 1) {
        setPreviewFileIndex(0);
        return;
      }

      setPreviewFileIndex((prev) => prev + 1);
    },
    [previewFileIndex]
  );

  const handleDeleteFile = useCallback(
    (path: string) => {
      if (onDeleteFile) {
        handleCloseFileViewer();
        onDeleteFile(path);
      }
    },
    [onDeleteFile, handleCloseFileViewer]
  );

  const displayCreatorName = useMemo(() => {
    return createdUser?.name ? createdUser.name : "ユーザーが取得できませんでした";
  }, [createdUser]);

  const edited = useMemo(
    () => isAfter(new Date(updatedAt), new Date(createdAt)),
    [createdAt, updatedAt]
  );

  return (
    <>
      <Box mb={2} display="flex">
        <UserIcon
          username={displayCreatorName}
          profileIconImageUrl={createdUser?.deleted ? undefined : createdUser?.profileIconImageUrl}
          size="small"
          circular={!createdUser?.name}
        />
        <Box ml={1} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography variant="body2" bold>
            {displayCreatorName}
          </Typography>
        </Box>
      </Box>
      {title ? (
        <Box mb={2} display="flex">
          <Typography variant="body2" noWrap>
            {title}
          </Typography>
        </Box>
      ) : null}
      <TextContext padding={2} isShowBg={true}>
        {text}
        {edited ? (
          <Tooltip arrow title={updatedAt} placement="bottom">
            <StyledSmall>(edited)</StyledSmall>
          </Tooltip>
        ) : null}
      </TextContext>
      {!attachedFilesLoading ? (
        attachedFiles?.length ? (
          <>
            {/* loadingがtrue -> falseに切り替わったタイミングで、一瞬だけflexアイテムが縦に並んでしまっていたので、fade-inで誤魔化してる */}
            <Fade in timeout={500}>
              <Box mt="24px" display="flex" flexWrap="wrap" gridGap="16px">
                {attachedFiles.map((file, i) => (
                  <Box key={file.src} width={180} height={216}>
                    <FileThumbnail
                      src={file.src}
                      fileName={file.fileName}
                      filePath={file.path}
                      isDisplayActionContainer
                      onPreviewFile={() => handleOpenFileViewer(i)}
                      onDeleteFile={onDeleteFile}
                      onDownloadFile={onDownloadFile}
                    />
                  </Box>
                ))}
              </Box>
            </Fade>
            {attachedFiles[previewFileIndex] ? (
              <FileViewer
                src={(attachedFiles[previewFileIndex] as (typeof attachedFiles)[number]).src}
                fileName={
                  (attachedFiles[previewFileIndex] as (typeof attachedFiles)[number]).fileName
                }
                filePath={(attachedFiles[previewFileIndex] as (typeof attachedFiles)[number]).path}
                pagingEnabled={attachedFiles.length > 1}
                onDownload={onDownloadFile}
                onDeleteFile={handleDeleteFile}
                onClose={handleCloseFileViewer}
                onClickNext={() => handlePreviewNextFile(attachedFiles.length)}
                onClickPrev={() => handlePreviewPrevFile(attachedFiles.length)}
              />
            ) : null}
          </>
        ) : null
      ) : (
        <Box mt={3} mb={3}>
          <Loading size="small" />
        </Box>
      )}
    </>
  );
};

const StyledSmall = styled.small`
  display: inline-block;
  margin-left: ${(props) => props.theme.spacing(0.5)}px;
  font-size: 10px;
  word-break: break-word;
  color: ${(props) => props.theme.palette.text.secondary};
`;
