import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Paper, UserIcon, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  newHireName: string;
  mentorProfileIconImageUrl?: string;
  mentorName: string;
  isActive: boolean;
};

export const MentorDecisionNotifyPaper: FC<Props> = (props) => {
  const { newHireName, mentorProfileIconImageUrl, mentorName, isActive } = props;

  return (
    <StyledPaper isShowBgImage $isActive={isActive}>
      <Box textAlign="center">
        <Box mb={3}>
          <Box mb={1}>
            <UserIcon
              username={mentorName}
              profileIconImageUrl={mentorProfileIconImageUrl}
              size="medium"
              borderColor="primary"
            />
          </Box>
          <Typography variant="body2" bold>
            {mentorName}
          </Typography>
        </Box>
        <Box lineHeight={2}>
          <Typography variant="body2">
            {`${newHireName}さんの入社後サポートを主に担当するバディが${mentorName}さんに決定しました🎉`}
            <br />
            {`バディ以外にもサポートメンバーを追加することができるので\nバディをはじめ、管理者やサポートメンバーを含むチームで${newHireName}さんを歓迎しサポートしていきましょう！`}
          </Typography>
        </Box>
      </Box>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;
