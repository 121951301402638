import React, { FC } from "react";

export const GripHorizontal: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(12250 -3212)">
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12250 3212)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12250 3219)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12243 3212)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12243 3219)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12236 3219)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12236 3212)" />
      </g>
    </svg>
  );
};
