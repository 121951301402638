import React from "react";

export const GoBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(24 24) rotate(180)">
        <path d="M8.6,7.4,10,6l6,6-6,6L8.6,16.6,13.2,12Z" opacity="0.54" stroke="none" />
      </g>
    </svg>
  );
};
