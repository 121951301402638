import { Box, DialogContent } from "@material-ui/core";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  profileIconImageUrl?: string;
  username: string;
};

export const DeleteMemberModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  profileIconImageUrl,
  username,
}) => {
  const [deleting, setDeleting] = useState(false);

  const handleSubmit = useCallback(async () => {
    setDeleting(true);
    await onSubmit()
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setDeleting(false);
      });
  }, [onCancel, onSubmit]);

  const Content = (
    <StyledDialogContent>
      <StyledBox>
        <UserIcon
          profileIconImageUrl={profileIconImageUrl}
          username={username}
          size="medium"
          borderColor="primary"
        />
        <Box height="8px" />
        <Typography variant="body2" bold noWrap>
          {username}
        </Typography>
      </StyledBox>
      <StyledBox>
        <Typography variant="body1" color="textSecondary">
          アカウントを削除しますか？
        </Typography>
        <Typography variant="caption" color="textSecondary">
          アカウントを削除すると対象アカウントは利用できなくなります。
        </Typography>
      </StyledBox>
    </StyledDialogContent>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={deleting}
      >
        {deleting ? "削除中" : "削除"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="アカウント削除"
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    margin: 0 auto;
  }
`;

const StyledBox = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
