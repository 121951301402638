import { Box } from "@material-ui/core";
import { convertBytesToSize } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  onClick: () => void;
  isPdf?: boolean;
  name: string;
  byte: number;
  canDownload?: boolean;
};

export const FileListItem: FC<Props> = ({ onClick, isPdf, name, byte, canDownload }) => {
  return (
    <StyledBox
      onClick={onClick}
      display="flex"
      alignItems="center"
      overflow="hidden"
      py="16px"
      px="24px"
    >
      <Box>
        {isPdf ? (
          <Icon size="md" icon="filePdf" color="lightGrey" />
        ) : (
          <Icon size="md" icon="file" color="lightGrey" />
        )}
      </Box>
      <Box display="flex" flexDirection="column" ml="16px">
        <Typography variant="caption">{name}</Typography>
        <StyledTypography variant="caption" color="textSecondary">
          {convertBytesToSize(byte)}
        </StyledTypography>
      </Box>
      <Box ml="auto">
        {canDownload ? (
          <Icon size="md" icon="download" color="primary" />
        ) : (
          <Icon size="sm" icon="arrowRight" color="primary" />
        )}
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  cursor: pointer;
  border-radius: 15px;
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;
