import React, { FC } from "react";
import styled from "styled-components";

import { Loading } from "../Loading";

import { useImageOriginalSize } from "~/hooks/shared/useImageOriginalSize";

const VIEWER_HEADER_HEIGHT = 64;
const PAGING_BUTTONS_WIDTH = 96;

type Props = {
  src: string;
  fileName: string;
  pagingEnabled: boolean;
};

// NOTE: 画像のサイズと画面サイズを比べて小さい方を表示する
const getImageWidth = (originalSizeWidth: number, pagingEnabled: boolean) => {
  const maxWidth = window.innerWidth - (pagingEnabled ? PAGING_BUTTONS_WIDTH : 0);

  return originalSizeWidth < maxWidth ? originalSizeWidth : maxWidth;
};

// NOTE: 画像のサイズと画面サイズを比べて小さい方を表示する
const getImageHeight = (originalSizeHeight: number) => {
  const maxHeight = window.innerHeight - VIEWER_HEADER_HEIGHT;

  return originalSizeHeight < maxHeight ? originalSizeHeight : maxHeight;
};

export const PreviewImage: FC<Props> = ({ src, fileName, pagingEnabled }) => {
  const originalSize = useImageOriginalSize(src);

  if (!originalSize) return <Loading size="large" />;

  return (
    <StyledImage
      width={getImageWidth(originalSize.width, pagingEnabled)}
      height={getImageHeight(originalSize.height)}
      src={src}
      alt={fileName}
    />
  );
};

const StyledImage = styled.img`
  user-select: none;
  object-fit: contain;
`;
