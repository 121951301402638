const units = ["bytes", "KB", "MB", "GB"];

/**
 * byte を渡して変換した文字列を返す。
 * @param {number} bytes
 * @param {number} index
 * @returns {string} 変換後の文字列。小数点第二以下を切り捨てる
 * @example 1024 -> 1KB
 */
export const convertBytesToSize = (bytes: number, index = 0): string => {
  if (bytes < 1024 || index === units.length - 1) {
    return `${bytes}${units[index]}`;
  }

  return convertBytesToSize(Math.floor((bytes / 1024) * 10) / 10, index + 1);
};
