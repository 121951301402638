import { TenantSettings } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";

/**
 * tenantIdをもとにtenantSettings
 * @param tenantId tenantのid
 */
export const useTenantSettingsByTenantId = (
  tenantId?: string
): SWRResponse<TenantSettings, Error> => {
  const keys = tenantId ? ["tenant-settings", tenantId] : null;

  return useSWR(keys, ([_key, tenantId]: [string, string]) => TenantSettingsUseCase.get(tenantId), {
    revalidateOnFocus: true,
  });
};
