import { TenantSettings, SelectableFeatures, Message } from "@onn/common";

import { TenantSettingsRepository } from "~/infrastructure/api/tenantSettingsRepository";

export interface ITenantSettingsRepository {
  get(tenantId: string): Promise<TenantSettings>;
  updateFeature(tenantId: string, feature: Record<SelectableFeatures, boolean>): Promise<void>;
  updateMessage(tenantId: string, messages: Message): Promise<void>;
  update(tenantId: string, updates: Partial<TenantSettings>): Promise<void>;
  newUpdate(id: string, updates: Partial<TenantSettings>): Promise<void>;
}

export const factory = {
  tenantSettingsRepository: (): ITenantSettingsRepository => {
    return new TenantSettingsRepository();
  },
};
