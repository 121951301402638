import { Grid, Box } from "@material-ui/core";
import { SelectableFeatures } from "@onn/common";
import React, { FC, useEffect, useRef, useState, RefObject } from "react";
import styled from "styled-components";

import { InvitationMessageSettings } from "./InvitationMessageSettings";

import { Typography, Paper, TextContext, Switch, AdminContentWrapper } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useQuery, useScrollToTargetElement, useSnackbar } from "~/hooks/shared";
import { useTenantSettings, useUpdateTenantSettings } from "~/hooks/tenantSetting";
import customInvitation from "~/images/custom-invitation.png";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";
import { captureException } from "~/util";

const fileApiAdapter = new FileAPIAdapter({ bucketType: "public" });

const AUTO_SCROLL_TO_CONTENT_Y_OFFSET = 20;
const SectionIds = {
  INVITATION_MESSAGE: "invitationMessage",
} as const;
type SectionIds = (typeof SectionIds)[keyof typeof SectionIds];

export const BasicTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const { tenantSettings, mutateTenantSettings } = useTenantSettings();
  const { isLoading: isLoadingUpdateTenantSettings, updateTenantSettings } =
    useUpdateTenantSettings();

  const [features, setFeatures] = useState<{ [key in SelectableFeatures]: boolean }>(
    tenantSettings.features
  );
  const [editingSections, setEditingSections] = useState<Set<SectionIds>>(new Set());

  const invitationMessageRef = useRef<HTMLDivElement>(null);
  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();

  const { query } = useQuery();

  const target = query.get("target");

  useEffect(() => {
    switch (target) {
      case "invitationMessage": {
        setScrollTargetRef(invitationMessageRef);
        break;
      }
    }
  }, [target]);

  useEffect(() => {
    setFeatures(tenantSettings.features);
  }, [tenantSettings]);

  const handleSwitchFeature = async (
    newObject: Partial<{ [key in SelectableFeatures]: boolean }>
  ) => {
    const newFeatures = { ...tenantSettings.features, ...newObject };
    setFeatures(newFeatures);

    await TenantSettingsUseCase.updateFeature(currentUser.tenantId, newFeatures);
    mutateTenantSettings();
  };

  const handleClickOptionSettingButton = (sectionId: SectionIds) => {
    setEditingSections((prevState) => new Set(prevState.add(sectionId)));
  };

  const handleCancelSettingButton = (sectionId: SectionIds) => {
    setEditingSections((prevState) => {
      prevState.delete(sectionId);
      return new Set(prevState);
    });
  };

  const handleSubmitInvitationMessageSettings = async (
    invitationMessage: { iconPath?: string; fullName: string; content: string },
    inputIcon?: File
  ) => {
    try {
      if (invitationMessage.iconPath && inputIcon) {
        await fileApiAdapter.upload({ path: invitationMessage.iconPath, file: inputIcon });
      }

      await updateTenantSettings(currentUser.tenantId, { invitationMessage });
      mutateTenantSettings();
      enqueueSnackbar("変更を保存しました", { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar("保存に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "BasicTab:handleSubmitInvitationMessageSettings" },
      });
    } finally {
      handleCancelSettingButton(SectionIds.INVITATION_MESSAGE);
    }
  };

  useScrollToTargetElement({
    enabled: !!scrollTargetRef,
    targetRef: scrollTargetRef,
    scrollYOffset: AUTO_SCROLL_TO_CONTENT_Y_OFFSET,
  });

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={3}>
            <Box position="sticky" top="20px">
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(invitationMessageRef)}
                $isActive={scrollTargetRef === invitationMessageRef}
              >
                招待メッセージ設定
              </StyledSideTypography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} ref={invitationMessageRef}>
              <StyledTextContext padding={2} isShowBg bold>
                招待メッセージ設定
              </StyledTextContext>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <Box px={2} pt={4}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" bold>
                      入社者招待カスタムメッセージ
                    </Typography>
                    <Switch
                      checked={!!features.customInvitationMessage}
                      onChange={() =>
                        handleSwitchFeature({
                          [SelectableFeatures.CUSTOM_INVITATION_MESSAGE]:
                            !features.customInvitationMessage,
                        })
                      }
                      color="primary"
                    />
                  </Box>
                  <StyledTypography variant="caption">
                    {`入社者に送られる招待メールに、案内を追加することができます。\nどんな目的で招待を送っているのかを簡潔に伝えて、入社者への説明を自動化しましょう。`}
                  </StyledTypography>
                </Box>
                {!!features.customInvitationMessage && (
                  <Box px="16px" pt="32px">
                    <InvitationMessageSettings
                      invitationMessage={tenantSettings.invitationMessage}
                      editing={editingSections.has(SectionIds.INVITATION_MESSAGE)}
                      updating={isLoadingUpdateTenantSettings}
                      onEdit={() => handleClickOptionSettingButton(SectionIds.INVITATION_MESSAGE)}
                      onCancel={() => handleCancelSettingButton(SectionIds.INVITATION_MESSAGE)}
                      onSubmit={handleSubmitInvitationMessageSettings}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box pt={4}>
                  <img width="100%" src={customInvitation} alt="customInvitation" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </AdminContentWrapper>
  );
};

const StyledSideTypography = styled(Typography)<{ $isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.$isActive &&
    `&.MuiTypography-root {
        color: ${props.theme.palette.primary.main};
        font-weight: bold;
    }`}
`;

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
