import { Menu, MenuItem } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  open: boolean;
  anchorElement: HTMLElement | null;
  onClickEditMemo: () => void;
  onClickDeleteMemo: () => void;
  onClickCopyShareLink: () => void;
  onClose: () => void;
};

export const MemoItemMenu: FC<Props> = ({
  open,
  anchorElement,
  onClickEditMemo,
  onClickDeleteMemo,
  onClickCopyShareLink,
  onClose,
}) => {
  return (
    <Menu
      open={open}
      anchorEl={anchorElement}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}
    >
      <MenuItem onClick={onClickEditMemo}>
        <Typography variant="body2">メモを編集</Typography>
      </MenuItem>
      <MenuItem onClick={onClickDeleteMemo}>
        <Typography variant="body2">メモを削除</Typography>
      </MenuItem>
      <MenuItem onClick={onClickCopyShareLink}>
        <Typography variant="body2">共有リンクをコピー</Typography>
      </MenuItem>
    </Menu>
  );
};
