import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { joinQuery } from "~/util";

/**
 * クエリストリングtargetにvalueをsetする関数を提供するhooks
 */
export const useSetQueryString = () => {
  const navigate = useNavigate();
  const { search, hash, pathname } = useLocation();

  /**
   * クエリストリングtargetにvalueをsetする
   * @param obj { target: value } valueがnullやundefinedのとき、クエリストリングは削除されます
   */
  const setQueryString = useCallback(
    (obj: Record<string, string | string[] | undefined | null>): void => {
      navigate(`${pathname}?${joinQuery(obj, search)}${hash}`, { replace: true });
    },
    [hash, navigate, pathname, search]
  );

  return { setQueryString };
};
