import { ContactRoomUnreadCountInfo } from "@onn/common";
import { where, query, Timestamp, onSnapshot, collectionGroup } from "firebase/firestore";
import { chunk } from "lodash";

import { firestore } from "~/config/firebase";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10; // 配列処理で同一クエリにおいて実行できる最大長

type ContactRoomUnreadCountInfoForDB = Omit<
  ExcludeMethods<ContactRoomUnreadCountInfo>,
  "updatedAt"
> & {
  updatedAt: Timestamp;
};

export class UnreadCountInfoRepository {
  private dbToObject({
    updatedAt,
    ...rest
  }: ContactRoomUnreadCountInfoForDB): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      ...rest,
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }

  listenUnreadCountInfo({
    contactRoomIds,
    employeeId,
    tenantId,
    onChangedUnreadCountInfo,
  }: {
    contactRoomIds: string[];
    employeeId: string;
    tenantId: string;
    onChangedUnreadCountInfo: (contactRoomUnreadCountInfo: ContactRoomUnreadCountInfo) => void;
  }) {
    return chunk(contactRoomIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map((chunk) => {
      const q = query(
        collectionGroup(firestore, "unreadCountInfo"),
        where("contactRoomId", "in", chunk),
        where("employeeId", "==", employeeId),
        where("tenantId", "==", tenantId)
      );
      return onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            onChangedUnreadCountInfo(
              this.dbToObject(change.doc.data() as ContactRoomUnreadCountInfoForDB)
            );
          }
        });
      });
    });
  }
}
