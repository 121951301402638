import { Box, Radio } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";
import theme from "~/config/theme";

const SIZE_RADIO_BUTTON = 24; // ラジオボタンのサイズ
const SIZE_BORDER = 40; // 繋がる線のサイズ

type CustomProps = {
  radioLength: number;
  name?: string;
  value?: number;
  onChangeRating?: (event: React.ChangeEvent<HTMLElement>, newValue: number) => void;
  disableEffect?: boolean;
  hasLabel?: boolean;
  captions?: string[];
};

type Props = ComponentProps<typeof Radio> & CustomProps;

export const RatingRadioGroup: FC<Props> = ({
  radioLength,
  name,
  value,
  onChangeRating,
  disableEffect,
  hasLabel,
  captions,
  ...props
}) => {
  const number = [...Array(radioLength)].map((_, i) => i + 1);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRating && onChangeRating(event, parseInt(event.target.value));
  };

  return (
    <StyleBox>
      <StyleBoxButtonGroup>
        {number.map((v, index) => {
          return (
            <Box key={`RatingRadio__${index}`}>
              <StyledRadioLabel $hasLabel={Boolean(hasLabel)} variant="body2">
                {v}
              </StyledRadioLabel>
              <Box display="flex" alignItems="center">
                <StyledRadio
                  {...props}
                  checked={value === v}
                  onChange={handleChange}
                  value={v}
                  color="primary"
                  name={name}
                  disableRipple={disableEffect}
                  icon={<RadioCircle component="span" value={value} />}
                  checkedIcon={
                    <RadioCircle component="span" bgcolor={theme.palette.primary.main} $isChecked />
                  }
                />
                {index !== number.length - 1 && <StyledBorder />}
              </Box>
            </Box>
          );
        })}
      </StyleBoxButtonGroup>
      {captions && (
        <StyleBoxCaption $radioLength={radioLength} px={0.5}>
          {captions.map((caption, index) => (
            <Box key={`StyleBoxCaption__${index}`} textAlign="center">
              {caption.split(`\n`).map((v, i) => (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  key={`Caption-${v}__${i}`}
                  display="block"
                >
                  {v}
                </Typography>
              ))}
            </Box>
          ))}
        </StyleBoxCaption>
      )}
    </StyleBox>
  );
};

const StyleBox = styled(Box)`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyleBoxButtonGroup = styled(Box)`
  display: flex;
  padding: 0 24px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0;
  }
`;

const StyledBorder = styled.hr`
  width: ${SIZE_BORDER}px;
  height: 3px;
  background-color: ${(props) => props.theme.palette.primary.main};
  opacity: 0.3;
`;

const StyledRadio = styled(Radio)<Partial<Props>>`
  &.MuiRadio-root {
    margin-right: 0px;
    padding: 0px;
  }
  ${(props) => props.disableEffect && `pointer-events:none;`}
`;

const RadioCircle = styled(Box)<{ $isChecked?: boolean; value?: number }>`
  &.MuiBox-root {
    width: ${SIZE_RADIO_BUTTON}px;
    height: ${SIZE_RADIO_BUTTON}px;
    border-radius: 50%;
    border: 3px solid;
    color: ${(props) => props.theme.palette.primary.main};
    ${(props) => props.value && !props.$isChecked && `opacity: 0.3;`}
  }
`;

const StyledRadioLabel = styled(Typography)<{ $hasLabel: boolean }>`
  width: 24px;
  padding-bottom: 5px;
  text-align: center;
  display: ${(props) => (props.$hasLabel ? "block" : "none")};
`;

const StyleBoxCaption = styled(Box)<{ $radioLength: number }>`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  width: ${(props) =>
    // キャプションの幅は全てのラジオの幅＋全ての繋がる線の幅＋パッディング50pxです。
    props.$radioLength * SIZE_RADIO_BUTTON + (props.$radioLength - 1) * SIZE_BORDER + 50}px;
`;
