/**
 * filePath からそのファイルの名前と拡張子を返す変数
 * @param filePath
 * @returns {name:string,extension:string}
 */
export const getNameAndExtensionFromFilePath = (
  filePath: string
): {
  name: string;
  extension?: string;
} => {
  if (!filePath.match(/\./)) {
    return {
      name: filePath,
    };
  }

  const nameFilePaths = filePath.match(/([^/]*)\./);
  const extensionFilePaths = filePath.match(/[^.]+$/);

  return {
    name: nameFilePaths ? (nameFilePaths[1] as (typeof nameFilePaths)[number]) : "",
    extension: extensionFilePaths ? extensionFilePaths[0] : undefined,
  };
};
