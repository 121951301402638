import { Maintenance } from "@onn/common";
import {
  DocumentData,
  getDocs,
  query,
  collection,
  CollectionReference,
  orderBy,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IMaintenanceRepository } from "~/service/repository/iMaintenanceRepository";

const COLLECTION_NAME = "maintenances";

const convertToMaintenance = (data: DocumentData): Maintenance => {
  return {
    isMaintenance: data.isMaintenance,
    title: data.title,
    message: data.message,
    whiteIpList: data.whiteIpList,
  };
};

export class MaintenanceRepository implements IMaintenanceRepository {
  async get(): Promise<Maintenance> {
    return await getDocs(query(this.collection(), orderBy("timestamp", "desc"))).then(
      (snapshot) => {
        return convertToMaintenance((snapshot.docs[0] as (typeof snapshot.docs)[number]).data());
      }
    );
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}
