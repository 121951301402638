import { ContactMessageDraft } from "@onn/common";
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { ContactMessageDraftRepository } from "~/infrastructure/api/contactMessageDraftRepository";
import { GetContactMessageDraftsUseCase } from "~/service/usecases/contactMessage/GetContactMessageDraftsUseCase";
import { SaveContactMessageDraftUseCase } from "~/service/usecases/contactMessage/SaveContactMessageDraftUseCase";

const getContactMessageDraftUseCase = new GetContactMessageDraftsUseCase(
  new ContactMessageDraftRepository()
);

const saveContactMessageDraftUseCase = new SaveContactMessageDraftUseCase(
  new ContactMessageDraftRepository()
);

export const useContactMessageDraftByCreatedUserId = (
  createdUserId: string
): {
  isLoading: boolean;
  contactMessageDrafts: ContactMessageDraft[];
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  refetchContactMessageDraft: () => void;
} => {
  const keys = ["getContactMessageDraftUseCase", createdUserId];

  const { currentUser } = useCurrentUser();

  const [contactMessageDrafts, setContactMessageDrafts] = useState<ContactMessageDraft[]>([]);

  const { data, isLoading, mutate } = useSWR(keys, ([_key, createdUserId]: [string, string]) =>
    getContactMessageDraftUseCase.execute({ createdUserId, tenantId: currentUser.tenantId })
  );

  useEffect(() => {
    if (data != null && data.length !== 0) setContactMessageDrafts(data);
  }, [data]);

  const saveContactMessageDraft = useCallback(
    (contactMessageDraft: ContactMessageDraft) => {
      if (contactMessageDrafts.some((c) => c.id === contactMessageDraft.id)) {
        setContactMessageDrafts(
          contactMessageDrafts.map((c) =>
            c.id === contactMessageDraft.id ? contactMessageDraft : c
          )
        );
      } else {
        setContactMessageDrafts([...contactMessageDrafts, contactMessageDraft]);
      }
      saveContactMessageDraftUseCase.execute(contactMessageDraft);
    },
    [contactMessageDrafts]
  );

  const refetchContactMessageDraft = useCallback(async () => {
    const newContactMessageDrafts = await getContactMessageDraftUseCase.execute({
      createdUserId,
      tenantId: currentUser.tenantId,
    });
    mutate((_) => {
      return newContactMessageDrafts;
    }, false);
  }, [createdUserId, currentUser.tenantId, mutate]);

  return {
    isLoading,
    contactMessageDrafts,
    saveContactMessageDraft,
    refetchContactMessageDraft,
  };
};
