import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { IconButton } from "~/components/uiParts/IconButton";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  name: string;
  extension?: string;
  onClickDeleteButton?: () => void;
};

export const FileTag: FC<Props> = ({ name, extension, onClickDeleteButton }) => {
  return (
    <Box display="flex" alignItems="center">
      <StyledBody>
        <Box display="flex" maxWidth={280}>
          <Typography display="block" variant="body2" noWrap>
            {name}
          </Typography>
          {extension && (
            <Typography display="block" variant="body2">
              {`.${extension}`}
            </Typography>
          )}
        </Box>
      </StyledBody>
      {onClickDeleteButton ? (
        <IconButton icon="close" color="darkGrey" size="sm" onClick={onClickDeleteButton} />
      ) : null}
    </Box>
  );
};

const StyledBody = styled(Box)`
  max-width: 100%;
  padding: 4px 12px;
  background-color: ${(props) => props.theme.palette.grey[400]};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;
