import { Box, Menu } from "@material-ui/core";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState, FC } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts/Button";
import { Checkbox } from "~/components/uiParts/Checkbox";
import { FormControlLabel } from "~/components/uiParts/FormControlLabel";
import { Icon } from "~/components/uiParts/Icon";
import { IconButton } from "~/components/uiParts/IconButton";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  selectableDateArray: DateString[];
  selectedDateArray: DateString[];
  onChange: (status: DateString[]) => void;
};

export const DateFilter: FC<Props> = ({ selectableDateArray, selectedDateArray, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const labelText = useMemo(() => {
    if (isEmpty(selectedDateArray) || selectedDateArray.length === selectableDateArray.length)
      return "全て";
    return selectedDateArray
      .map((selectedDate) => format(new Date(selectedDate), "yyyy/MM/dd"))
      .join(", ");
  }, [selectableDateArray.length, selectedDateArray]);

  const handleChange = useCallback(
    (date: DateString) => {
      if (selectedDateArray.includes(date)) {
        return onChange(selectedDateArray.filter((v) => v !== date));
      } else {
        return onChange([...selectedDateArray, date]);
      }
    },
    [onChange, selectedDateArray]
  );

  return (
    <>
      <Box position="relative">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon
              icon="filter"
              size="sm"
              color="grey"
              $isEmpty={isEmpty(selectedDateArray)}
            />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedDateArray) && (
          <StyledIconButton
            icon="close"
            size="sm"
            color="grey"
            onClick={(e) => {
              e.stopPropagation();
              onChange([]);
            }}
          />
        )}
      </Box>
      <Menu
        key="statusFilter"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: -8, horizontal: "right" }}
      >
        <Box py="16px" width="240px" display="flex" flexDirection="column">
          <Box
            px="24px"
            maxHeight={300}
            overflow="auto"
            display="flex"
            flexDirection="column"
            gridGap="8px"
          >
            {selectableDateArray.map((value, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedDateArray.includes(value)}
                      onChange={() => handleChange(value)}
                      name={value}
                      value={value}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {format(new Date(value), "yyyy/MM/dd")}
                    </Typography>
                  }
                />
              );
            })}
          </Box>
          {!isEmpty(selectedDateArray) && (
            <Box mt="8px" px="24px">
              <StyledTypography variant="body2" color="primary" onClick={() => onChange([])}>
                全てクリア
              </StyledTypography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
