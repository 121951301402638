import React, { FC } from "react";

export const FilePdf: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 26.998">
      <path
        stroke="none"
        d="M-14360.208-6259a1.5,1.5,0,0,1-1.5-1.5v-24a1.5,1.5,0,0,1,1.5-1.5h16.5c-.013.046,6.038,6.02,6,6v19.5a1.5,1.5,0,0,1-1.5,1.5Zm1.5-3h18v-15l-1.738,0-2.76,0a1.254,1.254,0,0,1-.173-.011,1.491,1.491,0,0,1-1.327-1.5v-.251c0-.165,0-.4,0-.682v-2.062c0-.521,0-1.041,0-1.5h-12Zm15-18,1.263,0h.481c-.565-.562-1.179-1.168-1.744-1.733Zm-3.322,15v-4.206h3.145l-.008.875h-2.038v.935h1.883v.876h-1.883V-6265Zm-4.595,0v-4.206h1.812a2.485,2.485,0,0,1,1.164.265,1.913,1.913,0,0,1,.79.741,2.1,2.1,0,0,1,.284,1.095,2.1,2.1,0,0,1-.287,1.1,1.921,1.921,0,0,1-.8.744,2.594,2.594,0,0,1-1.191.265Zm1.1-.889h.743a1.077,1.077,0,0,0,.565-.148,1.054,1.054,0,0,0,.384-.427,1.385,1.385,0,0,0,.135-.63,1.351,1.351,0,0,0-.146-.635,1.074,1.074,0,0,0-.406-.43,1.139,1.139,0,0,0-.587-.151h-.688Zm-5.179.889v-4.206h1.849a1.842,1.842,0,0,1,1.246.386,1.367,1.367,0,0,1,.446,1.089,1.485,1.485,0,0,1-.446,1.154,1.754,1.754,0,0,1-1.246.411h-.752V-6265Zm1.1-2.041h.7a.731.731,0,0,0,.52-.167.624.624,0,0,0,.181-.487.6.6,0,0,0-.181-.47.742.742,0,0,0-.52-.165h-.7Z"
        transform="translate(14361.708 6285.999)"
      />
    </svg>
  );
};
