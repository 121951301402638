import React, { FC } from "react";

export const ArrowDropDown: FC = () => {
  return (
    <svg
      className="MuiSvgIcon-root jss230"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      tabIndex={-1}
      data-ga-event-category="material-icons"
      data-ga-event-action="click"
      data-ga-event-label="ArrowDropDown"
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  );
};
