import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";
import logoSquare from "~/images/logo-square.svg";

type Props = {
  text: React.ReactNode;
  actionLabel: string;
  onAction: () => void;
};

export const ActionSnackbar: FC<Props> = ({ text, actionLabel, onAction }) => {
  return (
    <StyledBox py="16px" px="24px" display="flex" justifyContent="center" alignItems="center">
      <StyledIcon height={14} width={14} src={logoSquare} alt="Onnアイコン" />
      <Typography variant="caption" bold noWrap>
        {text}
      </Typography>
      <StyledButton onClick={onAction}>{actionLabel}</StyledButton>
    </StyledBox>
  );
};

const StyledIcon = styled.img`
  margin-right: 8px;
`;

const StyledButton = styled.button`
  background-color: white;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
  margin-left: 40px;
`;

const StyledBox = styled(Box)`
  background-color: white;
  border-radius: 8px;
  font-size: 12px;
  width: 95vw;
  max-width: 645px;
  color: ${(props) => props.theme.palette.grey[400]};
  border: ${(props) => `2px solid ${props.theme.palette.primary.main}`};
`;
