import { useContext, useEffect, useCallback } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

/**
 * v6 でPromptが対応していないことによるワークアラウンド
 * https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 * @param blocker
 * @param when
 */
const useBlocker = (
  blocker: { (tx: { retry: () => void }): void; (arg0: { retry(): void }): void },
  when = true
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { navigator } = useContext(NavigationContext) as any;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: { retry: () => void }) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};
/**
 * タブを閉じたりページをリロードするとき、ブラウザ組み込みの確認ポップアップを表示する
 * @param  message 表示する文章
 * @param  when trueのときにイベントリスナを追加
 */
export const usePrompt = (message: string, when = true) => {
  const blocker = useCallback(
    (tx: { retry: () => void }) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
};

export const usePromptWithoutNavigate = (message: string, when = true) => {
  const blocker = useCallback(() => {
    // eslint-disable-next-line no-alert
    window.alert(message);
  }, [message]);

  useBlocker(blocker, when);
};
