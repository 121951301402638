import { FormControl, Select, FormHelperText, MenuItem, Box } from "@material-ui/core";
import React, { ComponentProps, FC, ReactElement, useMemo } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";

type IconProps = ComponentProps<typeof Icon>;
type Props = Pick<ComponentProps<typeof Select>, "onChange"> & {
  selected: string | number | null;
  menuItems?: {
    value: string | number;
    name: string;
    disabled?: boolean;
    customItemElement?: ReactElement;
  }[];
  errorText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  errorBorder?: boolean; // エラーボーダーのみを表示させたい場合に使用
  labelWhenNoSelected?: string;
  isSmall?: boolean;
} & Partial<Pick<IconProps, "icon">>;

export const SelectForm: FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      selected,
      onChange,
      menuItems,
      errorText,
      fullWidth,
      disabled,
      readOnly,
      icon,
      errorBorder,
      labelWhenNoSelected = "-",
      isSmall = false,
      ...rest
    },
    ref
  ) => {
    const currentMenuItem = useMemo(() => {
      const menuItem = menuItems && menuItems.find((v) => v.value === selected);
      return (
        menuItem?.customItemElement || (
          <Typography variant="body2">
            {(selected && menuItem?.name) || labelWhenNoSelected}
          </Typography>
        )
      );
    }, [menuItems, selected, labelWhenNoSelected]);
    return (
      <FormControl
        variant="outlined"
        error={Boolean(errorText) || errorBorder}
        fullWidth={fullWidth}
        disabled={disabled}
        {...rest}
      >
        <Select
          displayEmpty
          renderValue={() => (
            <Box display="flex">
              {icon && <StyledIcon icon={icon} size="sm" color="grey" />}
              {currentMenuItem}
            </Box>
          )}
          fullWidth
          value={selected}
          onChange={onChange}
          inputProps={{ readOnly, ref }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: isSmall ? "400px" : undefined,
              },
            },
          }}
        >
          {/* menuItemsのときはItem要素はレンダリングしない */}
          {menuItems &&
            menuItems.map((item, index) => (
              <MenuItem value={item.value} key={index} disabled={item.disabled}>
                {item.customItemElement || <Typography variant="body2">{item.name}</Typography>}
              </MenuItem>
            ))}
        </Select>
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    );
  }
);

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  align-self: center;
`;
