import { Comment } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "../employee";

import { CommentUseCase } from "~/service/usecases/commentUseCase";

const commentUseCase = new CommentUseCase();

/**
 * transactionIdsをもとにtransactionに紐づいたcommentsを返すswr
 * @param {string[]} transactionIds
 */
export const useCommentsByTransactionIds = (
  transactionIds?: string[],
  fallbackData?: Comment[]
): SWRResponse<Comment[], Error> => {
  const key = transactionIds ? ["comments", transactionIds] : null;

  const { currentUser } = useCurrentUser();

  return useSWR(
    key,
    ([_key, transactionIds]: [string, string[]]) =>
      commentUseCase.whereByTransactionIds(transactionIds, currentUser.tenantId).then((res) => res),
    { fallbackData }
  );
};
