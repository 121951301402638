import { Library, Content } from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  orderBy,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { v4 } from "uuid";

import { queryOperator } from "./queryOperator";

import { firestore } from "~/config/firebase";
import { ILibraryRepository } from "~/service/repository/iLibraryRepository";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";
import { toFirestoreCompatible } from "~/util/toFirestoreCompatible";

const COLLECTION_NAME = "libraries";

type LibraryForDB = Omit<ConstructorParameters<typeof Library>[0], "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export class LibraryRepository implements ILibraryRepository {
  async create(
    library: Omit<Library, "id" | "createdAt" | "updatedAt" | "contents"> & {
      contents: Omit<Content, "id">[];
    }
  ): Promise<{ libraryId: string }> {
    const id = v4();
    const now = new Date();

    const contentsForDB = library.contents.map((content) => {
      return { ...content, id: v4() };
    });

    const libraryForDB = toFirestoreCompatible({
      ...library,
      id,
      createdAt: now,
      updatedAt: now,
      contents: contentsForDB,
    });

    const ref = this.doc(id);
    await setDoc(ref, libraryForDB);

    return { libraryId: id };
  }

  async findAll(
    tenantId: string,
    { includePrivate }: { includePrivate?: boolean }
  ): Promise<Library[]> {
    if (includePrivate) {
      const { docs } = await getDocs(
        query(this.collection(), where("tenantId", "==", tenantId), orderBy("index", "asc"))
      );
      return docs.map((doc) => this.dbToObject(doc.data() as LibraryForDB));
    } else {
      const { docs } = await getDocs(
        query(
          this.collection(),
          where("isPublic", "==", true),
          where("tenantId", "==", tenantId),
          orderBy("index", "asc")
        )
      );

      return docs.map((doc) => this.dbToObject(doc.data() as LibraryForDB));
    }
  }

  async findById(libraryId: string): Promise<Library> {
    const doc = await getDoc(this.doc(libraryId));

    if (!doc) {
      throw new Error("no library found");
    }

    return this.dbToObject(doc.data() as LibraryForDB);
  }

  async updateLibrary({
    libraryId,
    library,
  }: {
    libraryId: string;
    library: Omit<Library, "contents"> & {
      contents: Array<Omit<Content, "id"> & { id?: string }>;
    };
  }): Promise<void> {
    const now = new Date();

    // id が存在しない = 新規の content のみ uuid を発行する
    const contentsForDB = library.contents.map((content) => {
      return { ...content, id: content.id ? content.id : v4() };
    });

    const libraryForDB = toFirestoreCompatible({
      ...library,
      contents: contentsForDB,
      createdAt: library.createdAt,
      updatedAt: now,
    });

    return await updateDoc(this.doc(libraryId), libraryForDB);
  }

  async deleteById(libraryId: string): Promise<void> {
    return await deleteDoc(this.doc(libraryId));
  }

  /**
   * index の値を調整するための関数
   * @param {minIndex} 最小の対象
   * @param {maxIndex} 最大の対象
   * @param {point} 増減値
   */
  async adjustLibraryIndex({
    minIndex,
    maxIndex,
    point,
  }: {
    minIndex: number;
    maxIndex: number;
    point: number;
  }): Promise<void> {
    const batch = queryOperator.batch();

    // 対象の document を取得する
    const snapshot = await getDocs(
      query(this.collection(), where("index", ">=", minIndex), where("index", "<=", maxIndex))
    );

    // batch 処理を生成する
    snapshot.docs.forEach((doc) => {
      const data = doc.data() as LibraryForDB;
      batch.update(this.doc(data.id), { index: data.index + point });
    });
    batch.commit();
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private dbToObject({ createdAt, updatedAt, ...rest }: LibraryForDB) {
    return new Library({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }
}
