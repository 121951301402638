import { Box, Menu } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Icon, Typography } from "~/components/uiParts";
import {
  FormState,
  filterFormFields,
  formLabelTextMap,
  ToggleState,
} from "~/hooks/contactRoom/useIsCompletedFilterFormState";

type Props = {
  formLabelText: string;
  formState: FormState;
  toggleForm: ToggleState;
};

export const IsCompletedFilter: FC<Props> = React.memo(
  ({ formLabelText, formState, toggleForm }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const button = useMemo(() => {
      return (
        <StyledBox
          display="flex"
          alignItems="center"
          gridGap="8px"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Typography variant="caption" color="textSecondary">
            {formLabelText}
          </Typography>
          <Icon icon="arrowDropDown" size="sm" color="grey" />
        </StyledBox>
      );
    }, [formLabelText]);

    const menuContent = useMemo(() => {
      return (
        <Box py="16px" display="flex" flexDirection="column">
          <Box px="24px" overflow="auto" display="flex" flexDirection="column" gridGap="8px">
            {filterFormFields.map((field) => (
              <FormControlLabel
                key={field}
                control={<Checkbox checked={formState[field]} onChange={() => toggleForm(field)} />}
                label={
                  <Typography variant="body2" color="textSecondary">
                    {formLabelTextMap[field]}
                  </Typography>
                }
              />
            ))}
          </Box>
        </Box>
      );
    }, [formState, toggleForm]);

    return (
      <>
        {button}
        <Menu
          key="isCompletedFilter"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: -8, horizontal: "left" }}
        >
          {menuContent}
        </Menu>
      </>
    );
  }
);

const StyledBox = styled(Box)`
  cursor: pointer;
`;
