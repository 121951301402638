/**
 * 文字列の最後の .hoge のマッチを replaceStr で置換する
 * @param str
 * @param replaceStr
 * @returns {string}
 */
const replaceLastExtension = (str: string, replaceStr: string): string => {
  const lastDotIndex = str.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return str;
  }
  return str.substring(0, lastDotIndex) + replaceStr;
};

/**
 * url.pathname の一つ上の階層までの pathname を返す
 * @param url
 * @returns
 */
const getParentPathname = (url: URL): string => {
  const pathname = url.pathname;
  const pathnameArray = pathname.split("/");

  pathnameArray.pop();
  const parentPathname = pathnameArray.join("/");

  return parentPathname;
};

/**
 * オリジナル画像の URL をリサイズ版画像の URL に変換する
 * @param originalImageUrl オリジナル画像の URL
 * @param width リサイズの幅
 * @param height リサイズの高さ
 * @returns {URL} リサイズ版画像の URL
 */
export const convertOriginalToResizedImageUrl = (
  originalImageUrl: URL,
  width: number,
  height: number
): URL => {
  const nxn = `${width}x${height}`;

  // origin 部分は計算に関係ないので pathname のみ扱う
  let resizedImagePath = originalImageUrl.pathname;

  // eg. /uploads/profile => /uploads/profile/resized
  const dirname = getParentPathname(originalImageUrl);
  const resizedImageDirname = dirname.concat("/resized");
  resizedImagePath = resizedImagePath.replace(dirname, resizedImageDirname);
  // eg. /uploads/profile/resized/hoge.ext => /uploads/profile/resized/hoge_NxN.ext
  const extension = originalImageUrl.pathname.split(".").pop();
  resizedImagePath = replaceLastExtension(resizedImagePath, `_${nxn}.${extension}`);

  return new URL(resizedImagePath, originalImageUrl.origin);
};
