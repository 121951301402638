import { Failure, Result, Success } from "../../shared";

import { IContactRoomUnreadCountInfoSchema, contactRoomUnreadCountInfoSchema } from "./schema";

export class ContactRoomUnreadCountInfo implements IContactRoomUnreadCountInfoSchema {
  private static validator = contactRoomUnreadCountInfoSchema;

  readonly id: string;
  readonly employeeId: string;
  readonly unreadCount: number;
  readonly contactRoomId: string;
  readonly tenantId: string;
  updatedAt: Date;

  constructor(init: ExcludeMethods<ContactRoomUnreadCountInfo>) {
    this.id = init.employeeId; // サブコレクションの更新をしやすくするため、idをemployeeIdにしている
    this.employeeId = init.employeeId;
    this.unreadCount = init.unreadCount;
    this.contactRoomId = init.contactRoomId;
    this.tenantId = init.tenantId;
    this.updatedAt = init.updatedAt;
  }

  static validate(
    data: ExcludeMethods<ContactRoomUnreadCountInfo>
  ): Result<ContactRoomUnreadCountInfo, Error> {
    const result = ContactRoomUnreadCountInfo.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new ContactRoomUnreadCountInfo(data));
  }

  public static create(
    params: Omit<ExcludeMethods<ContactRoomUnreadCountInfo>, "id">
  ): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      id: params.employeeId, // サブコレクションの更新をしやすくするため、idをemployeeIdにしている
      employeeId: params.employeeId,
      unreadCount: params.unreadCount,
      contactRoomId: params.contactRoomId,
      tenantId: params.tenantId,
      updatedAt: params.updatedAt,
    });
  }
}
