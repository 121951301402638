import React, { FC } from "react";

export const CheckCircle: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <defs>
        <style>{`.check_circle_b{fill:#fff;}`}</style>
      </defs>
      <rect width="20" height="20" rx="10" stroke="none" />
      <g transform="translate(3 5)">
        <path
          className="check_circle_b"
          d="M-13977.343-3014.016a1.5,1.5,0,0,1-1.061-.439l-3.537-3.534a1.5,1.5,0,0,1,0-2.123,1.5,1.5,0,0,1,2.123,0l2.475,2.477,5.306-5.305a1.5,1.5,0,0,1,2.119,0,1.5,1.5,0,0,1,0,2.123l-6.365,6.362a1.489,1.489,0,0,1-1.059.439Z"
          transform="translate(13983 3024)"
          stroke="none"
        />
      </g>
    </svg>
  );
};
