import { Memo, TransactionDataType, TransactionStatus } from "../Transaction";

export class MemoTransaction {
  id: string;
  dataType: TransactionDataType.ADD_MEMO;
  contents: Memo;
  tenantId: string;
  employeeId: string;
  status: TransactionStatus.DONE;
  createdAt: Date;
  updatedAt: Date;
  sendAt: Date;
  deleted?: boolean;

  constructor(
    id: string,
    dataType: TransactionDataType.ADD_MEMO,
    contents: Memo,
    tenantId: string,
    employeeId: string,
    status: TransactionStatus.DONE,
    createdAt: Date,
    updatedAt: Date,
    sendAt: Date,
    deleted?: boolean
  ) {
    this.id = id;
    this.dataType = dataType;
    this.contents = contents;
    this.tenantId = tenantId;
    this.employeeId = employeeId;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.sendAt = sendAt;
    this.deleted = deleted;
  }
}
