import { Box } from "@material-ui/core";
import { isAfter } from "date-fns";
import React, { Fragment, useCallback, useEffect, useMemo, useState, FC } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";

import { CommentForm } from "./CommentForm";

import { UserIcon, Divider, Typography, Tooltip } from "~/components/uiParts";

type ContentText = { text: string } & (
  | { type: "plain" }
  | { type: "mention"; user?: { id: string; name: string; deleted?: boolean } }
);

type CommentProps = {
  id: string;
  user?: { id: string; name: string; profileIconImageUrl?: string; deleted?: boolean };
  content: ContentText[];
  currentUserId: string;
  editable: boolean;
  deletable: boolean;
  createdAt: string;
  updatedAt: string;
  suggestedMentionUsers?: { id: string; name: string }[];
  isCloseGapNameAndDate?: boolean;
  onClickDeleteButton: (commentId: string) => void;
  onClickSaveButton: (commentId: string, { content }: { content: string }) => void;
};

export const Comment: FC<CommentProps> = ({
  id,
  user,
  content,
  currentUserId,
  editable,
  deletable,
  createdAt,
  updatedAt,
  suggestedMentionUsers,
  isCloseGapNameAndDate,
  onClickDeleteButton,
  onClickSaveButton,
}: CommentProps) => {
  const [editing, setEditing] = useState(false);
  const [commentFormValue, setCommentFormValue] = useState("");

  const edited = useMemo(
    () => isAfter(new Date(updatedAt), new Date(createdAt)),
    [createdAt, updatedAt]
  );

  const handleClickEditButton = useCallback(() => setEditing(true), []);
  const handleCancelEditButton = useCallback(() => setEditing(false), []);
  const handleClickDeleteButton = useCallback(
    () => onClickDeleteButton(id),
    [onClickDeleteButton, id]
  );
  const handleChangeCommentFormValue = useCallback(
    (value: string) => setCommentFormValue(value),
    []
  );
  const handleClickSaveButton = useCallback(() => {
    setEditing(false);
    onClickSaveButton(id, { content: commentFormValue });
  }, [onClickSaveButton, id, commentFormValue]);

  // 編集モードに切り替えられたタイミングで、文章をテキストエリア上でハイライト表示可能な形式に変換する
  useEffect(() => {
    if (!editing) {
      return;
    }

    const computedValue = content.reduce(
      (acc, current) =>
        current.type === "mention"
          ? acc + `@[${current.user?.name || ""}](${current.user?.id || ""})`
          : acc + current.text,
      ""
    );
    setCommentFormValue(computedValue);
  }, [editing, content]);

  return (
    <StyledListItem>
      <Box width={40} display="flex" flexDirection="column" alignItems="center" mr={1}>
        <UserIcon
          circular
          size="small"
          username={user?.name || ""}
          profileIconImageUrl={!user?.deleted ? user?.profileIconImageUrl : undefined}
        />
      </Box>
      {/* 要素の中身によって幅が広がるのを防ぐため、親要素の幅 - (アイコン部分の領域40px + 余白の8px) で横幅を固定する} */}
      <Box width="calc(100% - 48px)">
        <Box
          display="flex"
          justifyContent={isCloseGapNameAndDate ? "start" : "space-between"}
          mb={1.5}
        >
          <Typography variant="body2" bold>
            {user?.name}
          </Typography>
          {isCloseGapNameAndDate && <Box display="inline-block" width="16px" />}
          <StyledTime>{createdAt}</StyledTime>
        </Box>
        {editing ? (
          <CommentForm
            autoFocus
            value={commentFormValue}
            textAreaMinHeight={120}
            textAreaMaxHeight={120}
            suggestedMentionUsers={suggestedMentionUsers}
            submitButtonLabel="保存"
            onChange={handleChangeCommentFormValue}
            onSubmit={handleClickSaveButton}
            onCancel={handleCancelEditButton}
          />
        ) : (
          <>
            <StyledParagraph>
              {content.map((fragment, i) =>
                fragment.type === "mention" ? (
                  <StyledEm
                    key={i}
                    mentionedToSelf={fragment.user?.id === currentUserId}
                    mentionedToDeletedUser={Boolean(fragment.user?.deleted)}
                  >
                    {fragment.text}
                  </StyledEm>
                ) : (
                  <Fragment key={i}>
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={`${i}--${key}`}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {fragment.text}
                    </Linkify>
                  </Fragment>
                )
              )}
              {edited ? (
                <Tooltip arrow title={updatedAt} placement="bottom">
                  <StyledSmall>(edited)</StyledSmall>
                </Tooltip>
              ) : null}
            </StyledParagraph>
            <Box display="flex" mt={1}>
              {editable ? (
                <StyledButton onClick={handleClickEditButton}>
                  <Typography variant="caption" color="textSecondary">
                    Edit
                  </Typography>
                </StyledButton>
              ) : null}
              {editable && deletable ? <Divider orientation="vertical" margin={4} /> : null}
              {deletable ? (
                <StyledButton onClick={handleClickDeleteButton}>
                  <Typography variant="caption" color="textSecondary">
                    Delete
                  </Typography>
                </StyledButton>
              ) : null}
            </Box>
          </>
        )}
      </Box>
    </StyledListItem>
  );
};

const StyledListItem = styled.li`
  padding: 0px 8px;
  list-style: none;
  display: flex;
  overflow-y: hidden;

  :not(:last-child) {
    & > .MuiBox-root:first-of-type {
      ::after {
        width: 3px;
        // 縦線が丸に対して右にずれないよう、縦線の太さに対してマイナスマージンをかける
        margin-left: calc(-3px / 2);
        height: 100%;
        content: "" "";
        background-color: ${(props) => props.theme.palette.primary.light};
      }
    }

    & > .MuiBox-root:last-of-type {
      padding-bottom: ${(props) => props.theme.spacing(2)}px;
    }
  }
`;

const StyledTime = styled.time`
  flex-shrink: 0;
  font-size: 12px;
  font-weight: normal;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledParagraph = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  white-space: pre-wrap;
  word-break: break-all;
`;

const StyledEm = styled.em<{ mentionedToSelf: boolean; mentionedToDeletedUser: boolean }>`
  font-style: normal;
  color: ${(props) =>
    props.mentionedToSelf
      ? props.theme.palette.primary.main
      : !props.mentionedToDeletedUser
      ? props.theme.palette.blue.main
      : props.theme.palette.text.muted};
`;

const StyledSmall = styled.small`
  display: inline-block;
  margin-left: ${(props) => props.theme.spacing(0.5)}px;
  font-size: 10px;
  word-break: break-word;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;
  touch-action: manipulation;
  user-select: none;
  background-color: transparent;
`;
