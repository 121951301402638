import React, { FC } from "react";

export const Eye: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(0.832 0.832)">
        <g transform="translate(1.168 4.404)">
          <path
            d="M12,20.059c-5.379,0-9.579-6.1-9.757-6.364L2,13.294l.271-.4C2.421,12.63,6.621,6.53,12,6.53s9.579,6.1,9.757,6.364l.243.4-.271.4C21.579,13.959,17.379,20.059,12,20.059ZM3.721,13.294c.993,1.3,4.386,5.336,8.279,5.336s7.286-4.029,8.279-5.336c-.993-1.3-4.386-5.336-8.279-5.336S4.714,11.987,3.721,13.294Z"
            transform="translate(-2 -6.53)"
            stroke="none"
          />
          <path
            d="M14.571,11a2.957,2.957,0,0,0-.343,0,.238.238,0,0,0-.15.407h0a1.85,1.85,0,0,1,.529,1.286,1.879,1.879,0,0,1-1.879,1.879,1.843,1.843,0,0,1-1.271-.514h0a.25.25,0,0,0-.429.129,2.95,2.95,0,0,0-.029.386A3.571,3.571,0,1,0,14.571,11Z"
            transform="translate(-4.571 -7.805)"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
};
