import { TenantSettings, SelectableFeatures, Message } from "@onn/common";

import { factory } from "~/service/repository/ITenantSettingsRepository";

const tenantSettingsRepository = factory.tenantSettingsRepository();
export const TenantSettingsUseCase = {
  get: async (tenantId: string): Promise<TenantSettings> => {
    return tenantSettingsRepository.get(tenantId);
  },
  updateFeature: async (
    tenantId: string,
    features: Record<SelectableFeatures, boolean>
  ): Promise<void> => {
    await tenantSettingsRepository.updateFeature(tenantId, features);
  },
  updateMessage: async (tenantId: string, message: Message): Promise<void> => {
    await tenantSettingsRepository.updateMessage(tenantId, message);
  },
  /**
   * @deprecated
   * この関数はdeprecatedになっている tenantSettingsRepository.updateを使って更新しているので非推奨です。
   * newUpdateを使ってください。
   * TODO: 全て置き換えた時にnewUpdateをリネームする。
   */
  update: async (tenantId: string, updates: Partial<TenantSettings>): Promise<void> => {
    await tenantSettingsRepository.update(tenantId, updates);
  },
  newUpdate: async (docId: string, updates: Partial<TenantSettings>): Promise<void> => {
    await tenantSettingsRepository.newUpdate(docId, updates);
  },
};
