import { Radio } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

type CustomProps = {
  isReadOnly?: boolean;
  defaultColor?: "default" | "primary";
};

type Props = ComponentProps<typeof Radio> & CustomProps;

export const RadioButton: FC<Props> = ({ isReadOnly, defaultColor, ...rest }) => {
  return <StyledRadio {...rest} $isReadOnly={isReadOnly} $defaultColor={defaultColor} />;
};

const StyledRadio = styled(Radio)<{ $isReadOnly?: boolean; $defaultColor?: string }>`
  svg {
    height: 20px;
    width: 20px;
  }

  &.MuiRadio-root {
    /* primaryのcolorはthemeで定義しているので、ここで定義しない */
    color: ${(props) => props.$defaultColor === "default" && props.theme.palette.grey[200]};
  }

  ${(props) =>
    props.$isReadOnly &&
    `
    &.MuiRadio-root {
      cursor: default;
      background-color: transparent !important;
    }
  `}
`;
