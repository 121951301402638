import { functionOperator } from "~/infrastructure/api/functionOperator";
import { ogpAdapter as IOgpAdapter } from "~/service/usecases/ogp/interfaces/ogpAdapter";

export class OgpAdapter implements IOgpAdapter {
  async getOgpByUrl({ url }: { url: string }): Promise<{ [key: string]: string }> {
    const ogpData = await functionOperator
      .httpsCall<unknown, { [key: string]: string }>("getOgp", {
        url,
      })
      .then((res) => {
        return res.data;
      });
    return ogpData;
  }
}
