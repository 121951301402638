import { useCallback } from "react";
import { useCookies } from "react-cookie";

const SEVEN_DAYS = 60 * 60 * 24 * 7;

const COOKIE_SET_OPTIONS = {
  path: "/",
  maxAge: SEVEN_DAYS,
  sameSite: "lax",
  secure: true,
} as const;

const COOKIE_REMOVE_OPTIONS = {
  path: "/",
} as const;

/**
 * Cookieを操作します
 * @returns value 与えられたkeyに対応する値
 * @returns updateValue 与えられたkeyに値を登録する(expires=7日)
 * @returns removeValue 与えられたkeyに値を削除する
 */
export const useCookie = (key: string) => {
  const [cookies, setCookie, removeCookie] = useCookies([key]);

  const updateValue = useCallback(
    (value: string) => {
      setCookie(key, value, COOKIE_SET_OPTIONS);
    },
    [key, setCookie]
  );

  const removeValue = useCallback(() => {
    removeCookie(key, COOKIE_REMOVE_OPTIONS);
  }, [key, removeCookie]);

  return { value: cookies[key], updateValue, removeValue };
};
