import { useState, useCallback } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { CommentUseCase } from "~/service/usecases/commentUseCase";

const commentUseCase = new CommentUseCase();

/**
 * コメントを更新する
 * @returns isLoading データを削除中かどうか
 * @returns deleteEmployeeActiveLog ログを削除する関数
 */
export const useUpdateComment = (): {
  isLoading: boolean;
  updateComment: (
    commentId: string,
    {
      content,
      transactionId,
    }: {
      content: string;
      transactionId: string;
    }
  ) => Promise<void>;
} => {
  const { currentUser } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);

  const updateComment = useCallback(
    async (
      commentId: string,
      { content, transactionId }: { content: string; transactionId: string }
    ) => {
      setIsLoading(true);
      await commentUseCase.update({
        commentId,
        contentString: content,
        transactionId,
        employeeId: currentUser.id,
      });
      setIsLoading(false);
    },
    [currentUser.id]
  );

  return {
    isLoading,
    updateComment,
  };
};
