import { Box } from "@material-ui/core";
import React from "react";
import styled, { CSSObject } from "styled-components";

import { Icon } from "~/components/uiParts";

type Props = {
  value: string;
  name: string;
};

export const FixedEmailInput = ({ value, name }: Props): JSX.Element => {
  return (
    <Box display="flex">
      <Box width="95%">
        <StyledFixedEmailInput value={value} name={name} type="email" readOnly />
      </Box>
      <Icon icon="check" color="primary" size="sm" />
    </Box>
  );
};

const StyledFixedEmailInput = styled.input`
  width: 100%;
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.theme.typography.subtitle2 as CSSObject};
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;
