import { WriteBatch, writeBatch } from "firebase/firestore";

import { firestore } from "~/config/firebase";

export const FIRESTORE_BATCH_MAX = 500;

export const queryOperator = {
  batch: () => writeBatch(firestore),
  commit: (batch: WriteBatch): Promise<void> => batch.commit(),
};
