import { format } from "date-fns";
import { useCallback, useMemo } from "react";

import { OtherEmployee } from "./useOtherEmployees";

import { useCommentsByTransactionIds } from "~/hooks/comment";
import { useCurrentUser } from "~/hooks/employee";

import { CommentUseCase } from "~/service/usecases/commentUseCase";

const commentUseCase = new CommentUseCase();

type ContentText = { text: string } & (
  | { type: "plain" }
  | { type: "mention"; user?: { id: string; name: string; deleted?: boolean } }
);

type Comment = {
  id: string;
  user?: { id: string; name: string; profileIconImageUrl?: string; deleted?: boolean };
  content: ContentText[];
  editable: boolean;
  deletable: boolean;
  createdAt: string;
  updatedAt: string;
  suggestedMentionUsers?: { id: string; name: string }[];
};

export type TransactionCommentsMap = { [transactionId: string]: Comment[] };

export const useTransactionComments = ({
  transactionIds,
  otherEmployees,
}: {
  transactionIds: string[];
  otherEmployees: OtherEmployee[];
}) => {
  const { currentUser } = useCurrentUser();
  const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";

  // FIXME: useViewModel を解体するときに削除
  const {
    data: comments,
    mutate: mutateComments,
    isValidating: commentsLoading,
  } = useCommentsByTransactionIds(transactionIds);

  /**
   * トランザクションのidを渡して関連するコメントを更新する
   * @param transactionId loadするcommentsが属するトランザクションのid
   */
  const loadTransactionComments = useCallback(
    async (transactionId: string) => {
      const comments = await commentUseCase.whereByTransactionIds(
        [transactionId],
        currentUser.tenantId
      );
      mutateComments((prevState) => {
        return [...(prevState || []).filter((v) => v.transactionId !== transactionId), ...comments];
      }, false);
    },
    [currentUser.tenantId, mutateComments]
  );

  const transactionCommentsMap = useMemo<TransactionCommentsMap>(() => {
    if (!comments) return {};

    const otherEmployeesMap = new Map();
    otherEmployees.forEach((employee) => {
      otherEmployeesMap.set(employee.id, employee);
    });

    return comments.reduce((acc, current) => {
      const comment: Comment = {
        id: current.id,
        user: otherEmployeesMap.get(current.employeeId),
        content: current.content.map((contentText) => {
          if (contentText.type === "plain") {
            return contentText;
          }

          return {
            type: "mention",
            text: contentText.text,
            user: otherEmployeesMap.get(contentText.employeeId),
          };
        }),
        editable: current.editable(currentUser),
        deletable: current.deletable(currentUser),
        createdAt: format(current.createdAt, TIMESTAMP_FORMAT),
        updatedAt: format(current.updatedAt, TIMESTAMP_FORMAT),
      };

      return {
        ...acc,
        [current.transactionId]: [...(acc[current.transactionId] ?? []), comment],
      };
    }, {} as TransactionCommentsMap);
  }, [comments, currentUser, otherEmployees]);

  return {
    transactionCommentsMap,
    commentsLoading,
    loadTransactionComments,
  };
};
