import React, { FC } from "react";

export const DoubleArrowRight: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M9.72175 11.975L5.50978 7.74892C5.28153 7.54674 5.17157 7.30181 5.1799 7.01412C5.18824 6.72644 5.29819 6.48152 5.50978 6.27935C5.70254 6.07717 5.9386 5.97607 6.21795 5.97607C6.4973 5.97607 6.74277 6.07717 6.95435 6.27935L11.9011 11.2261C11.9844 11.3261 12.0582 11.4402 12.1223 11.5685C12.1864 11.6967 12.2185 11.8322 12.2185 11.975C12.2185 12.1177 12.1864 12.2533 12.1223 12.3815C12.0582 12.5098 11.9844 12.6156 11.9011 12.6989L6.95435 17.6456C6.74277 17.8645 6.4973 17.9721 6.21795 17.9685C5.9386 17.9649 5.69784 17.862 5.49565 17.6598C5.28405 17.4576 5.17825 17.2145 5.17825 16.9304C5.17825 16.6464 5.28405 16.3996 5.49565 16.1902L9.72175 11.975ZM16.375 11.975L12.1239 7.74892C11.9217 7.54674 11.8207 7.30181 11.8207 7.01412C11.8207 6.72644 11.9217 6.48152 12.1239 6.27935C12.3261 6.07717 12.5669 5.97607 12.8462 5.97607C13.1256 5.97607 13.3794 6.07717 13.6076 6.27935L18.5402 11.2261C18.633 11.3261 18.7114 11.4402 18.7756 11.5685C18.8397 11.6967 18.8718 11.8322 18.8718 11.975C18.8718 12.1177 18.8397 12.2533 18.7756 12.3815C18.7114 12.5098 18.633 12.6156 18.5402 12.6989L13.6076 17.6456C13.3794 17.8645 13.1232 17.9721 12.8391 17.9685C12.5551 17.9649 12.325 17.862 12.1489 17.6598C11.9206 17.4576 11.8065 17.2145 11.8065 16.9304C11.8065 16.6464 11.9206 16.3996 12.1489 16.1902L16.375 11.975Z"
        fill="#757575"
      />
    </svg>
  );
};
