import { Divider as MuiDivider } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

type customProps = {
  margin?: number;
};

type Props = ComponentProps<typeof MuiDivider> & customProps;

export const Divider: FC<Props> = ({ margin = 0, ...props }) => {
  const isVertical = props.orientation === "vertical";

  return <StyledDivider {...props} margin={margin} flexItem={isVertical} />;
};

const StyledDivider = styled(MuiDivider)<Props>`
  ${(props: Props) =>
    props.orientation === "horizontal" &&
    `
    margin: ${props.margin}px 0px !important;
  `}

  ${(props: Props) =>
    props.orientation === "vertical" &&
    `
    margin: 0px ${props.margin}px !important;
  `}
`;
