import React, { ComponentProps, FC, useMemo, useState } from "react";
import styled from "styled-components";

import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import { UserIcon } from "../UserIcon";

type Props = {
  userInfo: {
    username: ComponentProps<typeof UserIcon>["username"];
    profileIconImageUrl?: ComponentProps<typeof UserIcon>["profileIconImageUrl"];
    borderColor?: ComponentProps<typeof UserIcon>["borderColor"];
  }[];
};

const UserIconWidth = 24;
const UserIconWithGapWidth = 26; // 2番目以降のアイコンや+nの部分、アイコン+gap

export const SmallUserIconGroup: FC<Props> = ({ userInfo }) => {
  const [areaWidth, setAreaWidth] = useState<number>(0);

  const [iconUsersInfo, extraUsersInfo] = useMemo(() => {
    /**
     * 幅に収まる、アイコンと+nの部分の最大個数を計算
     * +1 はfirstIconの分
     * 0 で小数点以下を切り捨てる
     * maxの最小値は2
     */
    const maxCount = Math.max(((areaWidth - UserIconWidth) / UserIconWithGapWidth + 1) | 0, 2);

    if (userInfo.length > maxCount) {
      return [userInfo.slice(0, maxCount - 1), userInfo.slice(maxCount - 1)];
    }

    return [userInfo, []];
  }, [userInfo, areaWidth]);

  const extraUsersNameLabel = useMemo(
    () => extraUsersInfo.map((u) => u.username).join(" / "),
    [extraUsersInfo]
  );

  return (
    <StyledUserIconWrapper ref={(node: HTMLDivElement) => setAreaWidth(node?.offsetWidth || 0)}>
      {iconUsersInfo.map((user, index) => (
        <UserIcon
          key={`${user.username}_${index}`}
          circular
          username={user.username}
          profileIconImageUrl={user.profileIconImageUrl}
          borderColor={user.borderColor}
          size="extraSmall"
          hover
        />
      ))}
      {extraUsersInfo.length > 0 && (
        <Tooltip title={extraUsersNameLabel} arrow>
          <StyledExtraUserCountTypography variant="body2" color="textPrimary" bold>
            +{extraUsersInfo.length}
          </StyledExtraUserCountTypography>
        </Tooltip>
      )}
    </StyledUserIconWrapper>
  );
};

const StyledExtraUserCountTypography = styled(Typography)`
  width: ${UserIconWidth}px;
  height: 100%;
  text-align: center;
`;

const StyledUserIconWrapper = styled.div`
  display: flex;
  column-gap: 2px;
  width: 100%;
  .MuiAvatar-root {
    border-width: 1px;
  }
`;
