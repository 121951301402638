import {
  addDays,
  addWeeks,
  differenceInCalendarDays,
  format,
  isWeekend,
  Locale,
  addMonths,
  setHours,
  subDays,
  subWeeks,
  subMonths,
} from "date-fns";

import { addBusinessDays, subBusinessDays, isHolidays } from "../../../utils";
import { Employee } from "../../Employee";

export class DifferenceDate {
  deliveryTime: number;
  unit: (typeof DifferenceDate.UNITS)[number];
  amount: number;
  direction: (typeof DifferenceDate.DIRECTIONS)[number];
  referenceDateType?: (typeof DifferenceDate.REFERENCE_DATE_TYPE)[number];

  static UNITS = ["DAY", "BUSINESS_DAY", "WEEK", "MONTH"] as const;
  static DIRECTIONS = ["BEFORE", "AFTER"] as const;
  static REFERENCE_DATE_TYPE = ["INVITED_AT", "JOIN_AT"] as const;

  static displayUnitMap: Record<DifferenceDate["unit"], string> = {
    DAY: "日",
    BUSINESS_DAY: "営業日",
    WEEK: "週",
    MONTH: "ヶ月",
  };

  static displayDirectionMap: Record<DifferenceDate["direction"], string> = {
    BEFORE: "前",
    AFTER: "後",
  };

  static displayReferenceDateTypeMap: Record<
    Required<DifferenceDate>["referenceDateType"],
    string
  > = {
    JOIN_AT: "入社",
    INVITED_AT: "入社者招待",
  };

  calculateDate(_referenceDate: Date): Date {
    const referenceDate = setHours(_referenceDate, this.deliveryTime);
    // 日時と週は当日起算に則るため1引いて計算する
    if (this.direction === "AFTER") {
      switch (this.unit) {
        case "DAY":
          return subDays(addDays(referenceDate, this.amount), 1);
        case "BUSINESS_DAY":
          return isHolidays(referenceDate) || isWeekend(referenceDate)
            ? addBusinessDays(referenceDate, this.amount)
            : addBusinessDays(referenceDate, this.amount - 1);
        case "WEEK":
          return subDays(addWeeks(referenceDate, this.amount), 1);
        case "MONTH":
          return addMonths(referenceDate, this.amount);
      }
    } else {
      switch (this.unit) {
        case "DAY":
          return subDays(referenceDate, this.amount);
        case "BUSINESS_DAY":
          return subBusinessDays(referenceDate, this.amount);
        case "WEEK":
          return subWeeks(referenceDate, this.amount);
        case "MONTH":
          return subMonths(referenceDate, this.amount);
      }
    }
  }

  calculateDateByEmployee(employee: Employee) {
    const referenceDateTarget =
      this.referenceDateType === "INVITED_AT" ? employee.invitedAt : employee.joinAt;

    // 入社日基準かつ入社日が存在しない場合にnullを返す
    if (!referenceDateTarget) return null;

    return this.calculateDate(new Date(referenceDateTarget.toString()));
  }

  getDisplayUnit() {
    return DifferenceDate.displayUnitMap[this.unit];
  }

  getDisplayDirection() {
    return DifferenceDate.displayDirectionMap[this.direction];
  }

  getDisplayReferenceDateType() {
    return DifferenceDate.displayReferenceDateTypeMap[this.referenceDateType || "JOIN_AT"];
  }

  getDisplayTiming() {
    return `${this.getDisplayReferenceDateType()}${
      this.amount
    }${this.getDisplayUnit()}${this.getDisplayDirection()}`;
  }

  getDisplayDeliveryTime() {
    return `${this.deliveryTime}時~${this.deliveryTime === 9 ? "(デフォルト)" : ""}`;
  }

  getDisplayTextByEmployee(
    newHire: Employee,
    formatText = "yyyy/MM/dd",
    option?: { locale: Locale }
  ): string {
    const dueDate = this.calculateDateByEmployee(newHire);

    if (!dueDate) {
      return this.getDisplayTiming();
    }

    return format(dueDate, formatText, option);
  }

  public static getDisplayDeliveryTime(hour: DifferenceDate["deliveryTime"]) {
    return `${hour}時~${hour === 9 ? "(デフォルト)" : ""}`;
  }

  constructor({
    deliveryTime,
    unit,
    amount,
    direction,
    referenceDateType,
  }: Partial<DifferenceDate> = {}) {
    this.deliveryTime = deliveryTime ?? 0;
    this.unit = unit || "DAY";
    this.amount = amount || 1;
    this.direction = direction || "AFTER";
    this.referenceDateType = referenceDateType || "JOIN_AT";
  }

  static convertDateIntoDifferenceDate(date: Date, referenceDate: Date) {
    // 日と週は当日起算に則るため1足して計算する
    const amount = differenceInCalendarDays(date, referenceDate);
    return new DifferenceDate({
      deliveryTime: 9,
      unit: "DAY",
      amount: amount >= 0 ? amount + 1 : Math.abs(amount),
      direction: amount >= 0 ? "AFTER" : "BEFORE",
    });
  }
}
