import { Grid, GridSize, Paper } from "@material-ui/core";
import React, { ReactNode } from "react";
import { DropResult } from "react-beautiful-dnd";
import styled from "styled-components";

import { DnDDraggable } from "../DnD/DnDDraggable";
import { DnDDroppable } from "../DnD/DnDDroppable";
import { DnDProvider } from "../DnD/DnDProvider";
import { Typography } from "../Typography";

import { constants } from "~/config/theme";

// ====================
// props type
// ====================

type Props<T> = {
  headers: { text: string; key: string }[];
  widthOptions: GridSize[];
  dataArray: T[];
  editable: boolean;
  onChangeExperiencesOrder: (result: DropResult) => void;
  rowRenderer: (data: T, index: number) => ReactNode;
};

// ====================
// component
// ====================

export const DnDTable = <T extends { id: string }>({
  headers,
  widthOptions,
  dataArray,
  editable,
  onChangeExperiencesOrder,
  rowRenderer,
}: Props<T>) => {
  return (
    <StyledPaper square>
      <StyledTableHeader container alignItems="center">
        {headers.map((header, index) => (
          <Grid key={header.key} item xs={widthOptions[index]}>
            {header.text !== "" && (
              <Typography variant="overline" noWrap color="textSecondary">
                {header.text}
              </Typography>
            )}
          </Grid>
        ))}
      </StyledTableHeader>

      <DnDProvider onDragEnd={onChangeExperiencesOrder}>
        <DnDDroppable isDropDisabled={!editable} droppableId="droppableId">
          <Grid>
            {dataArray.map((data, index) => {
              return (
                <DnDDraggable
                  key={data.id}
                  isDragDisabled={!editable}
                  draggableId={data.id}
                  index={index}
                >
                  <StyledRow>{rowRenderer(data, index)}</StyledRow>
                </DnDDraggable>
              );
            })}
          </Grid>
        </DnDDroppable>
      </DnDProvider>
    </StyledPaper>
  );
};

// ====================
// styles
// ====================

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    min-width: ${constants.ADMIN_CONTENT_MIN_WIDTH}px;
  }
`;

const StyledTableHeader = styled(Grid)`
  &.MuiGrid-root {
    padding: 16px 40px;
  }
`;

const StyledRow = styled(Grid)`
  :hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;
