import { v4 } from "uuid";

import { Failure, Result, Success } from "../shared";

import { contactMessageDraftSchema, IContactMessageDraftSchema } from "./schema";

export class ContactMessageDraft implements IContactMessageDraftSchema {
  private static validator = contactMessageDraftSchema;
  readonly id: string;
  readonly contactRoomId: string;
  readonly createdUserId: string;
  text: string;
  tenantId: string;

  constructor({
    id,
    contactRoomId,
    createdUserId,
    text,
    tenantId,
  }: {
    readonly id: string;
    readonly contactRoomId: string;
    readonly createdUserId: string;
    readonly text: string;
    readonly tenantId: string;
  }) {
    this.id = id;
    this.contactRoomId = contactRoomId;
    this.createdUserId = createdUserId;
    this.text = text;
    this.tenantId = tenantId;
  }

  static validate(data: ExcludeMethods<ContactMessageDraft>): Result<ContactMessageDraft, Error> {
    const result = ContactMessageDraft.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new ContactMessageDraft(data));
  }

  static create(params: ExcludeMethods<Omit<ContactMessageDraft, "id">>): ContactMessageDraft {
    return new ContactMessageDraft({
      ...params,
      id: v4(),
    });
  }
}
