import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography, UserIcon } from "~/components/uiParts";

type Props = {
  employee: Employee;
  checked: boolean;
  onChange: () => void;
};

export const EmployeeFormControlLabel: FC<Props> = ({ employee, checked, onChange }) => {
  return (
    <StyledFormControlLabel
      control={
        <Checkbox checked={checked} onChange={onChange} name={employee.id} value={employee.id} />
      }
      label={
        <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
          <UserIcon
            username={employee.getName()}
            profileIconImageUrl={employee.profileIconImageUrl}
            size="extraSmall"
            circular
          />
          <Typography variant="body2" color="textSecondary" noWrap>
            {employee.getName()}
          </Typography>
        </Box>
      }
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
