import { useState, useCallback } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { CommentUseCase } from "~/service/usecases/commentUseCase";
import { captureException } from "~/util";

const commentUseCase = new CommentUseCase();

/**
 * コメントを投稿する
 * @returns isLoading データを作成中かどうか
 * @returns createComment コメントを作成する関数
 */
export const useCreateComment = (): {
  isLoading: boolean;
  createComment: (
    contentString: string,
    {
      transactionId,
    }: {
      transactionId: string;
    }
  ) => Promise<void>;
} => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const createComment = useCallback(
    async (contentString: string, { transactionId }: { transactionId: string }) => {
      setIsLoading(true);
      try {
        await commentUseCase.add({
          contentString,
          transactionId,
          employeeId: currentUser.id,
          tenantId: currentUser.tenantId,
        });
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        captureException({
          error: e as Error,
          tags: { type: "useCreateComment:createComment" },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [currentUser.id, currentUser.tenantId, enqueueSnackbar]
  );

  return {
    isLoading,
    createComment,
  };
};
