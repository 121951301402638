import React, { FC } from "react";

export const Send: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-328 -703.503)">
        <path
          d="M6.667,59.745V56.91a.5.5,0,0,0-.848-.359l-5.667,5.5a.5.5,0,0,0,0,.717l5.667,5.5a.5.5,0,0,0,.848-.359V65.077h.945a8.5,8.5,0,0,1,7.435,4.381l.014.025a.5.5,0,0,0,.439.26.493.493,0,0,0,.124-.015A.5.5,0,0,0,16,69.243,9.511,9.511,0,0,0,6.667,59.745Z"
          transform="translate(332 652.094)"
          stroke="none"
        />
      </g>
    </svg>
  );
};
