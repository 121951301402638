import React, { FC } from "react";

export const GripVertical: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 18">
      <g transform="translate(3223 12250) rotate(90)">
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12250 3212)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12250 3219)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12243 3212)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12243 3219)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12236 3219)" />
        <circle stroke="none" cx="2" cy="2" r="2" transform="translate(-12236 3212)" />
      </g>
    </svg>
  );
};
