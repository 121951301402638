import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Body = APISchema["/patch_read_log_and_unread_count"]["PATCH"]["body"];

export const useUpdateReadLogAndUnreadCount = () => {
  const updateReadLogAndUnreadCount = useCallback(async (contactRoomId: string) => {
    const body: Body = {
      contactRoomId,
    };
    await apiClient.patch("/patch_read_log_and_unread_count", body);
  }, []);

  return { updateReadLogAndUnreadCount };
};
