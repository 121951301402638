import React, { FC } from "react";

export const ArrowDouble: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
      <g>
        <path d="M22,8l-4-4v3H3v2h15v3L22,8z" />
        <path d="M2,16l4,4v-3h15v-2H6v-3L2,16z" />
      </g>
    </svg>
  );
};
