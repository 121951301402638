import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  libraryTitle: string;
  onCancel: () => void;
  onAccept: () => void;
};

export const ConfirmDeleteLibraryModal: FC<Props> = ({
  open,
  libraryTitle,
  onAccept,
  onCancel,
}) => {
  return (
    <Modal
      open={open}
      title="カテゴリー削除"
      content={
        <Box>
          <Typography variant="body1" align="center">
            {`カテゴリーを削除しますか？\n 削除が行われるとカテゴリー内のコンテンツも失われます。\n この操作は取り消すことができません。`}
          </Typography>
          <Box height="24px" />
          <StyledBox px={3} py={4}>
            <Typography variant="body1">{libraryTitle}</Typography>
          </StyledBox>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            onClick={onAccept}
          >
            削除
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
