import { isFunction, isDate, isObject, isArray } from "lodash";

const isNonNullObject = (v: unknown): v is object => isObject(v) && v !== null; // typeof null は "object"
// eslint-disable-next-line @typescript-eslint/ban-types
const isForbiddenType = (v: unknown): v is undefined | Function => v === undefined || isFunction(v);

export type ToCloudFunctionsCompatible<T> = DeepConvertValueType<
  DeepConvertValueType<ExcludeMethods<T>, Date, number>,
  Date | undefined,
  number | undefined
>;

/**
 * 以下の操作をして Date型をunix時間（number型） に変換します
 * - 関数プロパティと undefined プロパティを delete する
 * - Date プロパティを number プロパティ(unix時間)に変換する
 * @param {object} obj
 * @returns {ToCloudFunctionsCompatible<ExcludeMethods<T>>}
 */
export const toCloudFunctionsCompatible = <T extends object>(
  obj: T
):
  | ToCloudFunctionsCompatible<ExcludeMethods<T>>
  | ToCloudFunctionsCompatible<ExcludeMethods<T>>[] => {
  // 配列のとき、undefined と関数を取り除いたのち、各要素に対して変換をかける
  if (isArray(obj)) {
    return obj
      .filter((item) => !isForbiddenType(item))
      .map((item) => {
        if (isDate(item)) {
          return item.getTime();
        }
        if (isNonNullObject(item)) {
          return toCloudFunctionsCompatible(item);
        }
        return item;
      });
  }

  // null でないオブジェクトのとき、各プロパティに対して変換をかける
  if (isNonNullObject(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = {} as any; // TODO: 型を妥協しない
    for (const key in obj) {
      const val = obj[key];
      if (isForbiddenType(val)) {
        continue;
      }
      if (isDate(val)) {
        result[key] = val.getTime();
        continue;
      }
      if (isNonNullObject(val)) {
        result[key] = toCloudFunctionsCompatible(val);
        continue;
      }
      result[key] = val;
    }
    return result;
  }

  // obj は配列またはオブジェクト
  throw new Error("Invalid value type");
};
