import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Notice, Typography } from "~/components/uiParts";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";

export const ResetEmailShow: FC = () => {
  return (
    <StyledBox px="16px">
      <Notice title="メールアドレスが変更されました" showCloseAnnotation={false}>
        <Typography variant="body1" color="textSecondary" align="center">
          変更を元に戻す場合は
          <br />
          お手数ですが管理者の方にご連絡ください
        </Typography>
      </Notice>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  left: 0;
  /* FIXME: 一時的に NotFound で背景表示。背景画像はルールを整理して場所を移す */
  background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  background-size: 60%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;
