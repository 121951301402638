import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useState, useCallback, FC } from "react";
import styled from "styled-components";

import { Button, Divider, Icon, Modal, Tooltip, Typography, UserIcon } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  assignee?: Employee;
  followers: Employee[];
  onSubmit: (followerIds: string[]) => Promise<void>;
  onClickAddFollower: (followers: Employee[]) => void;
  onChangeAssignee?: (assignee?: Employee) => Promise<void>;
};

export const ManageContactTeamModal: FC<Props> = ({
  open,
  onCancel,
  assignee,
  followers: _followers,
  onSubmit,
  onClickAddFollower: onClickAddFollower,
  onChangeAssignee,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [followers, setFollowers] = useState<Employee[]>(_followers);

  const handleClickDismissButton = useCallback(
    (targetEmployeeId: string) => {
      setIsLoading(true);
      const newFollowerIds = followers.flatMap((v) => (v.id !== targetEmployeeId ? v.id : []));
      onSubmit(newFollowerIds)
        .then(() => {
          setFollowers((prev) => prev.filter((v) => v.id !== targetEmployeeId));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [followers, onSubmit]
  );

  const EmployeeItem = ({
    label,
    onClick,
    employee,
  }: {
    label: string;
    onClick: () => void;
    employee?: Employee;
  }) => (
    <Box mt={2} display="flex">
      <UserIcon
        username={employee?.getName() ?? "未登録"}
        profileIconImageUrl={employee?.profileIconImageUrl}
        size="small"
        circular
      />
      <Box display="inline-block" ml={2}>
        <Typography variant="body1" bold>
          {employee?.getName() ?? "未登録"}
        </Typography>
        <Typography variant="caption">{employee?.email ?? "-"}</Typography>
      </Box>
      <Box ml="auto">
        <Button
          variant="outlined"
          borderRadius="regular"
          color="default"
          onClick={onClick}
          disabled={isLoading}
        >
          {label}
        </Button>
      </Box>
    </Box>
  );

  const Content = (
    <>
      {onChangeAssignee && (
        <>
          <StyledBox display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="body2" bold>
              担当者
            </Typography>
          </StyledBox>
          <EmployeeItem
            label="変更"
            employee={assignee}
            onClick={() => onChangeAssignee(assignee)}
          />
          <Divider margin={32} orientation="horizontal" />
        </>
      )}

      <Box display="flex" justifyContent="space-between">
        <StyledBox display="flex" justifyContent="flex-start" alignItems="center">
          <Typography variant="body2" bold>
            フォロワー
          </Typography>
          <Tooltip
            style={{ cursor: "pointer", marginBottom: 2 }}
            title="フォロワーは、コンタクトのやり取りの閲覧・返信が行えます。フォロワーになると該当のコンタクトルームで新着メッセージが来た際に通知が送信されます。"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </StyledBox>
        <StyledTypography
          variant="body2"
          bold
          color="primary"
          onClick={() => onClickAddFollower(followers)}
        >
          フォロワーを追加
        </StyledTypography>
      </Box>
      {followers.map((follower) => (
        <EmployeeItem
          label="削除"
          employee={follower}
          key={follower.id}
          onClick={() => handleClickDismissButton(follower.id)}
        />
      ))}
    </>
  );

  return (
    <Modal
      open={open}
      title={`${onChangeAssignee ? "" : "デフォルト"}対応チーム設定`}
      content={Content}
      onCancel={onCancel}
    />
  );
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledBox = styled(Box)`
  gap: 12px;
`;
