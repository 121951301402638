const REG_EXP_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * email が正しい形式かをチェックする関数
 * @param email
 * @returns boolean
 */
export const isValidEmail = (email: string): boolean => {
  return REG_EXP_PATTERN.test(email);
};
