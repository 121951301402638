import { Box } from "@material-ui/core";
import { Maintenance } from "@onn/common";
import React, { createContext, ReactNode, FC } from "react";
import styled from "styled-components";

import { Typography, Notice } from "~/components/uiParts";
import { useMaintenance } from "~/hooks/maintenance/useMaintenance";
import { useIpAddress } from "~/hooks/shared";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";

type Props = {
  children: ReactNode;
};

export const MaintenanceContext = createContext<{ maintenance: Maintenance | undefined }>({
  maintenance: undefined,
});

const convertMessage = (message: string) => {
  // \nで改行、` *`でbold開始、`* `でbold終了
  const messageElementsString = message
    .replace(/\\n/g, "<br />")
    .replace(/\s\*/g, "<em>")
    .replace(/\*\s/g, "</em>");

  return messageElementsString;
};

/**
 * メンテナンスを表示するかどうかを返す。メンテナンス状態でも、特定のIpAddressの場合はメンテナンスを表示しない
 */
const getIsDisplayMaintenance = (maintenance: Maintenance, currentIp?: string) => {
  if (!maintenance.isMaintenance) return false;

  if (currentIp && maintenance.whiteIpList.includes(currentIp)) return false;

  return true;
};

export const MaintenanceProvider: FC<Props> = ({ children }) => {
  const { data: maintenance } = useMaintenance();
  const { data: currentIp } = useIpAddress();

  return (
    <MaintenanceContext.Provider value={{ maintenance }}>
      {maintenance && getIsDisplayMaintenance(maintenance, currentIp) ? (
        <StyledBox px="24px">
          <Box maxWidth="800px">
            <Notice title={maintenance.title}>
              <StyledTypography variant="body1" color="textPrimary" align="center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: convertMessage(maintenance.message),
                  }}
                />
              </StyledTypography>
            </Notice>
          </Box>
        </StyledBox>
      ) : (
        children
      )}
    </MaintenanceContext.Provider>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  left: 0;
  /* FIXME: 一時的に Maintenance で背景表示。背景画像はルールを整理して場所を移す */
  background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  background-size: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledTypography = styled(Typography)`
  em {
    font-style: normal;
    font-weight: bold;
  }
`;
