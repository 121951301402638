import React, { FC, useState, ComponentProps, useMemo } from "react";
import styled from "styled-components";

import { Tooltip, Typography } from "~/components/uiParts";
import { UserIcon } from "~/components/uiParts/UserIcon";

type UserInfo = {
  profileIconImageUrl?: string;
  username: string;
  borderColor?: ComponentProps<typeof UserIcon>["borderColor"];
};

type Props = {
  usersInfo: UserInfo[];
  /**
   * undefinedの場合、親要素の幅によって表示するアイコン数を動的に計算します
   */
  max?: number;
  /**
   * trueの場合、hoverしたときusernameを表示します
   */
  tooltip?: boolean;
  onClick?: () => void;
  className?: string;
};

const FirstIconWidth = 40;
const AfterTheFirstWidth = 32; // 2番目以降のアイコンや+nの部分

/**
 * 幅に収まる、アイコンと+nの部分の最大個数を計算
 *
 * +1 はfirstIconの分
 * 0 で小数点以下を切り捨てる
 * @returns {number} 表示できるアイコンの個数
 */
const calcMax = (width: number) => {
  const max = ((width - FirstIconWidth) / AfterTheFirstWidth + 1) | 0;
  // maxの最小値は2
  return Math.max(max, 2);
};

// maxが指定されてあったとしても幅に収まる個数を表示する
const getMax = (width: number, max?: number) => {
  if (!max) return calcMax(width);

  return max <= calcMax(width) ? max : calcMax(width);
};

export const UserIconGroup: FC<Props> = ({
  usersInfo,
  max,
  tooltip = false,
  onClick,
  className,
}) => {
  const [width, setWidth] = useState<number>(0);

  const [iconUsersInfo, extraUsersInfo] = useMemo(() => {
    const maxCount = getMax(width, max);

    if (usersInfo.length > maxCount) {
      return [usersInfo.slice(0, maxCount - 1), usersInfo.slice(maxCount - 1)];
    }

    return [usersInfo, []];
  }, [usersInfo, width, max]);

  const extraUsersNameLabel = useMemo(
    () => extraUsersInfo.map((userInfo) => userInfo.username).join(" / "),
    [extraUsersInfo]
  );

  return (
    <StyledContainer
      className={className}
      ref={(node: HTMLDivElement) => (node ? setWidth(node.offsetWidth) : void 0)}
    >
      <StyledClickArea onClick={onClick} $pointer={!!onClick}>
        {iconUsersInfo.map((user, index) => (
          <StyledUserIconWrapper
            key={`${user.username}_${index}`}
            $zIndex={iconUsersInfo.length - index}
          >
            <UserIcon
              circular
              username={user.username}
              profileIconImageUrl={user.profileIconImageUrl}
              borderColor={user.borderColor}
              size="small"
              hover={tooltip}
            />
          </StyledUserIconWrapper>
        ))}
        {extraUsersInfo.length > 0 && (
          <Tooltip title={extraUsersNameLabel} disableHoverListener={!tooltip} arrow>
            <StyledTypography variant="body1" color="textPrimary" bold>
              +{extraUsersInfo.length}
            </StyledTypography>
          </Tooltip>
        )}
      </StyledClickArea>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;

  /* 先頭以外にネガティブマージンをあててかぶせる */
  & div:not(:first-child) {
    margin-left: -8px;
  }
`;
const StyledClickArea = styled.div<{ $pointer: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  ${({ $pointer }) => $pointer && "cursor: pointer;"}
`;
const StyledUserIconWrapper = styled.div<{ $zIndex: number }>`
  /* 左側のアイコンが上に重なるように */
  z-index: ${({ $zIndex }) => $zIndex};
`;
const StyledTypography = styled(Typography)`
  width: ${AfterTheFirstWidth}px;
  height: 100%;
  text-align: center;
`;
