import { Box, DialogContent } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  employee: Employee;
};

export const ResendInvitationModal: FC<Props> = ({ open, onCancel, onSubmit, employee }) => {
  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit()
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [onCancel, onSubmit]);

  const Content = (
    <StyledDialogContent>
      <StyledBox>
        <UserIcon
          profileIconImageUrl={employee.profileIconImageUrl}
          username={employee.getName()}
          size="medium"
          borderColor="primary"
        />
        <Box height="8px" />
        <Typography variant="body2" bold noWrap>
          {employee.getName() ?? employee.email}
        </Typography>
      </StyledBox>
      <StyledBox>
        <Typography variant="body1" color="textSecondary">
          Onnの招待通知を再送しますか？
        </Typography>
      </StyledBox>
    </StyledDialogContent>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        disabled={sending}
        onClick={handleSubmit}
      >
        再送する
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="招待通知の再送"
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    margin: 0 auto;
  }
`;

const StyledBox = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
