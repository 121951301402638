export class Revision {
  constructor(readonly forceUpdateRevision: string, readonly manualUpdateRevision: string) {}

  public detectUpdateType(newRevision: Revision): "force" | "manual" | "none" {
    if (this.forceUpdateRevision !== newRevision.forceUpdateRevision) {
      return "force";
    }
    if (this.manualUpdateRevision !== newRevision.manualUpdateRevision) {
      return "manual";
    }
    return "none";
  }

  static plainToInstance(init: DeepExcludeMethods<Revision>): Revision {
    return new Revision(init.forceUpdateRevision, init.manualUpdateRevision);
  }
}
