import React, { FC } from "react";
import styled from "styled-components";

import { Button, Icon } from "~/components/uiParts";

type ReactionsProps = {
  transactionId: string;
  onClickReactionButton: (transactionId: string) => void;
};

export const ReactionButton: FC<ReactionsProps> = ({ transactionId, onClickReactionButton }) => {
  return (
    <StyledButton
      fullWidth
      variant="text"
      color="primary"
      borderRadius="regular"
      startIcon={<Icon icon="send" size="md" color="primary" />}
      onClick={() => onClickReactionButton(transactionId)}
    >
      入社者へリアクションを送る
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #fff;
    box-shadow: 0px 0px 10px #0000000d;
    border-radius: 15px;
    font-size: 12px;
    padding: 8px;
    height: 40px;
  }
`;
