import React, { FC } from "react";

export const Clip: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.625 14">
      <path
        d="M97.341,3.246a3.28,3.28,0,0,0-2-2.966A4.184,4.184,0,0,0,93.887.039,4.38,4.38,0,0,0,92.4.28a3.26,3.26,0,0,0-1.04.7,3.38,3.38,0,0,0-.739,1.042,3.237,3.237,0,0,0-.275,1.267V9.549a2.261,2.261,0,0,0,.191.869,2.2,2.2,0,0,0,3.593.7A2,2,0,0,0,94.716,9.6V5.281l-1.75-.009V9.6c0,.326-.076.55-.4.55s-.474-.215-.474-.541V3.3a1.77,1.77,0,0,1,1.8-1.642,1.687,1.687,0,0,1,1.7,1.642L95.6,9.6c0,2-1.4,2.82-3.039,2.82a3.1,3.1,0,0,1-3.1-3V.982H87.716V9.549h0a4.6,4.6,0,0,0,2.8,4.111,5.905,5.905,0,0,0,2.044.379,5.506,5.506,0,0,0,1.971-.379,4.6,4.6,0,0,0,2.8-4.061h0V3.246Z"
        transform="translate(-87.716 -0.039)"
        stroke="none"
      />
    </svg>
  );
};
