import { Tenant } from "@onn/common";
import { useContext } from "react";

import { TenantContext } from "~/components/providers/TenantProvider";

export const useTenant = (): {
  tenant: Tenant;
  fetchTenant: () => void;
} => {
  const { tenant, fetchTenant } = useContext(TenantContext);

  if (tenant === undefined) {
    throw new Error("会社の取得に失敗しました");
  }

  return { tenant, fetchTenant };
};
