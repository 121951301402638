import { Employee } from "@onn/common";
import { useContext } from "react";

import { EmployeesContext } from "~/components/providers";

/**
 * @deprecated
 * このフックは廃止予定です。代わりにusePaginatedEmployeesを使用するか、必要な関数を作って全件取得をクライアントでしないようにしてください。
 */
export const useAllEmployees = (): {
  allEmployees: Employee[];
  mutateAllEmployees: () => void;
} => {
  const { allEmployees, mutateAllEmployees } = useContext(EmployeesContext);

  if (allEmployees === undefined) {
    throw new Error("ユーザー情報の取得に失敗しました");
  }

  return { allEmployees, mutateAllEmployees };
};
