import React, {
  FC,
  useState,
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  Dispatch,
} from "react";

import { FileViewer } from "~/components/uiParts/FileViewer";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { captureException, downloadFileAsync } from "~/util";

export type MetadataType = { name: string; size: number; url: string };

export const FileViewerContext = createContext<{
  setFiles: Dispatch<SetStateAction<MetadataType[] | undefined>>;
  setPreviewFileIndex: Dispatch<SetStateAction<number>>;
  handleDownloadFile: (url: string, metadata: MetadataType[]) => void;
}>({
  setFiles: () => void 0,
  setPreviewFileIndex: () => void 0,
  handleDownloadFile: () => void 0,
});

export const FileViewerProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [previewFileIndex, setPreviewFileIndex] = useState(-1);
  const [files, setFiles] = useState<MetadataType[]>();

  const handleDownloadFile = useCallback(
    async (url: string, metadata: MetadataType[]) => {
      const file = metadata.find((file) => file.url === url);
      if (!file) return;

      try {
        await downloadFileAsync({
          url,
          fileName: file.name,
        });
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar("ファイルのダウンロードに失敗しました", { variant: "error" });
        }
        captureException({
          error: error as Error,
          tags: { type: "FileViewerProvider:handleDownloadFile" },
        });
      }
    },
    [enqueueSnackbar]
  );

  const handlePreviewPrevFile = useCallback((attachedFilesLength: number) => {
    setPreviewFileIndex((prev) => {
      if (prev === 0) return attachedFilesLength - 1;
      return prev - 1;
    });
  }, []);

  const handlePreviewNextFile = useCallback((attachedFilesLength: number) => {
    setPreviewFileIndex((prev) => {
      if (prev === attachedFilesLength - 1) return 0;
      return prev + 1;
    });
  }, []);

  return (
    <FileViewerContext.Provider
      value={{
        setFiles,
        setPreviewFileIndex,
        handleDownloadFile,
      }}
    >
      {files && files[previewFileIndex] && (
        <FileViewer
          src={(files[previewFileIndex] as (typeof files)[number]).url}
          fileName={(files[previewFileIndex] as (typeof files)[number]).name}
          filePath={(files[previewFileIndex] as (typeof files)[number]).name}
          pagingEnabled={files.length > 1}
          onDownload={(url) => handleDownloadFile(url, files)}
          // TODO: Fileの削除にも対応してメモタブでも扱えるようにする
          // onDeleteFile={handleDeleteFile}
          onClose={() => setPreviewFileIndex(-1)}
          onClickNext={() => handlePreviewNextFile(files.length)}
          onClickPrev={() => handlePreviewPrevFile(files.length)}
        />
      )}

      {children}
    </FileViewerContext.Provider>
  );
};
