import { useCallback, useState } from "react";

/**
 * textが省略されているか判定するためのhooks
 * @returns setRef 判定対象のコンポーネントに渡す
 * @returns isTruncated 省略されていればtrue
 */
export const useIsTruncated = () => {
  const [isTruncated, setIsTruncated] = useState(false);
  const setRef = useCallback((node: HTMLElement) => {
    if (!node) return false;

    /**
     * NOTE:
     * scroll: 省略しない場合の幅(高さ)
     * offset: 画面で実際に表示されている幅(省略後の幅)
     *
     * 1行で横に伸びていくパターンと、横幅が固定されていて改行して縦に伸びていくパターンがあり、
     * どちらかの数値が一致しなければ省略されている
     */

    setIsTruncated(
      node.scrollWidth !== node.offsetWidth || node.scrollHeight !== node.offsetHeight
    );
  }, []);

  return { isTruncated, setRef };
};
