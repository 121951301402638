/**
 * 文字列をケバブケースに変換する
 * @param text 変換するテキスト
 * @returns
 */
export const convertStringToKebabCase = (text: string) => {
  return text
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
};
