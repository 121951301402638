import { z } from "zod";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  // nullが入ってきた場合
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === "date") {
      return { message: "日付フォーマットが不正です" };
    }
    if (issue.expected === "number") {
      return { message: "整数を入力してください" };
    }
    if (issue.expected === "integer") {
      return { message: "整数を入力してください" };
    }
  }

  // invalidなDateになった場合
  if (issue.code === z.ZodIssueCode.invalid_date) {
    return { message: "日付フォーマットが不正です" };
  }
  return { message: ctx.defaultError };
};

export const initializeZodSetting = () => z.setErrorMap(customErrorMap);
