import React, { Component, ErrorInfo, ReactNode } from "react";
import styled from "styled-components";

import { captureException, showReportDialog } from "../../util";

import { Button, Typography } from "~/components/uiParts";

type Props = {
  children: ReactNode;
};

type State = {
  eventId: string;
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { eventId: "", hasError: false };
  }

  static getDerivedStateFromError(): Pick<State, "hasError"> {
    return { hasError: true };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo): Promise<void> {
    const eventId = await captureException({
      error,
      extras: errorInfo,
      tags: {
        type: "Error Boundary",
      },
    });

    this.setState({ eventId });
  }

  private handleClickFeedback = () => showReportDialog({ eventId: this.state.eventId });

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <StyledMain>
          <StyledDiv>
            <Typography variant="h2">エラーが発生しました</Typography>
          </StyledDiv>
          <StyledDiv>
            <Typography>
              {
                "製品の改善のため、どのような状況でエラーが発生したのかについて、\n以下のボタンから送信していただけますと幸いです。"
              }
            </Typography>
          </StyledDiv>
          <Button
            variant="contained"
            borderRadius="circle"
            color="primary"
            onClick={this.handleClickFeedback}
          >
            フィードバックを送信する
          </Button>
        </StyledMain>
      );
    }

    return children;
  }
}

const StyledMain = styled.main`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledDiv = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;
