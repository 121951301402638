import { Tooltip as MuiTooltip } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type Props = ComponentProps<typeof MuiTooltip> & {
  isEllipsis?: boolean;
  isBgTransparent?: boolean;
};

const ellipsisStyle = {
  maxWidth: "320px",
  display: "-webkit-box",
  overflow: "hidden",
  wordBreak: "break-all",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
} as const;

export const Tooltip: FC<Props> = (props) => {
  return (
    <>
      {props.title ? (
        <StyledTooltip
          {...props}
          enterTouchDelay={50}
          title={
            // popperがstyled-componentsに対応していないためインラインで
            <Typography
              variant="caption"
              display="block"
              style={
                props.isEllipsis
                  ? ellipsisStyle
                  : {
                      maxWidth: "320px",
                    }
              }
            >
              {props.title}
            </Typography>
          }
        >
          <StyledSpanWrapper>{props.children}</StyledSpanWrapper>
        </StyledTooltip>
      ) : (
        props.children // titleがfalsyだったときはchildrenのみを返す
      )}
    </>
  );
};

// Tooltipはsvgをラップできない spanをラップするとき、inline-flexである必要がある
const StyledSpanWrapper = styled.span`
  display: inline-flex;
  max-width: 100%;
`;

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ tooltip: className }} />
))`
  &.MuiTooltip-tooltip {
    background-color: ${(props) =>
      props.isBgTransparent ? "transparent" : props.theme.palette.grey[500]};
  }

  & > .MuiTooltip-arrow::before {
    background-color: ${(props) =>
      props.isBgTransparent ? "transparent" : props.theme.palette.grey[500]};
  }
`;
