import { useCallback, useState } from "react";

import { useSnackbar } from "../shared";

import { AccountSettingUseCase } from "~/service/usecases/accountSettingUseCase";

const accountSettingUseCase = new AccountSettingUseCase();
/**
 * idをもとにslackに招待を再送する関数を提供するhooks
 */
export const useResendInvitationToSlack = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * idをもとにslackに招待を再送する
   * @param {string} employeeId
   */
  const resendInvitationToSlack = useCallback(
    async (employeeId: string) => {
      setIsLoading(true);
      await accountSettingUseCase
        .resendInvitationToSlack(employeeId)
        .then((res) => {
          enqueueSnackbar(res.data.message, { variant: "success" });
        })
        .catch(({ message }) => {
          enqueueSnackbar(message, { variant: "error" });
          throw new Error(message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    resendInvitationToSlack,
  };
};
