import { Box, Menu, MenuItem } from "@material-ui/core";
import { Content, isValidUrl } from "@onn/common";
import React, { FC, useState, useEffect, useCallback, MouseEvent } from "react";
import styled from "styled-components";

import { IconButton, Paper, TextareaAutosize, TextField, Typography } from "~/components/uiParts";

const LIBRARY_CONTENT_TITLE_MAX_NUM = 70;

type ContentFormType = Omit<Content, "id"> & { id?: string };

type Props = {
  content: ContentFormType;
  displaySortUpButton?: boolean;
  displaySortDownButton?: boolean;
  onChangeContentForm: (
    content: ContentFormType,
    newContentObject: Partial<ContentFormType>
  ) => void;
  onClickDeleteContent: (contentIndex: number) => void;
  onCLickSortButton: ({ content, isUp }: { content: ContentFormType; isUp: boolean }) => void;
  onChangeContentFormError: (boolean: boolean) => void;
};

export const ContentEditor: FC<Props> = ({
  content,
  displaySortUpButton,
  displaySortDownButton,
  onChangeContentForm,
  onClickDeleteContent,
  onCLickSortButton,
  onChangeContentFormError,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleContentError = useCallback(
    (content: ContentFormType) => {
      const isContentError =
        !content.title.trim() ||
        content.title.trim().length > LIBRARY_CONTENT_TITLE_MAX_NUM ||
        !isValidUrl(content.url) ||
        (content.description ? content.description.trim().length > 100 : false);

      onChangeContentFormError(isContentError);
    },
    [onChangeContentFormError]
  );

  useEffect(() => {
    handleContentError(content);
  }, [content, handleContentError]);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClickSortUpButton = () => {
    setAnchorEl(null);
    onCLickSortButton({ content, isUp: true });
  };

  const handleClickSortDownButton = () => {
    setAnchorEl(null);
    onCLickSortButton({ content, isUp: false });
  };

  const handleClickDeleteContentButton = () => {
    setAnchorEl(null);
    onClickDeleteContent(content.index);
  };

  return (
    <Box key={content.index}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">コンテンツ {content.index}</Typography>
        <IconButton icon="menuVert" onClick={(e) => openMenu(e)} />
        <Menu
          key={content.index}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {displaySortUpButton && (
            <MenuItem onClick={handleClickSortUpButton}>
              <Typography variant="body2">コンテンツを上に移動</Typography>
            </MenuItem>
          )}
          {displaySortDownButton && (
            <MenuItem onClick={handleClickSortDownButton}>
              <Typography variant="body2">コンテンツを下に移動</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={handleClickDeleteContentButton}>
            <Typography variant="body2">コンテンツを削除</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box height="12px" />
      <Paper square>
        <Typography variant="body2" bold>
          タイトル
        </Typography>
        <Box height="8px" />
        <StyledMutedTypography variant="caption">
          コンテンツのタイトルを入力してください。端的に内容がわかるタイトルを推奨しています。次項の記事URLのタイトルは自動反映されません。
        </StyledMutedTypography>
        <Box height="16px" />
        <TextField
          name="title"
          value={content.title}
          fullWidth
          variant="outlined"
          placeholder="例：創業ストーリー"
          onChange={(e) => onChangeContentForm(content, { title: e.target.value })}
          maxTextCount={LIBRARY_CONTENT_TITLE_MAX_NUM}
          error={content.title.length > LIBRARY_CONTENT_TITLE_MAX_NUM}
        />
        <Box height="32px" />
        <Typography variant="body2" bold>
          記事URL
        </Typography>
        <Box height="8px" />
        <StyledMutedTypography variant="caption">
          入社者が閲覧可能な外部記事のリンクを挿入してください。外部リンクにOGP画像が設定されている場合は、入社者ポータルにOGP画像が表示されます。
        </StyledMutedTypography>
        <Box height="16px" />
        <TextField
          name="url"
          value={content.url}
          fullWidth
          variant="outlined"
          placeholder="https://www.onn.xxxxx"
          onChange={(e) => onChangeContentForm(content, { url: e.target.value })}
        />
        <Box height="32px" />
        <Typography variant="body2" bold>
          説明文(任意)
        </Typography>
        <Box height="8px" />
        <StyledMutedTypography variant="caption">
          記事の要約となる説明文を入力してください。このコンテンツを共有した理由や特に注目して欲しいポイントを端的に添えると入社者の理解度がより一層高まります。
        </StyledMutedTypography>
        <Box height="16px" />
        <TextareaAutosize
          name="description"
          value={content.description}
          fullWidth
          placeholder="このコンテンツを共有した理由や特に注目して欲しいポイントなど、どのようなコンテンツなのか伝わる内容を入力しましょう。"
          onChange={(e) => onChangeContentForm(content, { description: e.target.value })}
          minRows={4}
          maxTextCount={100}
          error={content.description ? content.description.length > 100 : undefined}
        />
      </Paper>
    </Box>
  );
};

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
