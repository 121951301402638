import React from "react";
import styled from "styled-components";

import { Linkify, Paper, Typography } from "~/components/uiParts";

/**
 * メッセージのテキストを表示するコンポーネント
 */
export const MessageText = React.memo<{
  variant: "left" | "right";
  messageText: string;
}>(({ variant, messageText }) => {
  return (
    <StyledPaper $variant={variant}>
      <Linkify>
        <StyledTypography variant="body2">{messageText}</StyledTypography>
      </Linkify>
    </StyledPaper>
  );
});

const StyledPaper = styled(Paper)<{ $variant: "left" | "right" }>`
  &.MuiPaper-root {
    padding: 16px;
    border-radius: ${(props) =>
      props.$variant === "left" ? "0 10px 10px 10px" : "10px 0 10px 10px"};
  }
`;

const StyledTypography = styled(Typography)`
  word-break: break-all;
`;
