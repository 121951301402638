import { EMPLOYEE_ACTION_TYPE, EmployeeActiveLog } from "@onn/common";

import { EmployeeActiveLogRepository } from "~/infrastructure/api/employeeActiveLogRepository";

const employeeActiveLogRepository = new EmployeeActiveLogRepository();

export class EmployeeActiveLogUseCase {
  async create({
    employeeId,
    type,
    tenantId,
    targetId,
  }: {
    employeeId: string;
    type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE];
    tenantId: string;
    targetId?: string;
  }): Promise<EmployeeActiveLog> {
    return await employeeActiveLogRepository.create(employeeId, type, tenantId, targetId);
  }

  async findByEmployeeId(employeeId: string, tenantId: string): Promise<EmployeeActiveLog[]> {
    return await employeeActiveLogRepository.findByEmployeeId(employeeId, tenantId);
  }

  async findByEmployeeIdAndType(
    employeeId: string,
    tenantId: string,
    type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE]
  ): Promise<EmployeeActiveLog[]> {
    return await employeeActiveLogRepository.findByEmployeeIdAndType(employeeId, tenantId, type);
  }

  async deleteById(employeeActiveLogId: string): Promise<void> {
    return await employeeActiveLogRepository.deleteById(employeeActiveLogId);
  }
}
