import React, { FC } from "react";

export const Help: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g transform="translate(-42 -439)">
        <g transform="translate(42 439)">
          <path
            d="M10,2a8,8,0,1,0,8,8,8.009,8.009,0,0,0-8-8m0-2A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
            transform="translate(2 2)"
            stroke="none"
          />
          <g transform="translate(-193.6 -152.555)">
            <path
              d="M204.781,165.87l-.283-1.828.062-.056q2.156-.079,2.156-1.027v-.022a.682.682,0,0,0-.3-.592,1.438,1.438,0,0,0-.838-.209,2.687,2.687,0,0,0-1.848.779l-1.133-1.14a4.076,4.076,0,0,1,3.018-1.219,3.507,3.507,0,0,1,2.168.615,2.034,2.034,0,0,1,.813,1.721v.023q0,1.817-2.427,2.268l-.136.688Zm-.407,2.685V166.75h2v1.805Z"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
