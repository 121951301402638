import { Skeleton as MuiSkeleton } from "@material-ui/lab";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type userListType = {
  variant: "userList";
};

type userListTableType = {
  variant: "userListTable";
  columnOptions: {
    variant: "text" | "circle" | "circles" | "rect" | "none";
    typographyVariant?: "body1" | "body2" | "caption";
    grid: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  }[];
};

type Props = userListType | userListTableType;

export const Skeleton: FC<Props> = (props) => {
  switch (props.variant) {
    case "userList":
      return (
        <StyledFlex {...props}>
          <MuiSkeleton variant="circle" width={40} height={40} />
          <StyledTextWrapper>
            <MuiSkeleton variant="text" width="40%" height={16} />
            <MuiSkeleton variant="text" width="60%" height={12} />
          </StyledTextWrapper>
        </StyledFlex>
      );

    case "userListTable":
      return (
        <StyledFlex {...props}>
          {props.columnOptions.map(({ variant, typographyVariant, grid }, i) => {
            // 四角アイコン + 氏名、メールアドレスのような見た目
            if (variant === "rect") {
              return (
                <StyledGrid grid={grid} key={`Skeleton__${i}`}>
                  <StyledFlex>
                    <MuiSkeleton variant="rect" width={40} height={40} />
                    <StyledTextWrapper>
                      <MuiSkeleton variant="text" width="40%" height={16} />
                      <MuiSkeleton variant="text" width="60%" height={12} />
                    </StyledTextWrapper>
                  </StyledFlex>
                </StyledGrid>
              );
            }
            // 丸アイコン + 氏名のような見た目
            else if (variant === "circle") {
              return (
                <StyledGrid grid={grid} key={`Skeleton__${i}`}>
                  <StyledFlex>
                    <MuiSkeleton variant="circle" width={36} height={36} />
                    <StyledTextWrapper>
                      <MuiSkeleton variant="text" width="80%" height={16} />
                    </StyledTextWrapper>
                  </StyledFlex>
                </StyledGrid>
              );
            }
            // 3つ丸アイコンが重なり合う
            else if (variant === "circles") {
              return (
                <StyledGrid grid={grid} key={`Skeleton__${i}`}>
                  <StyledFlex>
                    <MuiSkeleton variant="circle" width={36} height={36} />
                    <StyledStackSkeleton variant="circle" width={36} height={36} />
                    <StyledStackSkeleton variant="circle" width={36} height={36} />
                  </StyledFlex>
                </StyledGrid>
              );
            }
            // テキストのみ
            else if (variant === "text") {
              return (
                <StyledGrid grid={grid} key={`Skeleton__${i}`}>
                  <Typography variant={typographyVariant}>
                    <MuiSkeleton variant="text" width="60%" />
                  </Typography>
                </StyledGrid>
              );
            }
            // スケルトンを表示しない(コンテキストメニューなど)
            else if (variant === "none") {
              return <StyledGrid grid={grid} key={`Skeleton__${i}`}></StyledGrid>;
            }
          })}
        </StyledFlex>
      );
  }
};

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledTextWrapper = styled.div`
  display: inline-block;
  padding-left: 16px;
  width: calc(100% - 40px);
`;

const StyledGrid = styled.div<{ grid: number }>`
  /* undefinedのときは100% */
  width: ${({ grid }) => (grid ? `calc(100% / (12 / ${grid}))` : `100%`)};
`;

const StyledStackSkeleton = styled(MuiSkeleton)`
  margin-left: -8px;
`;
