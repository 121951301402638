import { random } from "lodash";
import React, { FC } from "react";
import styled, { css, keyframes } from "styled-components";

type Props = {
  /**
   * アニメーションの最大duration + 最大delayの合計値
   * 少なくともこの時間内にアニメーションが終了する
   */
  lifetime: number;
  /**
   * emojiをcount個降らせる
   */
  emojiOptions: { emoji: string; count: number }[];
};

const getRandomizedOptions = (maxEachDuration: number) => {
  const rand = random(1, 6);

  // 最大サイズはゆっくり動く
  const options = [
    {
      fontSize: 20,
      duration: maxEachDuration * 0.6 * random(1, 1.5),
    },
    {
      fontSize: 30,
      duration: maxEachDuration * 0.8 * random(1, 1.5),
    },
    {
      fontSize: 40,
      duration: maxEachDuration * 1 * random(1, 1.5),
    },
  ];

  // 1/2で小, 1/3で中, 1/6で大
  if ([1, 2, 3].includes(rand)) return options[0];
  else if ([4, 5].includes(rand)) return options[1];
  else return options[2];
};

export const EmojiConfetti: FC<Props> = ({ emojiOptions, lifetime }) => {
  //  emojiOptionsの割合通りのemojiの配列
  const emojis = emojiOptions
    .map((option) => [...Array(option.count)].map(() => option.emoji))
    .flat();

  const maxDelay = lifetime / 3; // 最大の開始遅延には1/3割り当てる
  const maxEachDuration = (lifetime / 3) * 2; // 最大のアニメーション時間は2/3割り当てる

  return (
    <>
      {emojis.map((emoji, index) => {
        return (
          <StyledSpan
            key={`EmojiConfetti-${index}`}
            randomOption={getRandomizedOptions(maxEachDuration)}
            maxDelay={maxDelay}
          >
            {emoji}
          </StyledSpan>
        );
      })}
    </>
  );
};

const freeFallKeyframes = () => {
  const rand = random(-1, 1, true);

  return keyframes`
  0% {
    opacity: 1;
    top: -20%;
    /* 左右中央を基準としてランダムに放物線状の起動を描く */
    left: 50%;
    transform: rotate(0deg);
  }
  25% {
    opacity: 0.8;
    left: calc(50% + ${rand * 150}px);
  }
  50% {
    opacity: 0.7;
    left: calc(50% + ${rand * 200}px);
  }
  75% {
    opacity: 0.5;
    left: calc(50% + ${rand * 250}px);
  }
  100% {
    opacity: 0.3;
    top: 90%;
    left: calc(50% + ${rand * 300}px);

    /* 反 or 順時計回転しながら落下するものもある */
    ${random(1, 3) === 1 && `transform: rotate(360deg);`}
    ${random(1, 3) === 2 && `transform: rotate(-360deg);`}
    ${random(1, 3) === 3 && `transform: rotate(0deg);`}
  }
`;
};

const StyledSpan = styled.span<{
  randomOption: ReturnType<typeof getRandomizedOptions>;
  maxDelay: number;
}>`
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  user-select: none;
  z-index: ${(props) => props.theme.zIndex.snackbar};
  font-size: ${({ randomOption }) => randomOption && randomOption.fontSize}px;

  ${({ randomOption, maxDelay }) =>
    css`
      animation: ${freeFallKeyframes()} ${randomOption && randomOption.duration}ms linear;
      /* maxの1/4は少なくともdelayし、最大でmaxまでdelayする */
      animation-delay: ${maxDelay / 4 + (maxDelay / 4) * 3 * random(0, 1, true)}ms;
    `};
`;
