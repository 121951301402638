import { Box, Grid, GridSize, Paper } from "@material-ui/core";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { Typography } from "../Typography";

// ====================
// props type
// ====================

type Props<T> = {
  headers: { text: string; key: string }[];
  widthOptions: GridSize[];
  dataArray: T[];
  rowRenderer: (data: T, index: number) => ReactNode;
};

// ====================
// component
// ====================

export const Table = <T extends object>({
  headers,
  widthOptions,
  dataArray,
  rowRenderer,
}: Props<T>) => {
  return (
    <Paper square>
      <StyledTableHeader container alignItems="center">
        {headers.map((header, index) => (
          <Grid key={header.key} item xs={widthOptions[index]}>
            {header.text !== "" && (
              <Typography variant="overline" noWrap color="textSecondary">
                {header.text}
              </Typography>
            )}
          </Grid>
        ))}
      </StyledTableHeader>

      <Grid>
        {dataArray.map((data, index) => {
          return (
            <StyledRow key={index}>
              <StyledBox>{rowRenderer(data, index)}</StyledBox>
            </StyledRow>
          );
        })}
      </Grid>
    </Paper>
  );
};

// ====================
// styles
// ====================

const StyledTableHeader = styled(Grid)`
  &.MuiGrid-root {
    padding: 16px 40px;
  }
`;

const StyledRow = styled(Grid)`
  padding: 0 40px;
  :hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;

const StyledBox = styled(Box)`
  padding: 24px 0;
  border-top: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
