import { useCallback } from "react";

/**
 * コンタクトルーム一覧を表示可能かどうか返す関数を返す hooks
 */
export const useCanDisplayContactRooms = () => {
  /**
   * ポータル画面でコンタクトルーム一覧を表示可能かどうか返す
   */
  const canDisplayContactRooms = useCallback(
    ({
      doesExistsContactRooms,
      doesExistsLatestMessages,
      isContactInitialDisplay,
    }: {
      doesExistsContactRooms: boolean;
      doesExistsLatestMessages: boolean;
      isContactInitialDisplay: boolean;
    }): boolean => {
      if (isContactInitialDisplay) {
        // コンタクトルーム初期表示が有効の場合は、コンタクトルームが存在する場合は表示する
        return doesExistsContactRooms;
      } else {
        // コンタクトルーム初期表示が無効の場合、コンタクトメッセージが存在しない場合は表示しない
        // NOTE: 管理者からメッセージを送るまでは、入社者からはメッセージを送信できないようにするため
        return doesExistsContactRooms && doesExistsLatestMessages;
      }
    },
    []
  );
  return {
    canDisplayContactRooms,
  };
};
