import React from "react";

export const Calendar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M17.958,18.822a2.248,2.248,0,1,1,1.622-.658A2.2,2.2,0,0,1,17.958,18.822Zm-11,3.878A2.207,2.207,0,0,1,4.75,20.491V6.343A2.168,2.168,0,0,1,5.4,4.768,2.11,2.11,0,0,1,6.959,4.11H8.275V3.664a.923.923,0,0,1,.294-.67.923.923,0,0,1,.67-.294.91.91,0,0,1,.682.294.941.941,0,0,1,.282.67V4.11h8.6V3.664a.923.923,0,0,1,.294-.67.923.923,0,0,1,.67-.294.91.91,0,0,1,.682.294.941.941,0,0,1,.282.67V4.11h1.316A2.247,2.247,0,0,1,24.28,6.343V20.491a2.11,2.11,0,0,1-.658,1.563,2.168,2.168,0,0,1-1.575.646Zm0-2.209H22.047V10.6H6.959Zm0-11.3H22.047V6.343H6.959Zm0,0v0Z"
        transform="translate(-2.516 -0.699)"
        stroke="none"
      />
    </svg>
  );
};
