import React, { ComponentProps, FC } from "react";
import { Link as LinkOrigin } from "react-router-dom";

type Props = Optional<ComponentProps<typeof LinkOrigin>, "to">;

export const Link: FC<Props> = ({ to, children, ...props }) => {
  if (to) {
    return (
      <LinkOrigin to={to} {...props}>
        {children}
      </LinkOrigin>
    );
  }

  return <>{children}</>;
};
