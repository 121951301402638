import React, { FC } from "react";

export const CheckMarkFill: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        fill="#27E0B7"
      />
      <path
        d="M10.6572 16.9839C10.4602 16.984 10.2651 16.9454 10.083 16.8701C9.90096 16.7948 9.73506 16.6842 9.5957 16.5449L6.05859 13.011C5.91905 12.8717 5.80893 12.7063 5.7334 12.5242C5.65787 12.342 5.61914 12.1466 5.61914 11.9495C5.61914 11.7523 5.65787 11.5571 5.7334 11.375C5.80893 11.1929 5.91905 11.0273 6.05859 10.8879C6.19791 10.7484 6.36376 10.6378 6.5459 10.5623C6.72804 10.4867 6.92293 10.4478 7.12012 10.4478C7.3173 10.4478 7.51317 10.4867 7.69531 10.5623C7.87746 10.6378 8.04232 10.7484 8.18164 10.8879L10.6572 13.365L15.9629 8.06006C16.2441 7.77946 16.6252 7.62183 17.0225 7.62183C17.4197 7.62183 17.8008 7.77946 18.082 8.06006C18.2216 8.19938 18.3327 8.36473 18.4082 8.54688C18.4837 8.72902 18.5225 8.9244 18.5225 9.12158C18.5225 9.31876 18.4837 9.5139 18.4082 9.69604C18.3327 9.87819 18.2216 10.0438 18.082 10.1831L11.7168 16.5449C11.578 16.6844 11.4132 16.7953 11.2314 16.8706C11.0497 16.946 10.854 16.9843 10.6572 16.9839Z"
        fill="white"
      />
    </svg>
  );
};
