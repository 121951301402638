import { SWRResponse } from "swr";
import useImmutableSWR from "swr/immutable";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

/**
 * pathsをもとにファイルにアクセスできるurlのリストを取得するswr
 */
export const useFilesUrls = (paths?: string[]): SWRResponse<{ [key: string]: string }, Error> => {
  const key = paths ? paths : null;

  return useImmutableSWR(key, async (paths) => {
    const fetchFilePath = (path: string) => fileAPIAdapter.fetchUrl({ path });
    return Object.fromEntries(
      await Promise.all(
        paths.map(async (path) => {
          const url = await fetchFilePath(path);
          return [path, url];
        })
      )
    );
  });
};
