import { LatestContactMessage } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const useLatestContactMessage = ({
  tenantId,
  contactRoomIds,
  limit,
}: {
  tenantId: string;
  contactRoomIds?: string[];
  limit?: number;
}) => {
  const keyObj = {
    endpoint: `/get_contact_rooms_latest_messages`,
    contactRoomIds,
    tenantId,
    limit,
  } as const;

  return useSWR(keyObj, async ({ endpoint, contactRoomIds, limit }) => {
    const response = await apiClient.get(endpoint, { limit, contactRoomIds });
    return response.data.map((d) => {
      return new LatestContactMessage(d);
    });
  });
};
