import { GeneralTaskSettings } from "@onn/common";
import { useCallback, useState } from "react";

import { useNotifyOperationLog, useSnackbar } from "../shared";

import { useCurrentUser } from "~/hooks/employee";
import { UpdateGeneralTaskSettingsUseCase } from "~/service/usecases/tenantSettings";
import { captureException } from "~/util";

const useCase = new UpdateGeneralTaskSettingsUseCase();

/**
 * 共通タスクを更新する関数を提供するhooks
 */
export const useUpdateGeneralTaskSettings = (): {
  isLoading: boolean;
  updateGeneralTaskSettings: (newObject: GeneralTaskSettings) => Promise<void>;
} => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { operationLog, notifyOperationLog } = useNotifyOperationLog();

  const [isLoading, setIsLoading] = useState(false);

  /**
   * 共通タスクを更新する関数
   * @param {GeneralTaskSettings} newObject 共通タスクの設定値
   */
  const updateGeneralTaskSettings = useCallback(
    async (newObject: GeneralTaskSettings) => {
      setIsLoading(true);

      return useCase
        .execute(currentUser, newObject)
        .then(() => {
          notifyOperationLog(operationLog.updateGeneralTaskSettings(currentUser));
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useUpdateGeneralTaskSettings:updateGeneralTaskSettings",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [currentUser, enqueueSnackbar, notifyOperationLog, operationLog]
  );

  return {
    isLoading,
    updateGeneralTaskSettings,
  };
};
