import { useContext } from "react";

import { FileViewerContext } from "~/components/providers/FileViewerProvider";

/**
 * FileViewerを扱うためのhooks
 * @returns
 */
export const useFileViewer = () => {
  const { setFiles, setPreviewFileIndex, handleDownloadFile } = useContext(FileViewerContext);

  return { setFiles, setPreviewFileIndex, handleDownloadFile };
};
