import { SnackbarProvider as SnackbarProviderOrigin } from "notistack";
import React, { FC, forwardRef } from "react";

import { ActionSnackbar, Snackbar } from "~/components/uiParts";

type Props = {
  children: React.ReactNode;
};

export const SnackbarProvider: FC<Props> = ({ children }) => {
  return (
    <SnackbarProviderOrigin
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      /** @TODO: mui のバージョンアップ後に notistack のバージョンアップをして ignore を外す. mui の依存関係があるため */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      content={(
        key,
        message: {
          text: string;
          variant: "success" | "error" | "info";
        }
      ) => {
        return <SnackBarWrapper id={key} message={message} />;
      }}
    >
      {children}
    </SnackbarProviderOrigin>
  );
};

const SnackBarWrapper = forwardRef<
  // textは共通して必須
  // ActionSnackbarの場合、onActionとactionLabelのみ必須
  // Snackbarの場合、variantのみ必須
  HTMLDivElement,
  {
    id: string | number;
    message: { text: string } & (
      | {
          variant?: never;
          onAction: () => void;
          actionLabel: string;
        }
      | {
          variant: "success" | "error" | "info";
          onAction?: never;
          actionLabel?: never;
        }
    );
  }
>((props, ref) => {
  return (
    <div ref={ref}>
      {props.message.onAction ? (
        <ActionSnackbar
          text={props.message.text}
          actionLabel={props.message.actionLabel}
          onAction={props.message.onAction}
        />
      ) : (
        <Snackbar text={props.message.text} color={props.message.variant} />
      )}
    </div>
  );
});
