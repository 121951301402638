import { Maintenance } from "@onn/common";
import { SWRResponse } from "swr";
import immutableSWR from "swr/immutable";

import { MaintenanceUseCase } from "~/service/usecases/maintenanceUseCase";

/**
 * メンテナンスを取得する SWR
 * @returns {Maintenance} maintenance document
 */
export const useMaintenance = (): SWRResponse<Maintenance, Error> => {
  return immutableSWR("maintenance", () => MaintenanceUseCase.currentStatus().then((res) => res));
};
