import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, Button, Paper, LinearProgress } from "~/components/uiParts";
import { Typography } from "~/components/uiParts/Typography";

type tutorialItem = {
  isCompleted: boolean;
  onClick?: () => void;
  text: string;
};

type Props = {
  title: string;
  tutorialItems: Array<tutorialItem>;
  onClose: () => void;
};

const calcPercentageOfCompletion = (tutorialItems: Array<tutorialItem>): number => {
  const completedItems = tutorialItems.filter((item) => item.isCompleted);
  return (completedItems.length / tutorialItems.length) * 100;
};

export const TutorialCard: FC<Props> = (props) => {
  const { title, tutorialItems, onClose } = props;

  return (
    <StyledPaper border="primary">
      <Typography variant="body1" bold>
        {title}
      </Typography>
      <Box mt={2} mb={3}>
        <StyledLinearProgress
          variant="determinate"
          color="primary"
          value={calcPercentageOfCompletion(tutorialItems)}
        />
      </Box>
      {tutorialItems.map((item, i) => {
        return (
          <Box display="flex" mb={2} key={i}>
            {item.isCompleted ? (
              <StyledBoxForCheckCircle mr={1}>
                <Icon color="primary" icon="checkCircle" size="sm" />
              </StyledBoxForCheckCircle>
            ) : (
              <StyledCircle mr={1} />
            )}

            <StyledTypography variant="body2" onClick={item.onClick && item.onClick}>
              {item.text}
            </StyledTypography>
          </Box>
        );
      })}
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onClose} borderRadius="regular" variant="outlined" color="default">
          閉じる
        </Button>
      </Box>
    </StyledPaper>
  );
};

const StyledTypography = styled(Typography)<{ onClick?: () => void }>`
  &.MuiTypography-root {
    cursor: ${(props) => props.onClick && `pointer`};
  }
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledBoxForCheckCircle = styled(Box)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledPaper = styled(Paper)`
  width: 320px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  padding: 24px;
`;

// Override LinearProgress height
const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-determinate {
    height: 8px;
  }

  & > .MuiLinearProgress-bar {
    height: 8px;
  }
`;
