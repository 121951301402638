export type Result<T, E extends Error> = Success<T> | Failure<T, E>;

export class Success<T> {
  readonly value: T;

  constructor(value: T) {
    this.value = value;
  }
  map<V>(func: (arg: T) => V): Success<V> {
    return new Success(func(this.value));
  }
  isSuccess(): this is Success<T> {
    return true;
  }
  isFailure(): this is Failure<T, Error> {
    return false;
  }
}

export class Failure<T, E extends Error> {
  readonly error: E;

  constructor(error: E) {
    this.error = error;
  }
  map<V>(_func: (arg: T) => V): Failure<T, E> {
    return this;
  }
  isSuccess(): this is Success<unknown> {
    return false;
  }
  isFailure(): this is Failure<T, E> {
    return true;
  }
}
