import React, { FC } from "react";

export const Upload: FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77539 19C5.10902 19.0073 4.45754 18.8001 3.91797 18.409C3.63966 18.2106 3.41136 17.9498 3.25195 17.6475C3.09254 17.3452 3.00648 17.0097 3 16.668V14C3 13.7348 3.10543 13.4804 3.29297 13.2929C3.48051 13.1054 3.73478 13 4 13C4.26522 13 4.51949 13.1054 4.70703 13.2929C4.89457 13.4804 5 13.7348 5 14V16.667C5 16.73 5.26039 16.998 5.77539 16.998H18.2246C18.7406 16.998 18.998 16.73 18.998 16.667V14C18.998 13.7348 19.1035 13.4804 19.291 13.2929C19.4786 13.1054 19.7328 13 19.998 13C20.2633 13 20.5195 13.1054 20.707 13.2929C20.8946 13.4804 20.998 13.7348 20.998 14V16.667C20.9917 17.0094 20.9047 17.3455 20.7441 17.6479C20.5836 17.9504 20.3542 18.2108 20.0742 18.408C19.5359 18.7993 18.8862 19.0065 18.2207 18.999L5.77539 19ZM12 5H11.9512H11.9258H11.9023H11.8711H11.8555L11.8164 5.00598H11.8086L11.7676 5.01501H11.7617L11.7168 5.02698C11.5391 5.07941 11.3796 5.18017 11.2559 5.31799L7.29688 9.27698C7.20324 9.36927 7.12888 9.4793 7.07812 9.60059C7.02737 9.72187 7.00195 9.85204 7.00195 9.98352C7.00195 10.115 7.02737 10.2452 7.07812 10.3665C7.12888 10.4877 7.20324 10.5977 7.29688 10.69C7.38926 10.7837 7.49972 10.858 7.62109 10.9088C7.74246 10.9596 7.87235 10.9857 8.00391 10.9857C8.13547 10.9857 8.26535 10.9596 8.38672 10.9088C8.50809 10.858 8.61856 10.7837 8.71094 10.69L11 8.40002V14C11 14.2652 11.1054 14.5196 11.293 14.7071C11.4805 14.8946 11.7348 15 12 15C12.2652 15 12.5195 14.8946 12.707 14.7071C12.8946 14.5196 13 14.2652 13 14V8.409L15.2949 10.703C15.3873 10.7967 15.4978 10.871 15.6191 10.9218C15.7405 10.9726 15.8704 10.9987 16.002 10.9987C16.1335 10.9987 16.2634 10.9726 16.3848 10.9218C16.5061 10.871 16.6166 10.7967 16.709 10.703C16.8961 10.5155 17.002 10.2614 17.002 9.99652C17.002 9.7316 16.8961 9.47746 16.709 9.28998L12.7578 5.34399C12.6327 5.20104 12.4696 5.09694 12.2871 5.04401L12.2461 5.03302H12.2383L12.1992 5.02502H12.1875L12.1523 5.01898H12.1348H12.1055H12.0801H12.0547L12 5Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  );
};
