import { TenantSettings } from "@onn/common";
import { useState, useCallback } from "react";

import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";

/**
 * テナント設定を更新する
 * @returns isLoading データを更新中かどうか
 * @returns updateTenantSettings TenantSettingsを更新する関数
 */
export const useUpdateTenantSettings = (): {
  isLoading: boolean;
  updateTenantSettings: (tenantId: string, newObject: Partial<TenantSettings>) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);

  const updateTenantSettings = useCallback(
    async (tenantId: string, newObject: Partial<TenantSettings>) => {
      setIsLoading(true);

      return TenantSettingsUseCase.update(tenantId, newObject)
        .then((res) => res)
        .finally(() => setIsLoading(false));
    },
    []
  );

  return {
    isLoading,
    updateTenantSettings,
  };
};
