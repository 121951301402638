import { useEffect, useRef } from "react";

/**
 * stateやpropsなど一つ前の値を保持しておくためのhooks
 * NOTE: https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
 * @param value 保持する値
 * @returns
 */
export const usePreviousValue = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return { value: ref.current };
};
