import { styled } from "@material-ui/core/styles";
import React, { useState } from "react";

type Props = {
  children: React.ReactNode;
  pressed?: boolean;
  onPressedChange?: (pressed: boolean) => void;
} & React.HTMLAttributes<HTMLButtonElement>;

const Toggle = React.forwardRef<HTMLButtonElement, Props>(function Toggle(
  { children, pressed, onPressedChange, ...props },
  ref
) {
  const isControlled = pressed !== undefined;
  const [innerPressed, setInnerPressed] = useState(false);

  return (
    <Button
      ref={ref}
      {...props}
      type="button"
      aria-pressed={isControlled ? pressed : innerPressed}
      onClick={(e) => {
        setInnerPressed((prev) => !prev);
        onPressedChange?.(!pressed);
        props.onClick?.(e);
      }}
    >
      {children}
    </Button>
  );
});

const Button = styled("button")(({ theme }) => ({
  padding: 8,
  cursor: "pointer",
  borderRadius: 5,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[400],
  ['&[aria-pressed="true"]']: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));

export { Toggle };
