import { FC, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCurrentUser } from "~/hooks/employee";

export const I18nProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    i18n.changeLanguage(currentUser.lang);
  }, [currentUser.lang, i18n]);

  return children;
};
