import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { ReactNode, useState, MouseEvent, FC, useCallback } from "react";
import styled from "styled-components";

import { IconButton } from "~/components/uiParts/IconButton";
import { TooltipWhenTextTruncated } from "~/components/uiParts/TooltipWhenTextTruncated";
import { Typography } from "~/components/uiParts/Typography";
import { useClipboard } from "~/hooks/shared";

type Props = {
  title: string;
  caption: string;
  disabledMenuButton?: boolean;
  shareLink: string;
  content?: ReactNode;
  onClickAddCommentButton?: () => void;
  onClickOpenReactionModal?: () => void;
  onClickManageMessageButton?: () => void;
};

export const ActivityContentItem: FC<Props> = ({
  title,
  caption,
  disabledMenuButton,
  shareLink,
  content,
  onClickAddCommentButton,
  onClickOpenReactionModal,
  onClickManageMessageButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { handleCopy } = useClipboard();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCopyButton = useCallback(() => {
    handleCopy(shareLink, "リンク");
    handleClose();
  }, [handleCopy, shareLink]);

  const handleClickMenuItem = useCallback((callback: () => void) => {
    handleClose();
    callback();
  }, []);

  return (
    <Box width={1} sx={{ width: "605.5px" }}>
      <Box
        display="flex"
        alignItems="center"
        mb={3}
        height={24} // 高さ調整のための指定 TODO: IconButton のデザイン fix 後修正
      >
        <Box display="flex" mr={2} maxWidth="65%">
          <TooltipWhenTextTruncated text={title}>
            {(ref) => (
              <StyledTitleTypography variant="body1" noWrap ref={ref}>
                {title}
              </StyledTitleTypography>
            )}
          </TooltipWhenTextTruncated>
        </Box>
        <Box display="flex" mr="auto">
          <Typography variant="caption" noWrap>
            {caption}
          </Typography>
        </Box>
        {!disabledMenuButton && (
          <Box>
            <IconButton onClick={handleClick} icon="menuVert" />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {onClickOpenReactionModal && (
                <MenuItem
                  key="send-reaction"
                  onClick={() => handleClickMenuItem(onClickOpenReactionModal)}
                >
                  <Typography variant="body2">入社者へリアクションを送る</Typography>
                </MenuItem>
              )}
              <MenuItem key="copy-share-link" onClick={handleClickCopyButton}>
                <Typography variant="body2">共有リンクをコピー</Typography>
              </MenuItem>
              {onClickManageMessageButton && (
                <MenuItem onClick={() => handleClickMenuItem(onClickManageMessageButton)}>
                  <Typography variant="body2">記入依頼設定</Typography>
                </MenuItem>
              )}
              {onClickAddCommentButton && (
                <MenuItem
                  key="add-comment"
                  onClick={() => handleClickMenuItem(onClickAddCommentButton)}
                >
                  <Typography variant="body2">コメントを追加</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
      </Box>
      {content}
    </Box>
  );
};

const StyledTitleTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 20px;
  }
`;
