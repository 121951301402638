// eslint-disable-next-line no-restricted-imports
import { OptionsObject, SnackbarKey, useSnackbar as useSnackbarOrigin } from "notistack";
import { useCallback } from "react";

type Options = ChangeTypeOfKeys<OptionsObject, "variant", "error" | "success" | "info"> & {
  onAction?: () => void;
  actionLabel?: string;
};

/**
 * customSnackbarでoptionを使うためにuseSnackbarをwrapするhooks
 * @see https://github.com/iamhosseindhv/notistack/issues/218#issuecomment-874502937
 * @returns enqueueSnackbar
 */
export const useSnackbar = (): {
  enqueueSnackbar: (message: string, options: Options) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
} => {
  const { enqueueSnackbar: enqueueSnackbar_, ...others } = useSnackbarOrigin();

  const enqueueSnackbar = useCallback(
    (message: string, options: Options) => {
      /** @TODO: mui のバージョンアップ後に notistack のバージョンアップをして ignore を外す. mui の依存関係があるため */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return enqueueSnackbar_({ text: message, ...options }, options);
    },
    [enqueueSnackbar_]
  );
  return { enqueueSnackbar, ...others };
};
