import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  disabled?: boolean;
};

export const Indeterminate: FC<Props> = ({ disabled = false }) => (
  <svg id="checkbox" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <StyledRectangle id="長方形_1702" width="18" height="18" rx="4" $disabled={disabled} />
    <g id="グループ_1994" data-name="グループ 1994" transform="translate(-332.679 -247.946)">
      <g id="グループ_274" data-name="グループ 274" transform="translate(43.879 88.119)">
        <rect
          id="長方形_627"
          data-name="長方形 627"
          width="10"
          height="2"
          rx="1"
          transform="translate(292.801 167.828)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const StyledRectangle = styled.rect<{ $disabled: boolean }>`
  fill: ${(props) =>
    props.$disabled ? props.theme.palette.grey[300] : props.theme.palette.primary.main};
`;
