import { Grid, Box } from "@material-ui/core";
import React, { useEffect, useState, FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { UserIcon, Paper, Typography, Button } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useTenant } from "~/hooks/tenant";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { mixin } from "~/util";

type SituationType = "NEW_HIRE_PROFILE" | "NOT_SELECTED";

type LocationState = {
  from?: string;
};

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

export const Complete: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const [situation, setSituation] = useState<SituationType>("NOT_SELECTED");
  const [logoUrl, setLogoUrl] = useState<string>();

  const loadLogo = useCallback(async () => {
    const logoUrl = await fileAPIAdapter.fetchUrl({
      path: `public/uploads/logo/${currentUser.tenantId}`,
    });
    setLogoUrl(logoUrl);
  }, [currentUser.tenantId]);

  const loadSituation = useCallback((): void => {
    if (!location.state) {
      navigate("/");
    } else {
      const { from } = location.state as LocationState;
      switch (from) {
        case "profile": {
          setSituation("NEW_HIRE_PROFILE");
          break;
        }
        default: {
          setSituation("NOT_SELECTED");
          break;
        }
      }
    }
  }, [location.state, navigate]);

  useEffect(() => {
    loadLogo();
  }, [loadLogo]);

  useEffect(() => {
    loadSituation();
  }, [loadSituation]);

  const title = (): string => {
    switch (situation) {
      case "NEW_HIRE_PROFILE": {
        return "Profile";
      }
      default: {
        return "新規登録完了";
      }
    }
  };

  const isDisplayImage: boolean = ["NEW_HIRE_PROFILE"].includes(situation);

  const messagesBySituation = (): JSX.Element => {
    switch (situation) {
      case "NEW_HIRE_PROFILE":
        return (
          <Box mb={3} textAlign="center">
            <Typography variant="body1">
              これで入力は完了です！この自己紹介はバディやチームメンバーに共有されます！入社日にお会いできるのを楽しみにしています！
            </Typography>
          </Box>
        );
      default:
        return (
          <Box textAlign="center">
            <Typography variant="body1">
              入社前から新しいメンバーを気持ちよく迎え入れるために、welcomeメッセージの記入などのお願いを通知させていただきますので、よろしくお願いいたします
            </Typography>
          </Box>
        );
    }
  };

  return (
    <StyledBox maxWidth="848px">
      <Typography variant="h2">{title()}</Typography>
      <StyledPaper>
        <Box>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={10} sm={11}>
              <Box mb={5} textAlign="center">
                <Typography variant="h2" bold color="primary">
                  THANK YOU!
                </Typography>
              </Box>

              {messagesBySituation()}

              {isDisplayImage && (
                <Box display="table" textAlign="center" margin="0 auto">
                  <Box mb={1} display="inline-block">
                    <UserIcon
                      circular
                      borderColor="primary"
                      size="small"
                      username={tenant.tenantName}
                      profileIconImageUrl={logoUrl}
                    />
                  </Box>
                  <Typography variant="caption" bold display="block">
                    {tenant.tenantName}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
      <Box mt={4} mb={27} display="flex" flexDirection="column">
        <Button
          variant="text"
          borderRadius="regular"
          color="primary"
          onClick={() => navigate("/portal/onboarding_tasks")}
        >
          タスク一覧に戻る
        </Button>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: 100vh;
  padding: 80px 24px;

  ${mixin.breakDown.sm`
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledPaper = styled(Paper)`
  margin-top: 28px;
  ${mixin.breakDown.sm`
    margin-top: 24px;
  `}
`;
