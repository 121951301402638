import { TenantSettings } from "@onn/common";
import { useContext } from "react";
import { KeyedMutator } from "swr";

import { TenantSettingsContext } from "~/components/providers";

export const useTenantSettings = (): {
  tenantSettings: TenantSettings;
  mutateTenantSettings: KeyedMutator<TenantSettings>;
} => {
  const { tenantSettings, mutateTenantSettings } = useContext(TenantSettingsContext);

  if (tenantSettings === undefined) {
    throw new Error("会社設定の取得に失敗しました");
  }
  return { tenantSettings, mutateTenantSettings };
};
