import React, { FC } from "react";
import styled from "styled-components";

import { DifferenceSatisfaction } from "~/components/uiParts/DifferenceSatisfaction";

type Props = {
  latelySatisfaction?: number;
  previousSatisfaction?: number;
  hasPrevious?: boolean;
};

export const SatisfactionWithChart: FC<Props> = ({
  latelySatisfaction,
  previousSatisfaction,
  hasPrevious,
}) => {
  return (
    <StyledContainer>
      <DifferenceSatisfaction
        latelySatisfaction={latelySatisfaction}
        previousSatisfaction={previousSatisfaction}
        hasPrevious={hasPrevious}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
`;
