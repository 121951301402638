import { useEffect, useState, useCallback } from "react";

type Size = {
  width: number;
  height: number;
};

/**
 * 画像のオリジナルサイズを取得するhooks
 *
 * @param src 対象画像要素のsrc属性
 */
export const useImageOriginalSize = (src: string): Size | undefined => {
  const [size, setSize] = useState<Size | undefined>();

  const initialize = useCallback(async () => {
    setSize(undefined);

    const { naturalWidth, naturalHeight } = await loadImageAsync(src);

    setSize({ width: naturalWidth, height: naturalHeight });
  }, [src]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return size;
};

const loadImageAsync = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  });
