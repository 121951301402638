import { FileType } from "@onn/common";
import React, { ReactNode, useCallback, DragEvent, FC } from "react";

import { DropArea } from "~/components/uiParts/DropArea";
import { filterFilesByType } from "~/util/fileUtil";

type Payload = { status: "success"; files: File[] } | { status: "error"; message: string };

type Props = {
  children: ReactNode;
  accepts?: FileType[];
  maxFileSizeMb?: number;
  onDropFiles: (payload: Payload) => void;
};

// webkitGetAsEntry()の戻り値の型がanyなので、仕方なくany
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FileEntry = any;

// createReader()の戻り値の型がanyなので、仕方なくany
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DirectoryReader = any;

export const FileDropArea: FC<Props> = ({ children, accepts = [], maxFileSizeMb, onDropFiles }) => {
  const handleDropFiles = useCallback(
    async (e: DragEvent) => {
      if (!e.dataTransfer) {
        return;
      }
      const payload = await getDropFilesPayloadAsync(e.dataTransfer, { accepts, maxFileSizeMb });

      onDropFiles(payload);
    },
    [accepts, maxFileSizeMb, onDropFiles]
  );

  return <DropArea onDrop={handleDropFiles}>{children}</DropArea>;
};

const getDropFilesPayloadAsync = async (
  dataTransfer: DataTransfer,
  { accepts, maxFileSizeMb }: Pick<Props, "accepts" | "maxFileSizeMb">
): Promise<Payload> => {
  const files = await getFilesAsync(dataTransfer.items);

  if (!files.length) {
    return {
      status: "error",
      message: "ファイルを選択してください。",
    };
  }

  if (accepts && filterFilesByType(files, accepts).length !== files.length) {
    return {
      status: "error",
      message: "対応していない形式のファイルが含まれています",
    };
  }

  if (maxFileSizeMb && files.some((file) => file.size > maxFileSizeMb * 1024 * 1024)) {
    return {
      status: "error",
      message: `${maxFileSizeMb}MB以下のファイルのみアップロード可能です`,
    };
  }

  return {
    status: "success",
    files,
  };
};

/**
 * ドロップされたオブジェクトから読み込み可能なファイルを取得する
 *
 * @param items ドロップされたオブジェクト
 */
const getFilesAsync = async (items: DataTransferItemList): Promise<File[]> => {
  const fileEntries: FileEntry[] = [];

  await Promise.all(
    Array.from(items)
      .filter((item) => item.kind === "file" && typeof item.webkitGetAsEntry === "function")
      .map(async (item) => {
        const entry = item.webkitGetAsEntry() as FileSystemDirectoryEntry | null;
        if (!entry) return;

        if (entry.isFile) {
          fileEntries.push(entry);
        } else if (entry.isDirectory) {
          const directoryReader = entry.createReader();
          const entries = await readDirectoryAsync(directoryReader, []);

          fileEntries.push(...entries);
        }
      })
  );

  const files = await Promise.all(fileEntries.map(readFileAsync));

  return files;
};

/**
 * ディレクトリを読み込み、読み込みに成功したファイルエントリを返す
 */
const readDirectoryAsync = async (
  directoryReader: DirectoryReader,
  currentFileEntries: FileEntry[]
): Promise<FileEntry[]> => {
  await new Promise((resolve) => {
    // ブラウザの仕様で、フォルダ中の100件以上のファイルを読み込むためには繰り返し readEntries() メソッドをコールする必要がある。
    // そのため、entriesが0件になるまで再帰的に関数を実行する
    // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader/readEntries#JavaScript_content
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    directoryReader.readEntries(async (entries: any[]) => {
      if (entries.length !== 0) {
        currentFileEntries.push(...entries);
        await readDirectoryAsync(directoryReader, currentFileEntries);
      }
      return resolve(currentFileEntries);
    });
  });

  return currentFileEntries;
};

/**
 * ファイルエントリからファイルを読み込む
 */
const readFileAsync = (fileEntry: FileEntry): Promise<File> => {
  return new Promise<File>((resolve) =>
    fileEntry.file((file: File) => {
      const reader = new FileReader();
      reader.onload = () => resolve(file);
      reader.readAsText(file);
    })
  );
};
