import React, { FC } from "react";

export const Trash: FC = () => {
  return (
    <svg id="icon_trash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="Group_207" transform="translate(3 4)">
        <g id="Rectangle_432" transform="translate(2 3)" fill="none" strokeWidth="2">
          <rect width="14" height="14" rx="2" stroke="none" />
          <rect x="1" y="1" width="12" height="12" rx="1" fill="none" />
        </g>
        <g id="Rectangle_441" transform="translate(4)" fill="#fff" strokeWidth="2">
          <rect width="10" height="5" rx="2" stroke="none" />
          <rect x="1" y="1" width="8" height="3" rx="1" fill="none" />
        </g>
        <g id="Rectangle_438" transform="translate(0 3)" fill="#fff" strokeWidth="1">
          <rect width="18" height="2" rx="1" stroke="none" />
          <rect x="0.5" y="0.5" width="17" height="1" rx="0.5" fill="none" />
        </g>
        <g id="Rectangle_439" transform="translate(12 7) rotate(90)" fill="#fff" strokeWidth="1">
          <rect width="6" height="2" rx="1" stroke="none" />
          <rect x="0.5" y="0.5" width="5" height="1" rx="0.5" fill="none" />
        </g>
        <g id="Rectangle_440" transform="translate(8 7) rotate(90)" fill="#fff" strokeWidth="1">
          <rect width="6" height="2" rx="1" stroke="none" />
          <rect x="0.5" y="0.5" width="5" height="1" rx="0.5" fill="none" />
        </g>
      </g>
    </svg>
  );
};
