import { TenantSettings } from "@onn/common";
import React, { FC, createContext, ReactNode } from "react";
import { KeyedMutator } from "swr";

import { useTenantSettingsByTenantId } from "../../hooks/tenantSetting/useTenantSettingsByTenantId";

import { useCurrentUser } from "~/hooks/employee";
export const TenantSettingsContext = createContext<{
  tenantSettings: TenantSettings | undefined;
  mutateTenantSettings: KeyedMutator<TenantSettings>;
}>({
  tenantSettings: undefined,
  mutateTenantSettings: () => new Promise(() => undefined),
});

export const TenantSettingsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  const { data: tenantSettings, mutate: mutateTenantSettings } = useTenantSettingsByTenantId(
    currentUser.tenantId
  );

  if (!tenantSettings) return null;

  return (
    <TenantSettingsContext.Provider value={{ tenantSettings, mutateTenantSettings }}>
      {children}
    </TenantSettingsContext.Provider>
  );
};
