import { Failure, Result, Success } from "../shared";

import { IContactRoomUnreadCountSchema, contactRoomUnreadCountSchema } from "./schema";

export class ContactRoomUnreadCount implements IContactRoomUnreadCountSchema {
  private static validator = contactRoomUnreadCountSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly contactRoomId: string;

  constructor(init: ExcludeMethods<ContactRoomUnreadCount>) {
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.contactRoomId = init.contactRoomId;
  }

  static validate(
    data: ExcludeMethods<ContactRoomUnreadCount>
  ): Result<ContactRoomUnreadCount, Error> {
    const result = ContactRoomUnreadCount.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new ContactRoomUnreadCount(data));
  }

  public static create(params: {
    contactRoomId: string;
    tenantId: string;
  }): ContactRoomUnreadCount {
    return new ContactRoomUnreadCount({
      id: params.contactRoomId, // サブコレクションの更新をしやすくするため、idをcontactRoomIdにしている
      contactRoomId: params.contactRoomId,
      tenantId: params.tenantId,
    });
  }
}
