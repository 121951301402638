import React, { FC } from "react";

export const Email: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="none">
      <g transform="translate(10861.284 -14058.716)">
        <rect
          width="24"
          height="24"
          rx="12"
          transform="translate(-10861.284 14058.716)"
          fill="#959595"
        />
        <path
          d="M3.953,17.549a1.637,1.637,0,0,1-1.2-.5,1.611,1.611,0,0,1-.5-1.176V7.26a1.611,1.611,0,0,1,.5-1.176,1.637,1.637,0,0,1,1.2-.5H15.5a1.637,1.637,0,0,1,1.2.5,1.611,1.611,0,0,1,.5,1.176v8.611a1.611,1.611,0,0,1-.5,1.176,1.637,1.637,0,0,1-1.2.5ZM15.5,8.765l-5.319,3.406a.887.887,0,0,1-.246.112.872.872,0,0,1-.211.026.841.841,0,0,1-.22-.026.936.936,0,0,1-.237-.112L3.953,8.765v7.107H15.5ZM9.729,10.943,15.5,7.26H3.953ZM3.953,8.765v0Z"
          transform="translate(-10858.979 14059.184)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
