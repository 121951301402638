import { Tenant } from "@onn/common";
import { doc, DocumentData, deleteField, updateDoc, DocumentReference } from "firebase/firestore";

import { firestore } from "../../config/firebase";

import { apiClient } from "~/libs";
import { ITenantRepository } from "~/service/repository/ITenantRepository";

const COLLECTION_NAME = "tenants";

export class TenantRepository implements ITenantRepository {
  async get(): Promise<Tenant | null> {
    const tenant = await apiClient.get("/get_tenant").then((res) => res.data);

    return Tenant.plainToInstance(tenant);
  }
  async deleteSlackAccessToken(tenantId: string): Promise<void> {
    await updateDoc(this.doc(tenantId), { slackAccessToken: deleteField() });
  }
  async updateTenantName(tenantId: string, tenantName: string): Promise<void> {
    await updateDoc(this.doc(tenantId), { name: tenantName });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }
}
