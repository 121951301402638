import { Revision } from "@onn/common";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";

import { ShouldRefreshPageModal } from "../domains/revisions";

import { useRevision } from "~/hooks/revision/useRevision";
import { useLocalStorage, useSnackbar } from "~/hooks/shared";
import { RevisionUseCase } from "~/service/usecases/revisionUseCase";

const revisionUseCase = new RevisionUseCase();

function refreshPage() {
  window.location.reload();
}

export const RevisionProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const localStorage = useLocalStorage();
  const { enqueueSnackbar } = useSnackbar();
  const [showForceUpdate, setShowForceUpdate] = useState(false);
  const { data: revision } = useRevision();
  const [isCheckedRevision, setIsCheckedRevision] = useState(false);

  const currentRevisionObj = localStorage.retrieveValue<DeepExcludeMethods<Revision>>("revision");
  const currentRevision = currentRevisionObj
    ? Revision.plainToInstance(currentRevisionObj)
    : undefined;

  useEffect(() => {
    if (!revision) return;

    // 初回起動時の場合は、最新のrevisionを保存して画面を更新して終了
    if (!currentRevision) {
      localStorage.storeValue("revision", revision);
      refreshPage();
      return;
    }

    // すでにチェックしていたら何もしない
    if (isCheckedRevision) return;

    const updateType = currentRevision.detectUpdateType(revision);
    if (updateType === "force") {
      localStorage.storeValue("revision", revision);
      refreshPage();
      return;
    }
    setIsCheckedRevision(true);
  }, [localStorage, revision, currentRevision, isCheckedRevision]);

  const onFetchNewRevision = useCallback(
    (newRevision: Revision) => {
      if (!currentRevision) return;
      const updateType = currentRevision.detectUpdateType(newRevision);

      switch (updateType) {
        case "force":
          localStorage.storeValue("revision", newRevision);
          setShowForceUpdate(true);
          return;
        case "manual":
          localStorage.storeValue("revision", newRevision);
          enqueueSnackbar(
            "新しいバージョンがリリースされました。画面を更新してアップデートをしてください。",
            {
              variant: "success",
              onAction: () => {
                refreshPage();
              },
              actionLabel: "更新する",
            }
          );
          return;
        case "none":
          return;
      }
    },
    [currentRevision, enqueueSnackbar, localStorage, setShowForceUpdate]
  );

  useEffect(() => {
    revisionUseCase.execute(onFetchNewRevision);
  }, [onFetchNewRevision]);

  return (
    <>
      {children}
      <ShouldRefreshPageModal open={showForceUpdate} onSubmit={refreshPage} />
    </>
  );
};
