import { z } from "zod";

export const latestContactMessageSchema = z.object({
  id: z.string(),
  contactRoomId: z.string(),
  type: z.enum(["system", "contact"]),
  messageId: z.string(),
  displayLabel: z.string(),
  updatedAt: z.date().optional(), // systemMessageはupdatedAtがないため
  createdAt: z.date(),
  createdEmployeeId: z.string().optional(),
  tenantId: z.string(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILatestContactMessageSchema extends z.infer<typeof latestContactMessageSchema> {}
