import { captureException } from "~/util";

export const getStorageAccessors = (
  storage: Storage
): {
  storeValue: <T>(key: string, value?: T) => void;
  retrieveValue: <T>(
    key: string,
    handleStorageObject?: {
      func: (value: T) => T;
      onError?: () => void;
      errorTypeName?: string;
    }
  ) => T | null;
  removeValue: (key: string) => void;
} => {
  const storeValue = <T>(key: string, value?: T): void => {
    storage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event("storage"));
  };

  const retrieveValue = <T>(
    key: string,
    handleStorageObject?: {
      func: (value: T) => T;
      onError?: () => void;
      errorTypeName?: string;
    }
  ): T | null => {
    const value = storage.getItem(key);
    if (value === null) return null;
    if (handleStorageObject === undefined) return JSON.parse(value);

    try {
      return handleStorageObject.func(JSON.parse(value));
    } catch (error) {
      if (error instanceof Error) {
        captureException({
          error,
          tags: {
            type: handleStorageObject.errorTypeName || "usestorage.retrieveValue",
          },
        });
      }

      handleStorageObject.onError?.();
      return null;
    }
  };

  const removeValue = (key: string): void => {
    storage.removeItem(key);
    window.dispatchEvent(new Event("storage"));
  };

  return { storeValue, retrieveValue, removeValue };
};
